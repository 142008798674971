import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '../../../../../../../../components/Typography';
import { LateFeeLimitTypeEnum, LateFeeUnitEnum } from '../../constants';

import styles from './LateFeeDigestContent.module.scss';

const dayOrDays = (days) => (Number(days) === 1 ? 'day' : 'days');

function formatDollars(num) {
  if (isNaN(num)) {
    return num;
  }
  if (Number.isInteger(Number(num))) {
    return Number(num);
  }
  return Number(num).toFixed(2);
}

const LateFeeDigestContent = ({ className, data = {} }) => {
  const {
    oneTimeFeeAmount,
    oneTimeFeeGracePeriod,
    oneTimeLateFeeUnit,
    dailyLateFeeAmount,
    dailyLateFeeGracePeriod,
    lateFeeLimitUnit,
    lateFeeLimitAmount,
    lateFeeLimitType,
    dailyFeesApplied,
    oneTimeFeeApplied,
    lateFeeLimitDays,
  } = data;

  const showOneTimeFee = oneTimeFeeApplied && !!oneTimeFeeAmount;
  const showDailyFees = dailyFeesApplied && !!dailyLateFeeAmount;
  const showDailyFeesTotal =
    dailyFeesApplied &&
    lateFeeLimitAmount &&
    lateFeeLimitType === LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT &&
    dailyLateFeeAmount > 0;
  const showStopAfterDays =
    dailyFeesApplied &&
    dailyLateFeeAmount &&
    lateFeeLimitType === LateFeeLimitTypeEnum.MAX_DAYS &&
    dailyLateFeeGracePeriod > 0;

  let oneTimeLateFeeText;

  switch (oneTimeLateFeeUnit) {
    case LateFeeUnitEnum.PERCENTAGE:
      oneTimeLateFeeText = `${oneTimeFeeAmount}% of monthly rent`;
      break;
    case LateFeeUnitEnum.FLAT:
      oneTimeLateFeeText = `$${formatDollars(oneTimeFeeAmount)}`;
      break;
    case LateFeeUnitEnum.PERCENTAGE_OF_UNPAID:
      oneTimeLateFeeText = `${oneTimeFeeAmount}% of unpaid rent`;
      break;
  }

  return (
    <div className={className}>
      {showOneTimeFee && (
        <p className={styles.text}>
          A one-time initial fee of{' '}
          <Span weight="bold">{oneTimeLateFeeText}</Span> will be applied{' '}
          <Span weight="bold">
            {oneTimeFeeGracePeriod || 1} {dayOrDays(oneTimeFeeGracePeriod)}
          </Span>{' '}
          after the rent due date.
        </p>
      )}

      {showDailyFees && (
        <p className={styles.text}>
          A daily late fee of{' '}
          <Span weight="bold">${formatDollars(dailyLateFeeAmount) || 0}</Span>{' '}
          will be applied starting{' '}
          <Span weight="bold">
            {dailyLateFeeGracePeriod || 1} {dayOrDays(dailyLateFeeGracePeriod)}
          </Span>{' '}
          after the rent due date.
        </p>
      )}

      {showDailyFeesTotal && (
        <p className={styles.text}>
          Total late fees will not exceed{' '}
          <Span weight="bold">
            {lateFeeLimitUnit === LateFeeUnitEnum.PERCENTAGE
              ? `${lateFeeLimitAmount}% of monthly rent`
              : `$${formatDollars(lateFeeLimitAmount)}`}
          </Span>
          .
        </p>
      )}

      {showStopAfterDays && (
        <p className={styles.text}>
          Total number of daily late fees applied will not exceed{' '}
          <Span weight="bold">
            {lateFeeLimitDays || 1} daily fee{lateFeeLimitDays > 1 ? 's' : ''}
          </Span>
          .
        </p>
      )}
    </div>
  );
};

LateFeeDigestContent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    oneTimeFeeAmount: PropTypes.number,
    oneTimeFeeGracePeriod: PropTypes.number,
    oneTimeLateFeeUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    dailyLateFeeAmount: PropTypes.number,
    dailyLateFeeGracePeriod: PropTypes.number,
    lateFeeLimitUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    lateFeeLimitAmount: PropTypes.number,
    lateFeeLimitType: PropTypes.oneOf(Object.values(LateFeeLimitTypeEnum)),
    dailyFeesApplied: PropTypes.bool,
    oneTimeFeeApplied: PropTypes.bool,
    lateFeeLimitDays: PropTypes.number,
  }),
};

export default LateFeeDigestContent;
