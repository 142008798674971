import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import TrashIcon from '../../icons/Remove';
import FlatButton from '../FlatButton';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({
  children,
  className,
  justIcon,
  disabled,
  shrink = true,
  ...props
}) => (
  <FlatButton
    className={cx(
      styles.remove,
      { [styles.shrink]: shrink, [styles.justIcon]: justIcon },
      className,
    )}
    icon={() => (
      <TrashIcon color={disabled ? colors.greyLight : colors.primary} />
    )}
    disabled={disabled}
    {...props}
  >
    {!justIcon && (
      <span className={styles.hiddenSmall}>
        {children ? children : 'Remove'}
      </span>
    )}
  </FlatButton>
);

RemoveButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  justIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
};

export default RemoveButton;
