import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Close from '../../icons/Close';
import DownloadIcon from '../../icons/Download';
import Button from '../Button';
import IconButton from '../IconButton';
import Modal from '../Modal';

import styles from './DownloadFormModal.module.scss';

const DownloadFormModal = ({
  className,
  onClose,
  open,
  formats,
  onDownloadClicked,
}) => {
  return (
    <Modal open={open} className={cx(styles.container, className)}>
      <div className={styles.closeActionContainer}>
        <IconButton
          onClick={onClose}
          icon={Close}
          className={styles.closeAction}
        />
      </div>
      <div className={styles.title} id="dwn-modal-title">
        Choose Form Type
      </div>
      {formats.map((f, i) => {
        const Icon = f.icon;
        return (
          <div key={i} className={styles.itemRow}>
            <div className={styles.titleIconContainer}>
              <div className={styles.iconContainer}>
                <Icon className={styles.icon} />
              </div>
              <span className="format-title">{f.title}</span>
            </div>
            <div className={styles.downloadIconWrap}>
              <IconButton href={f.url} target={'_blank'} icon={DownloadIcon} />
            </div>
            <Button
              download
              secondary
              className={cx(styles.downloadBtn, `download-type-${f.format}`)}
              href={f.url}
              target={'_blank'}
              onClick={() => {
                onDownloadClicked(f.format);
                onClose();
              }}
            >
              Download
            </Button>
          </div>
        );
      })}
    </Modal>
  );
};

DownloadFormModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  formats: PropTypes.array,
  onDownloadClicked: PropTypes.func,
};

export default DownloadFormModal;
