import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import LoadingScreen from '../../../components/LoadingScreen';
import MaintenanceForm from '../../../components/Maintenance/MaintenanceForm';
import { useInfoToast } from '../../../components/Toast';
import ListingModeEnum from '../../../constants/enums/ListingModeEnum';
import {
  useLeases,
  useListings,
  useUserProfile,
} from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import listingQuery from '../../../graphql/queries/listingQuery.graphql';
import { removeFromApolloCache } from '../../../services/utilities';
import createMaintenanceRequest from '../graphql/createMaintenanceRequest.graphql';

import styles from './UniversalCreateMaintenancePage.module.scss';

const UniversalCreateMaintenancePage = ({ className, history }) => {
  const location = useLocation();
  const showInfoToast = useInfoToast();
  const searchParams = new URLSearchParams(location.search);

  const passedPropertyId = searchParams.get('property_id');
  const passedLeaseId = searchParams.get('lease_id');

  const [selectedListingId, setSelectedListingId] = useState(
    passedPropertyId || null,
  );

  const { user, loading: loadingUser } = useUserProfile({
    polling: false,
  });

  const mainApproverPhone = get(
    user,
    'maintenance_request_management_settings.approvers[0].phone',
    user?.telephone,
  );

  const { maintenance_plus_subscription_subscribed: maintenancePlusActive } =
    user || {};

  const { leases = [], loading: loadingLeases } = useLeases();

  const { listings, loading: listingsLoading } = useListings({
    variables: {
      mode: ListingModeEnum.ROOTS_ONLY,
    },
  });

  const [createMaintenance] = useMutation(createMaintenanceRequest, {
    refetchQueries: [
      { query: listingQuery, variables: { id: selectedListingId } },
      { query: basicUserProfile },
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(
        ['maintenanceRequests', 'leases', 'tenants', 'renterProfile'],
        cache,
      );
    },
  });

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  if (listingsLoading || loadingLeases || loadingUser) {
    return <LoadingScreen loading />;
  }

  const onSubmit = async (data) => {
    // Check if renterId is "NONE" and delete it from data if true
    // We should remove this once we releae the feature
    // And clean this code
    if (data.renterId === 'NONE') {
      delete data.renterId;
      delete data.contact_phone_number;
    }

    const submitData = {
      listing_id: selectedListingId,
      ...data,
      attachments: (data.attachments || []).map((a) => ({
        id: a.id,
      })),
    };
    try {
      const { data: response } = await createMaintenance({
        variables: { ...submitData },
      });
      const {
        createMaintenanceRequest: { maintenance_request },
      } = response;
      // We have ID for redirect here maintenance_request.id
      history.replace(
        `/owners/maintenance/${maintenance_request.id}/${selectedListingId}`,
      );
      showInfoToast('Maintenance request created!');
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      const message = parsedError.message || 'Something went wrong';
      window.scrollTo(0, 0);
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  const initialValues =
    passedPropertyId && passedLeaseId
      ? {
          property_id: passedPropertyId,
          lease_id: passedLeaseId,
        }
      : undefined;

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.back)}>
        <BackButton />
      </div>
      <MaintenanceForm
        onSubmit={onSubmit}
        onPropertyChange={setSelectedListingId}
        properties={listings}
        {...(initialValues && { initialValues })}
        leases={leases}
        maintenancePlusActive={maintenancePlusActive || isEnrolledFreeLula}
        mainApproverPhone={mainApproverPhone || user?.telephone}
      />
    </div>
  );
};

UniversalCreateMaintenancePage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(UniversalCreateMaintenancePage);
