/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { TTfetch } from '../core/TTfetch';
import { useConfig } from '../providers/ConfigProvider';

import clearExpiredCookieExperiments from './clearExpiredCookieExperiments';

const StatusEndpointContext = React.createContext({
  globalFeatures: [],
  loading: true,
  error: false,
  // other fields related to status endpoint
});

export const StatusEndpointProvider = ({ children }) => {
  const [globalFeatures, setGlobalFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [cookieExperiments, setCookieExperiments] = useState(null);
  const { API_URL } = useConfig();

  useEffect(() => {
    TTfetch(`${API_URL}/public/status`.replace(/\//g, '/'))
      .then((response) => response.json())
      .then((data) => {
        if (
          data.status === 'OK' &&
          window.location.pathname === '/maintenance'
        ) {
          window.location.pathname = '/';
          return;
        }
        if (
          data.status === 'MAINTENANCE' &&
          window.location.pathname === '/maintenance'
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 30000);
        }
        if (data.status !== 'OK') {
          // Not being used now, but could be useful for maintenance mode
          setError(true);
        }
        clearExpiredCookieExperiments(
          data.cookieExperiments.map((exp) => exp.name),
        );
        setGlobalFeatures(data.globalFeatures);
        setCookieExperiments(data.cookieExperiments);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <StatusEndpointContext.Provider
      value={{
        globalFeatures,
        loading,
        error,
        cookieExperiments,
      }}
    >
      <>{children}</>
    </StatusEndpointContext.Provider>
  );
};

export const useStatusEndpoint = () => {
  const { globalFeatures, loading, error, cookieExperiments } = useContext(
    StatusEndpointContext,
  );
  return {
    globalFeatures,
    loading,
    error,
    cookieExperiments,
  };
};
