import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';

import styles from './AdditionsToLeaseAgreementMD.module.scss';

const AdditionsToLeaseAgreementMD = ({ className, subDivisionName }) => {
  let cardContent;

  if (subDivisionName === SUB_DIVISION_NAMES_KEYS.montgomery) {
    cardContent = (
      <HelpfulInfoCard
        noIcon
        title="Maryland Renters’ Rights and Stabilization Act"
        className={cx(styles.helpfulInfoCard, className)}
        color="iceCold"
      >
        Montgomery County, Maryland requires that the most current version of
        the{' '}
        <a
          className={styles.link}
          href="https://www.montgomerycountymd.gov/DHCA/Resources/Files/housing/landlordtenant/publications/hbk_olta_eng.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Landlord-Tenant Handbook
        </a>{' '}
        must be presented to the tenant(s) and each party must sign it. We’ve
        already attached it for you.{' '}
      </HelpfulInfoCard>
    );
  } else if (subDivisionName === SUB_DIVISION_NAMES_KEYS.baltimore) {
    cardContent = (
      <HelpfulInfoCard
        noIcon
        title="Maryland Renters’ Rights and Stabilization Act"
        className={cx(styles.helpfulInfoCard, className)}
        color="iceCold"
      >
        Baltimore, Maryland requires that the most current version of the{' '}
        <a
          className={styles.link}
          href="https://dhcd.baltimorecity.gov/sites/default/files/Tenants%20Rights%20Guidebook%2004-30-2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tenant’s Rights Guidebook
        </a>{' '}
        must be presented to the tenant(s) and each party must sign it. We’ve
        already attached it for you.{' '}
      </HelpfulInfoCard>
    );
  } else {
    cardContent = (
      <HelpfulInfoCard
        noIcon
        title="Maryland Renters’ Rights and Stabilization Act"
        className={cx(styles.helpfulInfoCard, className)}
        color="iceCold"
      >
        Maryland requires that the most current version of the{' '}
        <a
          className={styles.link}
          href="https://www.marylandattorneygeneral.gov/CPD%20Documents/Tips-Publications/landlordTenantPDF.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Landlord Tenant Handbook
        </a>{' '}
        must be presented to the tenant(s) and each party must sign it. We’ve
        already attached it for you.
      </HelpfulInfoCard>
    );
  }

  return cardContent;
};

AdditionsToLeaseAgreementMD.propTypes = {
  className: PropTypes.string,
  subDivisionName: PropTypes.string,
};

export default AdditionsToLeaseAgreementMD;
