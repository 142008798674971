import React from 'react';
import PropTypes from 'prop-types';

import basicProfileQuery from '../../graphql/basicUserProfile.graphql';
import { useRenderOutside } from '../../helpers/render-outside';
import { segmentTracking } from '../../services/utilities';
import PremiumSubscriptionModal from '../PremiumSubscription';
import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';

import PaymentsPremiumPromotion from './PaymentsPremiumPromotion';

const PremiumSubscriptionModalWrapped = ({ onNext }) => {
  const renderOutside = useRenderOutside();

  const onShowPremiumModal = () => {
    renderOutside((done) => (
      <PremiumSubscriptionModal
        onClose={() => {
          done();
        }}
        onSuccess={() => {
          done();
          onNext();
        }}
        refetchQueries={[basicProfileQuery]}
        openLocation={MODAL_LOCATIONS.PREMIUM_UPSELL_PAGE}
        purchase_location="RP Premium Upsell - Premium"
      />
    ));
  };

  return (
    <PaymentsPremiumPromotion
      onPremium={() => {
        segmentTracking('premium clicked', {
          location: 'RP Premium Upsell',
        });
        onShowPremiumModal();
      }}
      onUpgradeToPremium={() => {
        segmentTracking('upgrade_to_premium clicked', {
          location: 'RP Premium Upsell',
        });
        onShowPremiumModal();
      }}
      onCancel={() => {
        segmentTracking('maybe_later clicked', {
          location: 'RP Premium Upsell',
        });
        onNext();
      }}
    />
  );
};

PremiumSubscriptionModalWrapped.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default PremiumSubscriptionModalWrapped;
