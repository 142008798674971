import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import LoadingScreen from '../../../components/LoadingScreen';
import MaintenanceForm from '../../../components/Maintenance/MaintenanceForm';
import { useLeases, useUserProfile } from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import listingQuery from '../../../graphql/queries/listingQuery.graphql';
import { removeFromApolloCache } from '../../../services/utilities';
import createMaintenanceRequest from '../graphql/createMaintenanceRequest.graphql';

import styles from './CreateMaintenancePage.module.scss';

const CreateMaintenancePage = ({ className, history, match }) => {
  const listingId = get(match, 'params.listingGlobalId', '');

  if (!listingId) {
    history.goBack();
  }

  const { user, loading: loadingUser } = useUserProfile({
    polling: false,
  });

  const mainApproverPhone = get(
    user,
    'maintenance_request_management_settings.approvers[0].phone',
    user?.telephone,
  );
  const { maintenance_plus_subscription_subscribed: maintenancePlusActive } =
    user || {};

  const { data, loading } = useQuery(listingQuery, {
    variables: {
      id: listingId,
    },
  });
  const { leases = [], loading: loadingLeases } = useLeases();

  const [createMaintenance] = useMutation(createMaintenanceRequest, {
    refetchQueries: [
      { query: listingQuery, variables: { id: listingId } },
      { query: basicUserProfile },
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(
        ['maintenanceRequests', 'leases', 'tenants'],
        cache,
      );
    },
  });

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  if (loading || loadingLeases || loadingUser) {
    return <LoadingScreen loading={loading} />;
  }
  const onSubmit = async (data) => {
    // The listing_id will now be the lowest level listing type selected in the form.
    // So it may differ from the listing_id in the URL.
    const finalListingId = data.room_id || data.unit_id || data.property_id;

    // Check if renterId is "NONE" and delete it from data if true
    // We should remove this once we releae the feature
    // And clean this code
    if (data.renterId === 'NONE') {
      delete data.renterId;
      delete data.contact_phone_number;
    }

    const submitData = {
      ...data,
      listing_id: finalListingId,
      attachments: (data.attachments || []).map((a) => ({
        id: a.id,
      })),
    };
    try {
      const { data: response } = await createMaintenance({
        variables: { ...submitData },
      });
      const {
        createMaintenanceRequest: { maintenance_request },
      } = response;
      // We have ID for redirect here maintenance_request.id
      history.replace(
        `/owners/maintenance/${maintenance_request.id}/${finalListingId}`,
      );
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      const message = parsedError.message || 'Something went wrong';
      window.scrollTo(0, 0);
      return {
        [FORM_ERROR]: message,
      };
    }
  };
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.back)}>
        <BackButton />
      </div>
      <MaintenanceForm
        onSubmit={onSubmit}
        listing={data.node}
        leases={leases}
        maintenancePlusActive={maintenancePlusActive || isEnrolledFreeLula}
        mainApproverPhone={mainApproverPhone || user?.telephone}
      />
    </div>
  );
};

CreateMaintenancePage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(CreateMaintenancePage);
