import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Word = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39" {...props}>
    <defs />
    <defs>
      <path id="a" d="M.007.005h38.2v37.322H.007z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(.667 .333)">
        <mask id="b" fill="#fff">
          <use href="#a" />
        </mask>
        <path
          fill="#27366A"
          d="M22.564.005v3.473c4.644.026 9.715-.048 14.355.026.778-.008 1.228.332 1.244 1.175.074 8.55-.017 17.589.044 26.14-.027.54.012 1.524-.073 2.069-.108.695-.417.828-1.115.922-.39.053-1.042.046-1.425.064-3.81-.019-7.618-.015-11.427-.013l-1.603-.004v3.476L.002 33.861c-.004-10.126 0-20.253 0-30.374L22.564.005"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#FFFFFE"
        d="M23.23 5.113h14.324v27.78H23.23V29.42h11.285v-1.736H23.23v-2.17h11.285v-1.737H23.23v-2.17h11.285v-1.736H23.23V17.7h11.285v-1.737H23.23v-2.17h11.285v-1.736H23.23v-2.17h11.285V8.152H23.23V5.113M9.636 12.783c.825-.048 1.65-.083 2.474-.126.577 2.93 1.168 5.855 1.793 8.772.49-3.012 1.033-6.016 1.558-9.024.868-.03 1.736-.078 2.6-.13-.981 4.206-1.84 8.446-2.913 12.626-.725.378-1.81-.017-2.669.044-.577-2.874-1.25-5.73-1.767-8.616-.507 2.804-1.167 5.582-1.749 8.368-.833-.043-1.67-.095-2.509-.151-.72-3.82-1.566-7.614-2.24-11.442.743-.035 1.49-.065 2.232-.091.447 2.765.955 5.517 1.345 8.286.612-2.839 1.237-5.677 1.845-8.516"
      />
    </g>
  </svg>
);

Word.defaultProps = {
  width: 40,
  height: 39,
};

Word.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Word);
