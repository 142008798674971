import React from 'react';
import { Field, useField } from 'react-final-form';

import Divider from '../../../../../../../components/Divider';
import InputWithIcon from '../../../../../../../components/InputWithIcon';
import SegmentedController from '../../../../../../../components/SegmentedController';
import SelectField from '../../../../../../../components/SelectField';
import parseToPositiveNumbers from '../../../../../../../helpers/parseToPositiveNumbers';
import DollarIcon from '../../../../../../../icons/Dollar';
import PercentageIcon from '../../../../../../../icons/Percentage';
import composeValidators from '../../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../../validators/fieldRequired';
import isGreaterThan from '../../../../../../../validators/isGreaterThan';
import isLowerOrEqualTo from '../../../../../../../validators/isLowerOrEqualTo';
import isNumber from '../../../../../../../validators/isNumber';
import { lateFeeOptions, LateFeeUnitEnum } from '../constants';
import { getGracePeriodOptions } from '../gracePeriodOptions';

import styles from '../ConfigureLateFeesModal.module.scss';

const gracePeriodOptions = getGracePeriodOptions();

const validateAmount = (value, formValues) => {
  if (formValues.oneTimeLateFeeUnit !== LateFeeUnitEnum.FLAT) {
    return isLowerOrEqualTo(100_00, 'Cannot exceed 100% of rent')(value);
  }

  return null;
};

const OneTimeFeeSubform = () => {
  const {
    input: { value: oneTimeFeeApplied },
  } = useField('oneTimeFeeApplied');

  const {
    input: { value: oneTimeLateFeeUnit },
  } = useField('oneTimeLateFeeUnit');

  if (!oneTimeFeeApplied) return null;

  const showIcon =
    oneTimeLateFeeUnit === LateFeeUnitEnum.FLAT ? DollarIcon : PercentageIcon;

  return (
    <>
      <div className={styles.initialFeeContainer}>
        <div className={styles.feeAmountContainer}>
          <Field
            name="oneTimeLateFeeUnit"
            component={SegmentedController}
            options={lateFeeOptions}
            defaultValue={LateFeeUnitEnum.FLAT}
            label="Fee Type"
            containerClassName={styles.segmentsContainer}
          />
          <Field
            component={InputWithIcon}
            id="oneTimeFeeAmount"
            name="oneTimeFeeAmount"
            className={styles.miniField}
            label="Amount"
            type="number"
            step=".01"
            pattern="\d+"
            parse={parseToPositiveNumbers}
            inputMode="decimal"
            min="0"
            validate={composeValidators(
              isNumber,
              fieldRequired,
              isGreaterThan(0),
              validateAmount,
            )}
            icon={showIcon}
            iconRight={oneTimeLateFeeUnit !== LateFeeUnitEnum.FLAT}
          />
        </div>
        <Field
          label="Applied"
          component={SelectField}
          id="oneTimeFeeGracePeriod"
          name="oneTimeFeeGracePeriod"
          options={gracePeriodOptions}
          defaultValue={gracePeriodOptions[0].value}
          className={styles.gracePeriod}
          validate={fieldRequired}
          validateFields={['dailyLateFeeGracePeriod']}
        />
      </div>
      <Divider text="" className={styles.divider} />
    </>
  );
};

export default OneTimeFeeSubform;
