import React from 'react';
import PropTypes from 'prop-types';

import { H2, Paragraph } from '../../../components/Typography';

const OnboardingExperimentTitle = ({ userFirstName }) => {
  return (
    <>
      <Paragraph className="mb-4" weight="semi">
        Welcome, {userFirstName} 👋
      </Paragraph>
      <H2 className="mb-4">Where would you like to start?</H2>
    </>
  );
};

OnboardingExperimentTitle.propTypes = {
  userFirstName: PropTypes.string,
};

export default OnboardingExperimentTitle;
