import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './SecurityDepositLawMD.module.scss';

function SecurityDepositLawMD() {
  return (
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={styles.helpfulInfoCard}
      title="Maryland Security Deposit Laws"
      color="iceCold"
      collapsable
    >
      <HelpfulInfoList>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          The maximum security deposit is limited to one month’s rent
        </HelpfulInfoListItem>
      </HelpfulInfoList>
    </HelpfulInfoCard>
  );
}

export default SecurityDepositLawMD;
