import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Pdf = (props) => (
  <svg viewBox="0 0 31 40" {...props}>
    <defs />
    <g fill="none" fillRule="evenodd">
      <path
        fill="#033A6D"
        d="M2.917 39.147a2.584 2.584 0 01-2.584-2.584V3.437A2.584 2.584 0 012.917.853h15.544l11.436 11.255v24.455a2.584 2.584 0 01-2.584 2.584H2.917z"
      />
      <path
        fill="#2370A3"
        d="M21.086 12.107h8.81L18.46.858v8.623a2.625 2.625 0 002.625 2.626"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M8.42 24.667v-2.372h.608c.83 0 1.47-.19 1.921-.57.45-.38.676-.928.676-1.646 0-.69-.212-1.209-.635-1.557-.422-.348-1.04-.522-1.852-.522H7v6.667h1.42zm.466-3.53H8.42v-1.979h.646c.388 0 .672.08.854.237.182.158.272.403.272.735 0 .328-.108.578-.325.75-.216.171-.543.257-.98.257zm5.898 3.53c1.184 0 2.09-.292 2.717-.876.628-.584.941-1.424.941-2.522 0-1.03-.302-1.833-.906-2.407-.605-.575-1.453-.862-2.546-.862h-2.102v6.667h1.896zm.133-1.168h-.61v-4.34h.756c1.27 0 1.905.715 1.905 2.147 0 1.462-.684 2.193-2.051 2.193zm6.31 1.168V22.03h2.27v-1.154h-2.27v-1.719h2.44V18H19.83v6.667h1.396z"
      />
    </g>
  </svg>
);

Pdf.defaultProps = {
  width: 31,
  height: 40,
};

Pdf.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Pdf);
