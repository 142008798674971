import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../../../components/RadioGroup';
import { Paragraph } from '../../../../../../../components/Typography';
import CristalIcon from '../../../../../../../icons/Cristal';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import SectionTitle from '../../../../../common/SectionTitle';

import styles from './MethamphetamineDisclosureNH.module.scss';

function MethamphetamineDisclosureNH({ fieldRequired }) {
  return (
    <section className={styles.container}>
      <SectionTitle title="Methamphetamines Disclosure" icon={CristalIcon} />
      <label className={styles.label}>
        Do you know if Methamphetamines have ever been produced and not cleaned
        up at your property?
      </label>
      <Paragraph className={styles.description}>
        New Hampshire law requires that you disclose this.
      </Paragraph>
      <Field
        component={RadioGroup}
        id="custom_state_data.methamphetamine_manufactured"
        name="custom_state_data.methamphetamine_manufactured"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            'id': 'methamphetamine_manufactured_yes',
            'value': true,
            'label': 'Yes',
            'data-qa':
              'lease-agreements-methamphetamine-manufactured-radio-yes',
          },
          {
            id: 'methamphetamine_manufactured_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition
        when="custom_state_data.methamphetamine_manufactured"
        is={true}
      >
        <HelpfulInfoCard
          noTitle
          Icon={TurboTenantSmallLogo}
          className={styles.helpCard}
          iconProps={{ className: styles.cardLogo }}
        >
          We will provide your tenant with the proper notice in the lease
          agreement
        </HelpfulInfoCard>
      </Condition>
    </section>
  );
}

MethamphetamineDisclosureNH.propTypes = {
  fieldRequired: PropTypes.func,
};

export default MethamphetamineDisclosureNH;
