import React from 'react';

import { H2, Paragraph } from '../../../components/Typography';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';

import styles from './SignupPage.module.scss';

const SignupOnboardingExperimentTitle = () => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <div>
      <H2 className={styles.experimentTitle}>The #1 tool for new landlords</H2>
      {!isSmallScreen ? (
        <Paragraph className={styles.experimentSubtitle}>
          Sign up to streamline your process.
        </Paragraph>
      ) : (
        <Paragraph className={styles.experimentSubtitle}>
          TurboTenant gives first-time landlords the tools to screen tenants,
          collect rent, and stay organized.
        </Paragraph>
      )}
    </div>
  );
};

export default SignupOnboardingExperimentTitle;
