import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageNotFound from '../../components/PageNotFound';
import componentLoader from '../../helpers/component-loader';
import InAppLayouts from '../../layout/InAppLayouts';
import { RouteWithLayout } from '../../providers/LayoutProvider';

import FormsEditPage from './FormsEditPage';

const OwnerFormsPack = componentLoader(() => import('./OwnerFormsPack'));

const FormsRoutes = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <OwnerFormsPack />} />
      <RouteWithLayout
        layout={InAppLayouts}
        exact
        path={`${url}/:originalFormId/edit`}
        component={FormsEditPage}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

FormsRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default FormsRoutes;
