import React, { useEffect, useState } from 'react';
import { Field, FormSpy, useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import IconTooltip from '../../../../../components/IconTooltip';
import RadioGroup from '../../../../../components/RadioGroup';
import Scheduler from '../../../../../components/Scheduler';
import Stepper from '../../../../../components/Stepper';
import Textarea from '../../../../../components/Textarea';
import { Experiments } from '../../../../../constants/experiments';
import { mobileBreakpoint } from '../../../../../constants/media-breakpoints';
import { useCookieExperimentAssignment } from '../../../../../helpers/experiments/useCookieExperimentAssignment';
import { useCookieExperimentVariant } from '../../../../../helpers/experiments/useCookieExperimentVariant';
import { segmentTracking } from '../../../../../services/utilities';
import fieldRequired from '../../../../../validators/fieldRequired';
import { getCurentTest } from '../../../../auth/signup/ABtestsHelper/FlowPicker';
import {
  segmentLocation,
  signupTestValue,
} from '../../../../auth/signupProExperiment/utils';

import styles from './PersonalizeForm.module.scss';

const PersonalizeForm = ({
  className,
  academy,
  submitting,
  hideTitle,
  buttonLabel,
  onSubmit,
}) => {
  const { values } = useFormState();
  const location = useLocation();
  const currentTestSignUpOnboarding = getCurentTest();
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth < mobileBreakpoint;
  const [formValues, setFormValues] = useState({});
  const [shouldShowScheduler, setShouldShowScheduler] = React.useState(false);
  const baseExperimentAssignment = useCookieExperimentAssignment();
  const tryAssignActiveCookieExperiment =
    location.pathname.includes('/pro') || location.pathname.includes('/premium')
      ? () => {} // We dont want to trigger experiments for /auth/signup/final/pro or /auth/signup/final/premium
      : baseExperimentAssignment;
  const scheduleExperimentVariant = useCookieExperimentVariant(
    Experiments.GtCxSchedulePersonalize.name,
  );

  useEffect(() => {
    const shouldShow =
      scheduleExperimentVariant ===
      Experiments.GtCxSchedulePersonalize.variants.variant_1;
    setShouldShowScheduler(shouldShow);

    if (
      location.pathname === '/auth/signup/personalize' &&
      values.properties >= 3
    ) {
      tryAssignActiveCookieExperiment(
        Experiments.GtCxSchedulePersonalize.name,
        {},
        (variantAssigned) =>
          setShouldShowScheduler(
            variantAssigned ===
              Experiments.GtCxSchedulePersonalize.variants.variant_1,
          ),
      );
      setShouldShowScheduler(shouldShow);

      if (
        scheduleExperimentVariant ===
        Experiments.GtCxSchedulePersonalize.variants.variant_1
      ) {
        tryAssignActiveCookieExperiment(
          Experiments.GtOnboardingPhoneNumber.name,
          {
            forceVariant: Experiments.GtOnboardingPhoneNumber.variants.control,
          },
        );
      } else {
        tryAssignActiveCookieExperiment(
          Experiments.GtOnboardingPhoneNumber.name,
        );
      }
    }
  }, [values.properties, scheduleExperimentVariant]);

  useEffect(() => {
    if (shouldShowScheduler) {
      segmentTracking('scheduler rendered', {
        location: 'Sign Up Flow',
      });
    }
  }, [shouldShowScheduler]);

  tryAssignActiveCookieExperiment(Experiments.GT_QUESTION_TWO_COPY.name);
  const questionTwoCopyExperimentVariant = useCookieExperimentVariant(
    Experiments.GT_QUESTION_TWO_COPY.name,
  );

  const questionTwoLabel =
    questionTwoCopyExperimentVariant ===
    Experiments.GT_QUESTION_TWO_COPY.variants.units
      ? 'How many units do you own/manage?'
      : 'How many properties do you own/manage?';

  let title;
  if (academy) {
    title = 'Tells us about yourself';
  } else {
    title = isMobile
      ? 'Tell us about yourself so we can personalize your account'
      : "Let's personalize your experience";
  }

  const handleButtonClick = () => {
    const { properties, signupDescription, years_of_management } = formValues;

    if (properties >= 3 && signupDescription !== 'OTHERS_RENTALS') {
      tryAssignActiveCookieExperiment(
        Experiments.GtThreePropertiesNllSignupOnboardingAnswers.name,
      );
    }

    if (
      properties === 1 &&
      signupDescription === 'MY_RENTALS' &&
      years_of_management === 'Less than a year'
    ) {
      tryAssignActiveCookieExperiment(
        Experiments.GtOnePropertyNllSignupOnboardingAnswers.name,
      );
    }

    segmentTracking('continue clicked', {
      location:
        currentTestSignUpOnboarding === signupTestValue
          ? segmentLocation
          : 'Sign Up Flow',
      experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
    });

    onSubmit?.(formValues);
  };

  return (
    <div className={cx(styles.container, className)}>
      {hideTitle ? null : <h1>{title}</h1>}
      <Field
        className={styles.signupDescription}
        component={RadioGroup}
        id="landlord_signup_description"
        name="signupDescription"
        label="Which best describes you?"
        labelProps={{
          className: styles.radioLabel,
        }}
        vertical
        options={[
          {
            label: 'I manage my own rental(s)',
            value: 'MY_RENTALS',
          },
          {
            label: 'I manage rentals for others',
            value: 'OTHERS_RENTALS',
          },
          {
            label: 'I manage a mix of both',
            value: 'BOTH',
          },
          {
            label: "I don't manage any rentals yet",
            value: 'DONT_MANAGE_RENTALS',
          },
          {
            value: 'BROKER',
            label: (
              <span className={styles.dwellingOption}>
                I'm an apartment or rental broker
                <IconTooltip
                  tip={
                    <span>
                      They serve as the go-between for property managers and
                      potential tenants. They do not actively manage rentals.
                    </span>
                  }
                />
              </span>
            ),
          },
        ]}
        validate={fieldRequired}
      />
      <div className={styles.row}>
        <Field
          label={questionTwoLabel}
          component={Stepper}
          labelProps={{
            className: styles.stepperLabel,
          }}
          small
          name="properties"
          id="propertiesCount"
          className={styles.stepper}
          validate={fieldRequired}
        />
      </div>
      {shouldShowScheduler && (
        <div className={styles.schedulerContainer}>
          <Scheduler />
        </div>
      )}
      <Field
        vertical
        className={styles.yearsOfManagment}
        component={RadioGroup}
        id="landlord_signup_description"
        name="years_of_management"
        label="How long have you managed rentals?"
        labelProps={{
          className: styles.radioLabel,
        }}
        options={[
          {
            label: 'Less than a year',
            value: 'Less than a year',
          },
          {
            label: '1-4 years',
            value: '1-4 years',
          },
          {
            label: '5-10 years',
            value: '5-10 years',
          },
          {
            label: 'More than 10 years',
            value: 'More than 10 years',
          },
          {
            label: "I don't manage any rentals yet",
            value: "I don't manage any rentals yet",
          },
        ]}
        validate={fieldRequired}
      />
      <Field
        component={Textarea}
        hint="(Optional)"
        name="how_did_you_hear_about_us"
        label="How did you hear about TurboTenant?"
        className={styles.HowDidYouHearAboutUs}
        validate={(value) => {
          if (value?.length && value.length > 250) {
            return 'Max 250 characters';
          }
        }}
        skipTouchedCheck
      />
      <Button
        type="submit"
        className={styles.submit}
        loading={submitting}
        disabled={submitting}
        data-qa="personalized-form-submit"
        onClick={handleButtonClick}
      >
        {buttonLabel}
      </Button>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          setFormValues(values);
          return null;
        }}
      </FormSpy>
    </div>
  );
};

PersonalizeForm.defaultProps = {
  buttonLabel: 'Continue',
};

PersonalizeForm.propTypes = {
  className: PropTypes.string,
  academy: PropTypes.bool,
  submitting: PropTypes.bool,
  hideTitle: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default PersonalizeForm;
