import React, { memo } from 'react';
import PropTypes from 'prop-types';

const UnorderedList = ({ color, ...props }) => (
  <svg viewBox="0 0 20 16" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M2.555 0C3.745 0 4.71.917 4.71 2.047c0 1.13-.965 2.046-2.155 2.046S.4 3.177.4 2.047 1.364 0 2.555 0Zm0 1.116c-.541 0-.98.417-.98.93 0 .514.439.93.98.93.54 0 .98-.416.98-.93 0-.513-.44-.93-.98-.93Zm16.457.319c.325 0 .588.25.588.558 0 .308-.263.558-.588.558h-11.7c-.325 0-.588-.25-.588-.558 0-.309.263-.558.587-.558h11.701ZM2.555 5.953c1.19 0 2.155.917 2.155 2.047 0 1.13-.965 2.047-2.155 2.047S.4 9.13.4 8c0-1.13.964-2.047 2.155-2.047Zm0 1.117c-.541 0-.98.416-.98.93s.439.93.98.93c.54 0 .98-.417.98-.93 0-.513-.44-.93-.98-.93Zm16.457.318c.325 0 .588.25.588.558 0 .308-.263.558-.588.558h-11.7c-.325 0-.588-.25-.588-.558 0-.308.263-.558.587-.558h11.701ZM2.555 11.907c1.19 0 2.155.917 2.155 2.046 0 1.13-.965 2.047-2.155 2.047S.4 15.084.4 13.953c0-1.13.964-2.046 2.155-2.046Zm0 1.116c-.541 0-.98.417-.98.93 0 .514.439.93.98.93.54 0 .98-.416.98-.93 0-.513-.44-.93-.98-.93Zm16.457.318c.325 0 .588.25.588.558 0 .308-.263.558-.588.558h-11.7c-.325 0-.588-.25-.588-.558 0-.308.263-.558.587-.558h11.701Z"
    />
  </svg>
);

UnorderedList.defaultProps = {
  width: 20,
  height: 16,
  color: 'currentColor',
};

UnorderedList.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(UnorderedList);
