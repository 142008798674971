import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import QuickReplyModalContext from '../components/CometChatWorkspace/src/contexts/QuickReplyModalContext';
import { segmentTracking } from '../services/utilities';

const UniversalMessagingContext = createContext();

const locationMap = [
  { keyword: 'academy', name: 'Academy Page' },
  { keyword: 'dashboard', name: 'Dashboard' },
  { keyword: 'properties', name: 'Properties Index Page' },
  { keyword: 'maintenance', name: 'Maintenance Index Page' },
  { keyword: 'leases', name: 'Leases Index' },
  { keyword: 'forms', name: 'Forms' },
  { keyword: 'leads', name: 'Leads Index Page' },
  { keyword: 'applicants', name: 'Applicant Index Page' },
  { keyword: 'tenants', name: 'Tenant Index Page' },
  { keyword: 'payments', name: 'Payments Overview Page' },
  { keyword: 'charges', name: 'Charges Page' },
  { keyword: 'deposits', name: 'Deposits Page' },
  { keyword: 'expenses', name: 'Expenses Index Page' },
  { keyword: 'accounting', name: 'Accounting Page' },
  { keyword: 'reports', name: 'Reports Page' },
  { keyword: 'toolbox', name: 'Toolbox Page' },
  { keyword: 'award', name: 'Community Page' },
  { keyword: 'help_center', name: 'Need Help Page' },
  { keyword: 'referrals', name: 'Referral Page' },
];

const UniversalMessagingProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isQuickReplyModalOpen, setIsQuickReplyModalOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialChatId, setInitialChatId] = useState(null);

  const location = useLocation();

  const toggleChat = useCallback(
    (initialChatId) => {
      const currentRoute = location.pathname;
      if (initialChatId) {
        setInitialChatId(initialChatId);
      }
      const endMatch = locationMap.find(({ keyword }) => {
        const splitRoute = currentRoute.split('/');
        return splitRoute.at(-1) === keyword;
      });

      const matchedLocation =
        endMatch ||
        locationMap.find(({ keyword }) => {
          return currentRoute?.includes(keyword);
        });

      if (matchedLocation?.name) {
        segmentTracking('chat_bubble clicked', {
          location: `Chat Bubble - ${matchedLocation.name}`,
        });
      }

      if (!isOpen) {
        setPosition({ x: 0, y: 0 });
      }
      setIsOpen((prev) => !prev);
    },
    [location],
  );

  const updatePosition = useCallback((newPosition) => {
    setPosition({
      x: newPosition.x,
      y: newPosition.y,
    });
  }, []);

  const resetInitialChatId = useCallback(() => {
    setInitialChatId(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      isOpen,
      position,
      toggleChat,
      updatePosition,
      resetInitialChatId,
      initialChatId,
    }),
    [
      isOpen,
      position,
      toggleChat,
      updatePosition,
      resetInitialChatId,
      initialChatId,
    ],
  );

  /**
   * NOTE: Instead of creating a separate context provider for the QuickReplyModal, this one was reused. If other values,
   * unrelated with UniversalMessaging, need to be added to the context, a new context provider should be created.
   */
  const quickReplyContextValue = useMemo(() => [
    isQuickReplyModalOpen,
    setIsQuickReplyModalOpen,
  ]);

  return (
    <UniversalMessagingContext.Provider value={contextValue}>
      <QuickReplyModalContext.Provider value={quickReplyContextValue}>
        {children}
      </QuickReplyModalContext.Provider>
    </UniversalMessagingContext.Provider>
  );
};

UniversalMessagingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUniversalMessagingContext = () =>
  useContext(UniversalMessagingContext);

export default UniversalMessagingProvider;
