import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DownArrow = ({ color, ...props }) => (
  <svg viewBox="0 0 24 24" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.792 10H7.208a.233.233 0 0 0-.181.076.113.113 0 0 0 .003.15l4.792 5.702A.234.234 0 0 0 12 16c.073 0 .14-.027.178-.072l4.792-5.703a.113.113 0 0 0 .003-.15.233.233 0 0 0-.181-.075Z"
      fill={color}
    />
  </svg>
);

DownArrow.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

DownArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DownArrow);
