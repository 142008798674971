import React, { memo } from 'react';
import PropTypes from 'prop-types';

const GoogleDocs = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 42" {...props}>
    <defs />
    <g fill="none" fillRule="evenodd">
      <path
        fill="#5290F5"
        d="M26.98 41.147H2.584A2.584 2.584 0 010 38.563V2.77A2.584 2.584 0 012.584.187h15.543L29.563 11.44v27.122a2.584 2.584 0 01-2.583 2.584"
      />
      <path
        fill="#F1F1F1"
        d="M6.962 22.65h15.037v-1.845H6.962zM6.962 26.34h15.037v-1.845H6.962zM6.962 30.03h15.037v-1.845H6.962zM6.962 33.72h11.44v-1.845H6.961z"
      />
      <path
        fill="#A6C5FA"
        d="M20.753 11.44h8.81L18.126.191v8.624a2.625 2.625 0 002.626 2.625"
      />
    </g>
  </svg>
);

GoogleDocs.defaultProps = {
  width: 30,
  height: 42,
};

GoogleDocs.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(GoogleDocs);
