import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RENTAL_REQUEST_SOURCES from '../../../services/rental-request.source.enum';
import EmailQueryStringProvider from '../providers/EmailQueryStringProvider';

import { HrNew, Link } from './EmailTypography';
import FooterSection from './FooterSection';
import LogoSection from './LogoSection';
import PreviewText from './PreviewText';
import RenterFooter from './RenterFooter';
import UnsubscribeCategories from './UnsubscribeCategories';
const styles = {
  body: {
    fontFamily: "'Open Sans', sans-serif",
    backgroundColor: '#F5F6F8',
    padding: 0,
  },
  containerWrap: {
    padding: '40px 10px',
  },
  mainContainer: {
    backgroundColor: '#fff',
    border: '1px solid #E6E9F0',
    borderRadius: '4px',
  },
  container: {
    color: '#042238',
    padding: '5% 6% 32px 6%',
  },
  containerCentered: {
    margin: '0 auto',
    maxWidth: '600px',
  },
  footer: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '24px 0',
  },
  bannerStyles: {
    display: 'block',
    verticalAlign: 'top',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    minHeight: 170,
    maxHeight: 280,
  },
  bannerContainer: {
    minHeight: 150,
    maxHeight: 280,
    height: 'auto',
    verticalAlign: 'top',
    fontSize: 0,
    overflow: 'hidden',
  },
};

const NormalTransactionalTemplate = ({
  applicationSource,
  logo,
  children,
  banner,
  bannerLink,
  bannerStyles,
  bannerContainerStyles,
  unsubscribeUrl,
  hideLogo,
  footer,
  love,
  hideFooter,
  source,
  medium,
  campaign,
  content,
  ownerEmail,
  userEmail,
  isPreview,
  previewText,
  recipientIsRenter,
  previewTextAdditionalEmptySpaces,
}) => {
  // eslint-disable-next-line no-useless-assignment
  const BannerWrapper = bannerLink ? 'a' : Fragment;
  const isFromRedfin = applicationSource === RENTAL_REQUEST_SOURCES.REDFIN_API;

  const footerToUse = recipientIsRenter ? (
    <RenterFooter showAppStoreButtons />
  ) : (
    footer
  );

  return (
    <html lang="en-US">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;900&display=swap"
          rel="stylesheet"
        />
      </head>
      <body
        style={{
          ...styles.body,
          ...(isFromRedfin ? { backgroundColor: '#FAF9F8' } : {}),
        }}
      >
        <PreviewText
          previewText={previewText}
          previewTextAdditionalEmptySpaces={previewTextAdditionalEmptySpaces}
        />

        <EmailQueryStringProvider
          {...{ ownerEmail, userEmail, campaign, content, source, medium }}
        >
          <div style={styles.containerWrap}>
            <LogoSection
              isFromRedfin={isFromRedfin}
              hideLogo={hideLogo}
              logo={logo}
            />
            <div style={styles.containerCentered}>
              <div style={{ maxWidth: 600 }}>
                <div style={styles.mainContainer}>
                  {banner && (
                    <BannerWrapper
                      {...(bannerLink ? { href: bannerLink } : {})}
                    >
                      <div
                        style={{
                          ...styles.bannerContainer,
                          ...bannerStyles,
                          ...bannerContainerStyles,
                        }}
                      >
                        <img
                          alt="banner"
                          src={`${banner}`}
                          style={{
                            ...styles.bannerStyles,
                            ...bannerStyles,
                          }}
                        />
                      </div>
                    </BannerWrapper>
                  )}
                  <div style={styles.container}>
                    {children}
                    {hideFooter ? null : (
                      <>
                        <HrNew />
                        <div style={{ margin: '0 auto', maxWidth: 456 }}>
                          <div
                            style={{
                              ...styles.footer,
                              paddingBottom: 0,
                              paddingTop: 32,
                            }}
                          >
                            <FooterSection
                              isFromRedfin={isFromRedfin}
                              footerToUse={footerToUse}
                              recipientIsRenter={recipientIsRenter}
                              isPreview={isPreview}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...styles.footer, color: '#042238' }}>
              {love && !recipientIsRenter && (
                <div>
                  <Link
                    skipDLT={true}
                    href="https://rental.turbotenant.com/owners/referrals?utm_source=transactional_email&utm_campaign=referral"
                    style={{ color: '#2370A3' }}
                    disabled={isPreview}
                  >
                    Give $25 and get $25 when you refer another landlord to
                    TurboTenant
                  </Link>{' '}
                  💰
                </div>
              )}

              {unsubscribeUrl && (
                <>
                  <UnsubscribeCategories />
                  <Link
                    href={unsubscribeUrl}
                    footerLink
                    disabled={isPreview}
                    style={{ marginTop: 8, marginBottom: 16, display: 'block' }}
                  >
                    Update Email Preferences
                  </Link>
                </>
              )}

              <div
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  color: '#516A80',
                  fontFamily: '"Open Sans"',
                  fontSize: '14px',
                  letterSpacing: '0',
                  lineHeight: '21px',
                  textAlign: 'center',
                }}
              >
                TurboTenant, Inc © {new Date().getFullYear()}
              </div>
              <div>
                <Link
                  href="https://www.turbotenant.com/terms-of-use/"
                  footerLink
                  disabled={isPreview}
                >
                  Terms of Service
                </Link>
                {' | '}
                <Link
                  href="https://www.turbotenant.com/privacy-policy/"
                  footerLink
                  disabled={isPreview}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </EmailQueryStringProvider>
      </body>
    </html>
  );
};

NormalTransactionalTemplate.defaultProps = {
  banner: '',
  unsubscribeUrl: '',
  hideLogo: false,
  love: true,
  hideFooter: false,
  recipientIsRenter: false,
};

NormalTransactionalTemplate.propTypes = {
  logo: PropTypes.string,
  children: PropTypes.node.isRequired,
  banner: PropTypes.string,
  unsubscribeUrl: PropTypes.string,
  hideLogo: PropTypes.bool,
  footer: PropTypes.node,
  love: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isPreview: PropTypes.bool,
  previewText: PropTypes.string,
  userEmail: PropTypes.string,
  // DTL
  ownerEmail: PropTypes.string,
  // UTM
  campaign: PropTypes.string,
  source: PropTypes.string,
  medium: PropTypes.string,
  content: PropTypes.string,
  bannerLink: PropTypes.string,
  bannerStyles: PropTypes.object,
  bannerContainerStyles: PropTypes.object,
  recipientIsRenter: PropTypes.bool,
  applicationSource: PropTypes.string,
};

export default NormalTransactionalTemplate;
