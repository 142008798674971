import React, { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SubscriptionAddOn from '../../SubscriptionAddOn';

import BenefitItemsMobile from './BenefitItemsMobile';
import BenefitSection from './BenefitSection';
import PackageHeader from './PackageHeader';

import styles from './BenefitsView.module.scss';

const BenefitsView = ({
  benefits,
  selectedBenefit,
  extraParams,
  displayMonthlyPrice,
  proPrice,
  onGetPro,
  premiumPrice,
  onGetPremium,
  isMobile,
  onClickSegment,
  subscriptionAddOnProps,
}) => {
  const PremiumPackageRef = useRef(null);

  const scrollToPremium = () => {
    if (PremiumPackageRef.current) {
      PremiumPackageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return isMobile ? (
    <>
      <div
        className={cx(styles.packageMobile, styles.premium)}
        ref={PremiumPackageRef}
      >
        <PackageHeader
          title="Premium"
          price={premiumPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPremium}
        />
        <BenefitItemsMobile
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          extraParams={extraParams}
          scrollToPremium={scrollToPremium}
          onClickSegment={onClickSegment}
        />
      </div>
      <SubscriptionAddOn {...subscriptionAddOnProps} />
      <div className={cx(styles.packageMobile, styles.pro)}>
        <PackageHeader
          title="Pro"
          price={proPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPro}
          isPro
        />
        <BenefitItemsMobile
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          extraParams={extraParams}
          scrollToPremium={scrollToPremium}
          onClickSegment={onClickSegment}
          isProPlan
        />
      </div>
    </>
  ) : (
    <>
      <div className={styles.headerRow}>
        <PackageHeader
          title="Pro"
          price={proPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPro}
          isPro
        />
        <PackageHeader
          title="Premium"
          price={premiumPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPremium}
        />
      </div>
      <BenefitSection
        benefits={benefits}
        selectedBenefit={selectedBenefit}
        extraParams={extraParams}
      />
    </>
  );
};

BenefitsView.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  displayMonthlyPrice: PropTypes.bool.isRequired,
  proPrice: PropTypes.number.isRequired,
  onGetPro: PropTypes.func.isRequired,
  premiumPrice: PropTypes.number.isRequired,
  onGetPremium: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClickSegment: PropTypes.func.isRequired,
  subscriptionAddOnProps: PropTypes.object.isRequired,
};

export default BenefitsView;
