import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import Card from '../Card';
import SelectionRadioBoxGroup from '../SelectionRadioBoxGroup';
import Tag from '../Tag/Tag';
import { H2, Paragraph, Span } from '../Typography/Typography';

import styles from './ACHFeeCard.module.scss';

const options = [
  {
    value: 'Tenant',
    label: (
      <>
        <Paragraph className={styles.radioTitle} size="large" weight="semi">
          Tenant
        </Paragraph>
        <Span size="large" weight="normal">
          The tenant will pay the $2 fee
        </Span>
      </>
    ),
  },
  {
    value: 'Landlord',
    label: (
      <>
        <Paragraph className={styles.radioTitle} size="large">
          Me
        </Paragraph>
        <Span size="large" weight="normal">
          I will pay the $2 fee (deducted from the payment I receive)
        </Span>
      </>
    ),
  },

  {
    value: 'Neither',
    label: (
      <>
        <Paragraph size="large" className={styles.radioTitle}>
          Neither
          <Tag small accent className={styles.tag}>
            Most common
          </Tag>
        </Paragraph>
        <Span size="large" weight="normal">
          Upgrade to Premium for a $0 ACH fee
        </Span>
      </>
    ),
  },
];

const ACHFeeCard = ({ onSubmit, initialValues, loading }) => {
  return (
    <Card className={styles.container} padded>
      <H2>Who will cover the ACH fee?</H2>
      <Paragraph>
        Many tenants choose to pay rent directly from a bank account. Select who
        will pay the associated processing fee if they do.
      </Paragraph>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={SelectionRadioBoxGroup}
              name="coverAchFee"
              options={options}
              radioProps={{
                className: styles.radioGroupOption,
              }}
              vertical={false}
              containerClassName={styles.radioGroup}
            />

            <Button
              className={styles.button}
              variant="primary"
              disabled={loading}
              loading={loading}
              type="submit"
            >
              Next
            </Button>
          </form>
        )}
      </Form>
    </Card>
  );
};
ACHFeeCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
};
export default ACHFeeCard;
