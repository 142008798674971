import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ReceiveMoney = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 38 38">
    <g fill={color} fillRule="evenodd">
      <ellipse cx="20.249" cy="17.633" rx="4.667" ry="4.661" />
      <ellipse cx="28.203" cy="5.473" rx="4.667" ry="4.661" />
      /
      <path
        fill="#000"
        fillRule="nonzero"
        d="M7.304 21.077c.449 0 .812.364.812.812l-.001.809h4.059c3.043 0 5.496 1.272 7.439 3.06l.194.183h4.54c1.625 0 2.53.953 2.717 2.015l5.99-1.984c2.115-.602 3.77-.064 5.664 1.828a.812.812 0 0 1-.21 1.3c-1.824.911-3.49 1.767-5.29 2.711l-.897.473-2.069 1.096-.363.192c-.848.449-1.672.883-2.01 1.059-2.825 1.461-4.379 2.096-5.938 2.347-1.645.265-3.209.068-5.598-.644-1.12-.333-5.92-1.986-8.23-2.775l.003 1.369a.812.812 0 0 1-.812.811H.812A.812.812 0 0 1 0 34.928V21.889c0-.448.363-.812.812-.812h6.492Zm4.87 3.245-4.059-.001-.002 7.523c1.405.478 4.492 1.541 6.637 2.262l.597.2c.66.22 1.18.39 1.459.473 2.176.648 3.513.816 4.877.597 1.34-.216 2.77-.8 5.45-2.187.813-.42 4.474-2.363 4.43-2.34l.902-.475a301.044 301.044 0 0 1 3.525-1.823l.725-.367-.032-.026c-1.017-.776-1.887-.95-2.97-.683l-.182.048-6.914 2.291c-.42.548-1.113.943-2.08.989l-.19.004H15.42a.812.812 0 0 1 0-1.623h8.928c1.505 0 1.505-1.62 0-1.62h-4.87a.812.812 0 0 1-.573-.237c-1.733-1.73-3.974-3.005-6.731-3.005ZM6.492 22.7h-4.87v11.416h4.87V22.7ZM20.29 11.35a5.678 5.678 0 0 1 5.681 5.675 5.678 5.678 0 0 1-5.681 5.676 5.678 5.678 0 0 1-5.681-5.676 5.678 5.678 0 0 1 5.68-5.676Zm0 1.623a4.055 4.055 0 0 0-4.058 4.052 4.055 4.055 0 0 0 4.058 4.052 4.055 4.055 0 0 0 4.058-4.052 4.055 4.055 0 0 0-4.058-4.052Zm0 1.62c.448 0 .811.362.811.81v3.243a.812.812 0 1 1-1.623 0v-3.242c0-.449.364-.812.812-.812ZM28.406 0a5.678 5.678 0 0 1 5.681 5.676 5.678 5.678 0 0 1-5.681 5.675 5.678 5.678 0 0 1-5.681-5.675A5.678 5.678 0 0 1 28.405 0Zm0 1.623a4.055 4.055 0 0 0-4.058 4.053 4.055 4.055 0 0 0 4.058 4.052 4.055 4.055 0 0 0 4.058-4.052 4.055 4.055 0 0 0-4.058-4.053Zm0 1.62c.448 0 .811.363.811.811v3.243a.812.812 0 1 1-1.623 0V4.054c0-.448.364-.811.812-.811Z"
      />
    </g>
  </svg>
);

ReceiveMoney.defaultProps = {
  width: 54,
  height: 54,
  color: colors.babyBlue,
};

ReceiveMoney.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ReceiveMoney);
