import React from 'react';

const initialState = {
  application: null,
  loading: false,
};

const ApplicationContext = React.createContext(initialState);

export default ApplicationContext;
