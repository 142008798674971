import React from 'react';

import { Experiments } from '../../../../constants/experiments';
import { useActiveExperiments } from '../../../../providers/ActiveExperimentsProvider/context';

import OwnerPaymentsOnboarding from './OwnerPaymentsOnboarding';
import OwnerPaymentsOnboardingTest from './OwnerPaymentsOnboardingTest';

const components = {
  control: OwnerPaymentsOnboarding,
  test: OwnerPaymentsOnboardingTest,
};

const OwnerPaymentsOnboardingWrapper = (props) => {
  const { userExperiments } = useActiveExperiments();

  const variant = userExperiments[Experiments.ChargesFirst.name] || 'control';
  const Component = components[variant];

  return <Component {...props} />;
};

export default OwnerPaymentsOnboardingWrapper;
