import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextJustifyCenter = ({ color, ...props }) => (
  <svg viewBox="0 0 20 18" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.012 16.627a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024ZM17.445.2a.587.587 0 1 1 0 1.173H2.555a.587.587 0 1 1 0-1.173h14.89Zm-2.743 2.347a.587.587 0 1 1 0 1.173H5.298a.587.587 0 1 1 0-1.173h9.404Zm1.96 2.346a.587.587 0 1 1 0 1.174H3.338a.587.587 0 1 1 0-1.174H16.66Zm2.35 2.347a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024Zm-3.134 2.347a.587.587 0 1 1 0 1.173H4.122a.587.587 0 1 1 0-1.173h11.756Zm-1.96 2.346a.587.587 0 1 1 0 1.174H6.082a.587.587 0 1 1 0-1.174h7.836Zm1.96 2.347a.587.587 0 1 1 0 1.173H4.122a.587.587 0 1 1 0-1.173h11.756Z"
    />
  </svg>
);

TextJustifyCenter.defaultProps = {
  width: 20,
  height: 18,
  color: 'currentColor',
};

TextJustifyCenter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextJustifyCenter);
