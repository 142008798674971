import React, { memo } from 'react';
import PropTypes from 'prop-types';

const OrderedList = ({ color, ...props }) => (
  <svg viewBox="0 0 20 18" {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M18.999 2.053c.332 0 .601.223.601.5 0 .276-.27.5-.601.5H5.365c-.332 0-.601-.224-.601-.5 0-.277.269-.5.601-.5H19Zm0 6.484c.332 0 .601.224.601.5s-.27.5-.601.5H5.365c-.332 0-.601-.224-.601-.5s.269-.5.601-.5H19Zm0 6.484c.332 0 .601.224.601.5s-.27.5-.601.5H5.365c-.332 0-.601-.224-.601-.5s.269-.5.601-.5H19ZM1.906.383c.415-.395 1.112-.108 1.112.458v3.35c0 .354-.293.64-.654.64a.647.647 0 0 1-.655-.64V2.359l-.197.19a.666.666 0 0 1-.853.052l-.073-.063a.63.63 0 0 1 .011-.905L1.907.383Zm-.222 6.301c.708 0 1.284.651 1.284 1.473 0 .472-.08.807-.276 1.134-.027.045-.27.351-.555.726l-.018.023h.35c.278 0 .508.24.544.551l.005.087c0 .352-.246.638-.55.638H.951c-.452 0-.71-.597-.442-1.018.09-.14.192-.29.298-.442L.97 9.63c.409-.562.824-1.076.82-1.069.058-.097.078-.178.078-.403 0-.11-.077-.197-.184-.197-.098 0-.157.07-.157.19 0 .352-.247.638-.55.638-.304 0-.55-.286-.55-.638 0-.821.548-1.466 1.257-1.466Zm.761 6.484c.454 0 .727.612.48 1.074l-.36.67.051.048c.244.26.376.642.398 1.098l.003.139c0 .94-.439 1.603-1.335 1.603h-.71c-.316 0-.572-.311-.572-.695 0-.383.256-.694.572-.694h.765c.145 0 .136-.006.136-.214v-.063c0-.166-.009-.152-.19-.152-.455 0-.728-.612-.48-1.074l.186-.35H.972c-.29 0-.529-.261-.567-.6L.4 13.862c0-.384.256-.695.572-.695h1.473Z"
    />
  </svg>
);

OrderedList.defaultProps = {
  width: 20,
  height: 18,
  color: 'currentColor',
};

OrderedList.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(OrderedList);
