import React from 'react';

import { Experiments } from '../../constants/experiments';
import { useUserProfile } from '../../core/TTgraphql';
import getExperimentVariant from '../../helpers/experiments';
import ACHFeeCardWrapped from '../ACHFeeCard/ACHFeeCardWrapper';
import PremiumSubscriptionModalWrapped from '../PaymentsPremiumPromotion/PaymentsPremiumPromotionWrapper';

const PremiumUpsellPaymentsExp = (props) => {
  const { user } = useUserProfile({}, false);

  const experiment = getExperimentVariant(
    user,
    Experiments.PaymentOnboardingUpsellPremium.name,
  );

  if (
    experiment ===
    Experiments.PaymentOnboardingUpsellPremium.variants.premium_card
  ) {
    return <PremiumSubscriptionModalWrapped {...props} />;
  }

  return <ACHFeeCardWrapped {...props} />;
};

export default PremiumUpsellPaymentsExp;
