import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import FinalFormError from '../../../components/FinalFormError';
import PremiumRadioButtonOffer, {
  ShowPremiumPriceForOffer,
} from '../../../components/PremiumRadioButtonOffer';
import { MODAL_LOCATIONS } from '../../../components/PremiumSubscription/constants';
import getPremiumPurchasedFrom from '../../../components/PremiumSubscription/getPremiumPurchasedFrom';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import fieldRequired from '../../../validators/fieldRequired';
import WhoWillPay from '../../owners/external_application/sections/WhoWillPay';
import PublicScreeningReportSidebar from '../PublicScreeningReportSidebar';

import styles from './ScreeningReportTypeStep.module.scss';

const ScreeningReportTypeStep = ({
  initialValues = {},
  applicationFee,
  onSubmit,
  segmentData = {},
}) => {
  return (
    <MarketingLayout
      hideFooter
      side={
        <PublicScreeningReportSidebar
          segmentLocation="Screening Type - Public Screening"
          segmentData={segmentData}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles.title}>Screening Report Type</h3>
          <div className={styles.subTitle}>
            Which screening report type do you prefer?
          </div>
          <Form
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            subscription={{
              submitting: true,
              values: true,
            }}
            initialValues={initialValues}
          >
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <FinalFormError />
                <Field
                  component={PremiumRadioButtonOffer}
                  validate={fieldRequired}
                  hideLabel={true}
                  name="premium_offer"
                  purchase_location={getPremiumPurchasedFrom(
                    MODAL_LOCATIONS.PUBLIC_SCREENING,
                  )}
                  openLocation={MODAL_LOCATIONS.PUBLIC_SCREENING}
                  className={styles.premiumOffer}
                  {...{
                    premium: {
                      title: 'PREMIUM SCREENING REPORT',
                      desc: (
                        <>
                          <span> Background, Eviction, Credit </span>
                          <b>
                            {' '}
                            + <i> Income Insights</i>
                          </b>
                        </>
                      ),
                      desc2: (
                        <>
                          <ShowPremiumPriceForOffer /> Screening Fee{' '}
                        </>
                      ),
                      tracking: {
                        event: 'premium_app_type clicked',
                        location: 'Screening Type - Public Screening',
                        ...segmentData,
                      },
                    },
                    free: {
                      title: 'STANDARD SCREENING REPORT',
                      desc: 'Background, Eviction, Credit Only',
                      desc2: `$${applicationFee} Screening Fee`,
                      tracking: {
                        event: 'normal_app clicked',
                        location: 'Screening Type - Public Screening',
                        ...segmentData,
                      },
                    },
                  }}
                />
                <WhoWillPay applicationFee={applicationFee} hideTitle={true} />
                <div className={styles.actions}>
                  <Button
                    className={styles.submit}
                    type="submit"
                    loading={submitting}
                    id="signup_submit"
                    data-qa="external-application-functional-button-next"
                    onClick={() => {
                      segmentTracking('next clicked', {
                        location: 'Screening Type - Public Screening',
                        ...segmentData,
                      });
                    }}
                  >
                    {values.landlord_pays ? 'Next' : 'Request Report'}
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </MarketingLayout>
  );
};

ScreeningReportTypeStep.propTypes = {
  initialValues: PropTypes.object,
  applicationFee: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  segmentData: PropTypes.object,
};

export default ScreeningReportTypeStep;
