import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextJustifyRight = ({ color, ...props }) => (
  <svg viewBox="0 0 20 18" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.012 16.627a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024Zm0-16.427a.587.587 0 1 1 0 1.173H4.122a.587.587 0 1 1 0-1.173h14.89Zm0 2.347a.587.587 0 1 1 0 1.173H6.473a.587.587 0 1 1 0-1.173h12.54Zm0 2.346a.587.587 0 1 1 0 1.174H4.122a.587.587 0 1 1 0-1.174h14.89Zm0 2.347a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024Zm0 2.347a.587.587 0 1 1 0 1.173H4.122a.587.587 0 1 1 0-1.173h14.89Zm0 2.346a.587.587 0 1 1 0 1.174H6.473a.587.587 0 1 1 0-1.174h12.54Zm0 2.347a.587.587 0 1 1 0 1.173H4.122a.587.587 0 1 1 0-1.173h14.89Z"
    />
  </svg>
);

TextJustifyRight.defaultProps = {
  width: 20,
  height: 18,
  color: 'currentColor',
};

TextJustifyRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextJustifyRight);
