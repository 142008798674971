import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import MaintenanceRequestWorkOrder from 'api-emails/MaintenanceRequestWorkOrder';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';

import formatPhone from '../../../helpers/format-phone';
import PlusIcon from '../../../icons/streamline/Plus';
import { NEW_PRO_ID } from '../../../pages/properties/maintenance/send_work_order/SendWorkOrderPage';
import fieldRequired from '../../../validators/fieldRequired';
import Button from '../../Button';
import Card from '../../Card';
import CustomSelect from '../../CustomSelect';
import FinalFormError from '../../FinalFormError';
import Input from '../../Input';
import PreviewEmailModal from '../../PreviewEmailModal';

import NewProForm from './NewProForm';
import ProCustomLabel from './ProCustomLabel';

import styles from './SendWorkOrderForm.module.scss';

const SendWorkOrderForm = ({
  className,
  initialValues,
  onSubmit,
  mRequest,
  listing,
  user,
  pros = [],
  onNewProSave,
}) => {
  const [previewModal, setPreviewModal] = useState(false);
  const firstName = get(user, 'first_name', '');
  const lastName = get(user, 'last_name', '');
  const mrTitle = get(mRequest, 'title', '');
  const mrDescription = get(mRequest, 'description', '');
  const mrCreatedAt = get(mRequest, 'created_at', '');
  const mrFormatedDate = moment(mrCreatedAt).format('MM/DD/YYYY');
  const mrFirstName = get(mRequest, 'renter.first_name', '');
  const mrLastName = get(mRequest, 'renter.last_name', '');
  const mrAttachments = get(mRequest, 'maintenance_request_attachments', []);
  const renter = mRequest &&
    mRequest.renter && {
      ...mRequest.renter,
      telephone: formatPhone(mRequest.renter.telephone),
    };

  const proOptions = pros
    .map((pro) => ({
      label: ({ isSelected }) => (
        <ProCustomLabel
          name={pro.name}
          company={pro.company}
          email={pro.email}
          isSelected={isSelected}
        />
      ),
      value: pro.id,
      name: pro.name,
      company: pro.company,
    }))
    .concat({
      label: (
        <div className={styles.newProLabel}>
          <span className={styles.newProIcon}>
            <PlusIcon width={32} height={32} />
          </span>
          <span className={styles.newProText}>New maintenance pro</span>
        </div>
      ),
      value: NEW_PRO_ID,
    });

  const renderOption = (option, _isHighlighted, isSelected) =>
    typeof option.label === 'function'
      ? option.label({ isSelected })
      : option.label;

  const renderSelectedOption = (option) => {
    return (
      <div className={styles.selectedOption}>
        {option.name} {option.company ? `| ${option.company}` : null}
      </div>
    );
  };

  return (
    <Card className={cx(styles.card, className)}>
      <h2 className={styles.title}>Send Work Order</h2>
      <p className={styles.subTitle}>
        Send your maintenance person an email with the information from the
        maintenance request as well as the tenant's contact information.
      </p>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{
          submitting: true,
          values: true,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={handleSubmit} className={styles.container}>
            <div className={styles.maintenanceItem}>
              <span className={cx(styles.date)}>{mrFormatedDate}</span>
              <span className={cx(styles.label)}>{mrTitle}</span>
              {mrFirstName && mrLastName && (
                <span className={cx(styles.reqBy)}>
                  Requested by
                  <span
                    className={cx(styles.name)}
                  >{`${mrFirstName} ${mrLastName}`}</span>
                </span>
              )}
            </div>
            <FinalFormError />
            {values?.pro === NEW_PRO_ID ? (
              <NewProForm
                onSave={(data) => onNewProSave(data, form)}
                onClear={() => {
                  form.change('pro', null);
                }}
                vendorsCount={pros.length}
              />
            ) : (
              <Field
                label="Select a Pro"
                component={CustomSelect}
                name="pro"
                id="pro"
                options={proOptions}
                renderOption={renderOption}
                renderSelectedOption={renderSelectedOption}
                validate={fieldRequired}
                selectedClassName={styles.proSelected}
                optionClassName={styles.proOption}
              />
            )}
            <Field
              label="Additional Message"
              hint="(Optional)"
              component={Input}
              name="additional_message"
              id="additional_message"
              className={styles.message}
              inputProps={{
                as: 'textarea',
              }}
              maxLength={1000}
              help={(val = '') => `${val.length} / 1000 characters used`}
            />
            <Button
              className={styles.submit}
              type="submit"
              loading={submitting}
              id="work_order_submit"
              disabled={submitting || values.pro === NEW_PRO_ID}
            >
              Send work order
            </Button>
            <div className={styles.row} onClick={() => setPreviewModal(true)}>
              <a className={styles.link}>Preview Email We Send</a>
            </div>
            <PreviewEmailModal
              open={previewModal}
              onClose={() => setPreviewModal(false)}
            >
              <MaintenanceRequestWorkOrder
                owner={{
                  first_name: firstName,
                  last_name: lastName,
                }}
                messageFromOwner={
                  (form.getFieldState('additional_message') || {}).value
                }
                maintenanceRequest={{
                  title: mrTitle,
                  description: mrDescription,
                }}
                listing={listing || {}}
                photos={mrAttachments}
                renter={renter}
              />
            </PreviewEmailModal>
          </form>
        )}
      </Form>
    </Card>
  );
};

SendWorkOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  className: PropTypes.string,
  mRequest: PropTypes.object.isRequired,
  listing: PropTypes.object,
  user: PropTypes.object,
  pros: PropTypes.array,
  onNewProSave: PropTypes.func.isRequired,
};

SendWorkOrderForm.defaultProps = {
  initialValues: {},
};

export default SendWorkOrderForm;
