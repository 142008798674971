import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfoCA.module.scss';

const BankInfoCA = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="California Security Deposit Collection Laws"
        collapsable
      >
        <p className={styles.desc}>
          The maximum security deposit is one month's rent for furnished or
          unfurnished properties. You may charge a maximum of two months' rent,
          if you own 2 or less properties with a collective total of 4 or less
          units. You must be a natural person or a limited liability owned by
          natural person(s) or a family trust.
        </p>
      </HelpfulInfoCard>
    </div>
  );
};

BankInfoCA.propTypes = {
  className: PropTypes.string,
};

export default BankInfoCA;
