/**
 * Retrieves all experiment cookies and returns them in a structured format
 * @returns {Array<{name: string, value: string}>} Array of experiment names and their assigned variants
 */
function getCookieExperiments() {
  if (!document.cookie) {
    return [];
  }

  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());

  return cookies
    .filter((cookie) => cookie.startsWith('experiment_'))
    .map((cookie) => {
      const [fullName, value] = cookie.split('=');
      const name = fullName.replace('experiment_', '');

      return {
        name,
        value,
      };
    });
}

export default getCookieExperiments;
