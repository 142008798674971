import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import basicUserProfile from '../../../../../graphql/basicUserProfile.graphql';
import refreshBankAccountLoginMut from '../../../../../graphql/mutations/payments/refreshBankAccountLogin.graphql';
import bannerGraphlQuery from '../../../../../graphql/queries/globalBanner.graphql';
import { getLinkToken } from '../../../../../services/plaidService';
import Button from '../../../../Button';
import Spinner from '../../../../Spinner';
import DismissAction from '../DismissAction';

import styles from '../../GlobalBanner.module.scss';
import secondaryStyles from './BankAccountLoginExpired.module.scss';

const BankAccountLoginExpired = ({ bannerId }) => {
  const [plaidConfig, setPlaidConfig] = useState({});
  const { error, ready, open: openPlaidLink } = usePlaidLink(plaidConfig);

  const [refreshBankAccountLogin, { loading: refreshing }] = useMutation(
    refreshBankAccountLoginMut,
    {
      refetchQueries: [
        { query: bannerGraphlQuery },
        { query: basicUserProfile },
      ],
      awaitRefetchQueries: true,
    },
  );

  const triggerPlaidLink = useCallback(() => {
    if (ready) openPlaidLink();
  }, [ready]);

  useEffect(() => {
    getLinkToken(null, true, true)
      .then((linkToken) => {
        setPlaidConfig({
          onSuccess: async () => {
            await refreshBankAccountLogin();
          },
          token: linkToken,
        });
      })
      .catch((error) => {
        if (window.atatus) {
          window.atatus.notify({
            message: 'Failed to get Plaid link token for global banner',
            error,
          });
        }
      });
  }, []);

  if (refreshing) {
    return (
      <div className={styles['gb-style-danger']}>
        <Spinner secondary />
      </div>
    );
  }

  return (
    <div className={styles['gb-style-danger']}>
      <div className={secondaryStyles.content}>
        Your rent payment method has expired. Please log in to reconnect.
        <Button
          variant="ghost"
          small
          className={secondaryStyles.button}
          disabled={!ready || error}
          onClick={triggerPlaidLink}
        >
          Log in
        </Button>
      </div>
      <DismissAction bannerId={bannerId} />
    </div>
  );
};

BankAccountLoginExpired.propTypes = {
  bannerId: PropTypes.string.isRequired,
};

export default BankAccountLoginExpired;
