import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextStrikethrough = ({ color, ...props }) => (
  <svg viewBox="0 0 21 20" {...props} fill={color}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.2.4c2.994 0 4.77 1.024 4.77 3.472v.817a.618.618 0 0 1-.622.613.618.618 0 0 1-.622-.613v-.817c0-1.596-1.128-2.246-3.526-2.246-2.203 0-3.526.943-3.526 2.655 0 1.67 1.001 2.661 3.969 4.455l.297.18c.634.383 1.197.74 1.694 1.083l8.154.001c.338 0 .612.358.612.8 0 .442-.274.8-.612.8H13.55c.878.965 1.25 1.966 1.25 3.302 0 2.93-2.333 4.698-5.6 4.698-3.414 0-5.6-1.41-5.6-4.29v-.816c0-.339.279-.613.622-.613.344 0 .622.274.622.613v.817c0 2.067 1.546 3.063 4.356 3.063 2.64 0 4.356-1.3 4.356-3.472 0-1.312-.505-2.238-1.832-3.302H1.012c-.338 0-.612-.358-.612-.8 0-.442.274-.8.612-.8h8.342a54.089 54.089 0 0 0-.065-.04l-.297-.18C5.658 7.767 4.43 6.55 4.43 4.28 4.43 1.79 6.38.4 9.2.4Z"
    />
  </svg>
);

TextStrikethrough.defaultProps = {
  width: 21,
  height: 20,
  color: 'currentColor',
};

TextStrikethrough.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextStrikethrough);
