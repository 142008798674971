import React from 'react';
import PropTypes from 'prop-types';

import SelectionRadioBoxGroup from '../../../components/SelectionRadioBoxGroup';
import { Experiments } from '../../../constants/experiments';
import { useCookieExperimentVariant } from '../../../helpers/experiments/useCookieExperimentVariant';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import LandlordKeyIcon from '../../../icons/Key';
import RenterHouseIcon from '../../../icons/RenterHouse';

import LogoGardenSidebarAnimatedMobile from './ExperimentSidebars/LogoGardenSidebarAnimatedMobile';

import styles from './WhichDescribeYouSection.module.scss';

const WhichDescribeYouSection = ({ onChange, value }) => {
  const isMobile = useIsSmallScreen();
  const logogardenMobileAnimatedExperiment = useCookieExperimentVariant(
    Experiments.GtAnimatedLogogardenMobile.name,
  );

  const isLogoGardenAnimatedVariant =
    logogardenMobileAnimatedExperiment ===
    Experiments.GtAnimatedLogogardenMobile.variants.variant_1;
  const shouldRenderLogoGardenAnimation =
    isMobile && isLogoGardenAnimatedVariant && !value;
  return (
    <>
      <SelectionRadioBoxGroup
        vertical
        id="landlord"
        options={[
          {
            label: 'Landlord',
            value: 'landlord',
            icon: LandlordKeyIcon,
            id: 'landlord',
          },
          {
            label: 'Renter',
            value: 'renter',
            icon: RenterHouseIcon,
            id: 'renter',
          },
        ]}
        input={{
          name: 'which-describe-you',
          onChange,
          value,
        }}
        radioProps={{
          className: styles.describeRadio,
        }}
      />
      {shouldRenderLogoGardenAnimation && <LogoGardenSidebarAnimatedMobile />}
    </>
  );
};

WhichDescribeYouSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WhichDescribeYouSection;
