import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import PlainCheckbox from '../PlainCheckbox';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  id,
  input,
  name,
  label,
  disabled,
  className,
  meta, // ignored
  labelClassName,
  labelCheckedClassName,
  checked,
  wholeContainerClickable,
  checkedIcon: Icon,
  controlled = false, // prevent "input" prop values from overriding other props
  ignoreError = false,
  ...restProps
}) => {
  const WrapperElement = wholeContainerClickable ? 'label' : 'div';
  const isChecked = controlled ? checked : input?.checked || checked;

  const error = ignoreError ? null : getFieldError(meta, false);

  return (
    <WrapperElement
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
        [styles.checked]: isChecked,
        [styles.wholeContainerClickable]: wholeContainerClickable,
        [styles.hasError]: error,
      })}
      {...(WrapperElement ? { htmlFor: id } : {})}
    >
      <label className={styles.checkboxContainer}>
        <PlainCheckbox
          name={name}
          checked={isChecked}
          {...(controlled
            ? { value: input?.value, onChange: input?.onChange }
            : input)}
          id={id}
          disabled={disabled}
          className={styles.checkbox}
          checkedIcon={Icon}
          {...restProps}
        />
        {label && (
          <span
            className={cx(styles.label, labelClassName, {
              [labelCheckedClassName]: isChecked,
            })}
          >
            {label}
          </span>
        )}
      </label>
      {error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </WrapperElement>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.object,
  checkedIcon: PropTypes.any,
  labelClassName: PropTypes.string,
  labelCheckedClassName: PropTypes.string,
  checked: PropTypes.bool,
  wholeContainerClickable: PropTypes.bool,
  controlled: PropTypes.bool,
  ignoreError: PropTypes.bool,
};

Checkbox.defaultProps = {
  meta: {},
  controlled: false,
  ignoreError: false,
};

export default Checkbox;
