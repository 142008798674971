import React, { memo } from 'react';
import PropTypes from 'prop-types';

const GoogleSheets = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 42" {...props}>
    <defs />
    <g fill="none" fillRule="evenodd">
      <path
        fill="#23A566"
        d="M30 39.463a2.413 2.413 0 01-2.413 2.413H2.413A2.413 2.413 0 010 39.463V2.547A2.413 2.413 0 012.413.134h16.186L30 11.81v27.652"
      />
      <path
        fill="#1C8F5A"
        d="M19.856 11.515L30 21.206l-.03-9.41h-8.978a2.4 2.4 0 01-1.13-.281h-.006"
      />
      <path
        fill="#8ED1B1"
        d="M30 11.796h-9.008a2.413 2.413 0 01-2.413-2.413V.123L30 11.797"
      />
      <path
        fill="#FFF"
        d="M7.199 34.155h15.603V20.643H7.198v13.512zm8.766-9.169h4.906v-2.413h-4.906v2.413zm0 3.7h4.906v-2.252h-4.906v2.252zm0 3.539h4.906v-2.091h-4.906v2.09zm-6.836 0h4.906v-2.091H9.129v2.09zm0-3.539h4.906v-2.252H9.129v2.252zm0-3.7h4.906v-2.413H9.129v2.413z"
      />
    </g>
  </svg>
);

GoogleSheets.defaultProps = {
  width: 30,
  height: 42,
};

GoogleSheets.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(GoogleSheets);
