import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { segmentTracking } from '../../services/utilities';
import GoogleLogin from '../GoogleLogin';
import SocialButton from '../SocialButton';
import { useErrorToast } from '../Toast';

import styles from './EmbeddedSocialSignup.module.scss';

const EmbeddedSocialSignup = ({ className, handleSignUp, segmentLocation }) => {
  const showErrorMessage = useErrorToast();

  const socialSignUp = async (signUpData) => {
    try {
      await handleSignUp(signUpData);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <GoogleLogin
        onSuccess={socialSignUp}
        onFailure={() => {
          showErrorMessage('An error occurred while logging in with Google');
        }}
        onClick={() => {
          segmentTracking('google_signup clicked', {
            location: segmentLocation,
          });
        }}
        type="signup"
        className={styles.googleButton}
        GoogleButton={SocialButton}
        buttonProps={{
          socialNetwork: 'google',
          type: 'signup',
        }}
      />
    </div>
  );
};

EmbeddedSocialSignup.propTypes = {
  handleSignUp: PropTypes.func.isRequired,
  className: PropTypes.string,
  segmentLocation: PropTypes.string,
};

export default withRouter(EmbeddedSocialSignup);
