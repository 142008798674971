/**
 * Clears experiment cookies that are no longer active according to the status endpoint
 * @param {Object[]} activeCookieExperiments - Array of active experiments from status endpoint
 */
function clearExpiredCookieExperiments(activeCookieExperiments) {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const experimentCookies = cookies.filter((cookie) =>
    cookie.startsWith('experiment_'),
  );

  const activeExperimentNames = activeCookieExperiments.map(
    (exp) => `experiment_${exp}`,
  );

  experimentCookies.forEach((cookieStr) => {
    const cookieName = cookieStr.split('=')[0];
    if (!activeExperimentNames.includes(cookieName)) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  });
}

export default clearExpiredCookieExperiments;
