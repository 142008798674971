import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import BackButton from '../../../../components/BackButton';
import LoadingScreen from '../../../../components/LoadingScreen';
import Logo from '../../../../components/Logo';
import { useUserProfile } from '../../../../core/TTgraphql';
import { getUserBankAccounts } from '../../../../helpers/getUserBankAccounts';
import hasOwnerFinishedPaymentsOnboarding from '../../../../helpers/hasOwnerFinishedPaymentsOnboarding';
import { useRentPaymentRules } from '../../../../hooks/useRentPaymentRules';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities/segment';

import CollectionWrapper from './components/CollectionWrapper';
import HowPaymentsWork from './components/HowPaymentsWork';
import SetupConfirmationWrapper from './components/SetupConfirmationWrapper';
import ChargesWrapper from './ChargesFirst';

import styles from './OwnersPaymentsOnboarding.module.scss';

const OwnersPaymentsOnboardingTest = ({ match, history, location }) => {
  const backButtonOverride = useRef();

  const { PRIVATE_BASE_PATH } = useConfig();
  const { wantsOfflineTracking } = useRentPaymentRules();

  const CHARGES_STEP = 1;
  const BANK_STEP = 2;
  const SUMMARY_STEP = 3;

  const step = parseInt(match?.params?.step || 0);

  const isEdit = match?.params?.isEdit === 'true' || false;
  const { ONLINE, OFFLINE } = parse(location.search, { parseBooleans: true });

  const { user, loading: loadingUser } = useUserProfile({ polling: false });
  const hasUserFinishedPaymentsOnboarding =
    hasOwnerFinishedPaymentsOnboarding(user);

  useEffect(() => {
    if (hasUserFinishedPaymentsOnboarding) {
      history.replace('/owners/dashboard');
    }
  }, [hasUserFinishedPaymentsOnboarding, history]);

  const isPremiumUser = user.premium_subscription_subscribed;

  const userBankAccounts = getUserBankAccounts(user);

  // If user is not premium, there's no need to display which account is selected
  const bankAccountOptions = user.premium_subscription_subscribed
    ? [
        ...userBankAccounts.map((ba) => ({
          value: ba.id,
          label: ba.nickname || ba.bank_name,
          default: ba.default_for_currency,
        })),
      ]
    : [];

  // const getCurrentStep = () => {
  //   switch (step) {
  //     case CHARGES_STEP:
  //       return 'Charges';
  //     case BANK_STEP:
  //       return 'Collection';
  //   }
  // };

  const getNextStepLink = (leaseId) => {
    const paramsString =
      ONLINE || OFFLINE ? stringify({ ONLINE, OFFLINE }) : '';
    if (step === SUMMARY_STEP) {
      return `${PRIVATE_BASE_PATH}leases/view/${leaseId}/payments?fromSetupFlow=true&${paramsString}${
        wantsOfflineTracking ? '&offlineOnboardingCompleted=true' : ''
      }`;
    }
    if (isEdit) {
      return `${PRIVATE_BASE_PATH}payments/onboarding/${SUMMARY_STEP}?${paramsString}`;
    }
    return `${PRIVATE_BASE_PATH}payments/onboarding/${
      step + 1
    }?${paramsString}`;
  };

  const onEditClicked = (step, params = null) => {
    const paramsString = params ? stringify(params) : '';
    switch (step) {
      case 'property':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${CHARGES_STEP}/true?${paramsString}`,
        );
      case 'charges':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${CHARGES_STEP}/true?${paramsString}`,
        );
      case 'bank':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${BANK_STEP}/true?${paramsString}`,
        );
    }
  };

  const queryParams = parse(location.search, { parseBooleans: true });
  const { bankStep } = queryParams;

  const getContent = () => {
    const commonProps = {
      onNext: (leaseId) => {
        history.push(getNextStepLink(leaseId));
        switch (step) {
          case CHARGES_STEP:
            segmentTracking('charges_step submit', {
              location: 'rp onboarding charges step',
            });
            break;
          case BANK_STEP:
            segmentTracking('collection_step submit', {
              location: 'rp onboarding collection step',
            });
            break;
          case SUMMARY_STEP:
            segmentTracking('summary_screen_submit clicked', {
              location: 'rp onboarding summary screen',
            });
            break;
        }
      },
      onSkipCharges: (leaseId) => {
        history.push(getNextStepLink(leaseId));
        return (
          'Charges',
          segmentTracking('charges_step submit', {
            location: 'rp onboarding skip charges step',
          })
        );
      },
      isEdit,
    };

    switch (step) {
      case 0:
        return (
          <HowPaymentsWork
            className={styles.paymentsContainer}
            segmentLocation="rp onboarding intro"
            onBack={() => {
              history.push(`/onboarding/process`);
            }}
            action={{
              to: `${PRIVATE_BASE_PATH}payments/onboarding/${step + 1}`,
              label: 'Continue',
              eventName: 'next',
            }}
          />
        );
      case CHARGES_STEP:
        return (
          <ChargesWrapper
            {...commonProps}
            loadingUser={loadingUser}
            isPremiumUser={isPremiumUser}
            bankAccountOptions={bankAccountOptions}
          />
        );
      case BANK_STEP:
        return (
          <CollectionWrapper
            bankStep={bankStep || isEdit}
            onNext={(data) => {
              const nextStep = getNextStepLink();
              const queryParams = stringify(data);

              history.push(`${nextStep}${queryParams}`);
            }}
            overrideBackButtonClick={(override) => {
              backButtonOverride.current = override;
            }}
            history={history}
            location={location}
            onboarding
          />
        );

      case SUMMARY_STEP:
        return (
          <SetupConfirmationWrapper
            showPropertySection={true}
            hideLeaseSection
            {...commonProps}
            onEdit={onEditClicked}
          />
        );
    }
  };

  const getBackLink = useCallback(() => {
    return `${PRIVATE_BASE_PATH}payments/onboarding/${step - 1}`;
  }, [queryParams, step]);

  // const progressBarOptions = ['Charges', 'Collection'];
  return (
    <LoadingScreen loading={loadingUser}>
      <div className={cx(styles.container, { [styles.firstStep]: step === 0 })}>
        <Logo width={162} height={48} className={styles.logo} />
        {step !== 0 && step !== SUMMARY_STEP && !isEdit && (
          <div className={cx(styles.header, styles.test)}>
            {step > 0 && !isEdit && (
              <BackButton
                className={styles.back}
                onClick={(e) => {
                  if (backButtonOverride.current) {
                    e.preventDefault();
                    backButtonOverride.current();
                  }
                }}
                to={getBackLink()}
              />
            )}

            {/* {!loadingRentPaymentRules && (
              <ProgressBar
                options={progressBarOptions}
                active={getCurrentStep()}
                className={styles.progress}
              />
            )} */}
          </div>
        )}
        {getContent()}
      </div>
    </LoadingScreen>
  );
};

OwnersPaymentsOnboardingTest.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default OwnersPaymentsOnboardingTest;
