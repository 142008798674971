import React from 'react';
import cx from 'classnames';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import DownArrow from '../../icons/streamline/filled/DownArrow';
import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import styles from './CustomSelect.module.scss';

const CustomSelect = ({
  id,
  disabled,
  options,
  input,
  label,
  labelProps,
  className,
  renderOption,
  renderSelectedOption,
  meta,
  dataQa,
  placeholder,
  highlightedClassName,
  selectedClassName,
  optionClassName,
}) => {
  const error = getFieldError(meta);
  const selectedOption = options.find((opt) => opt.value === input.value);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items: options,
    selectedItem: selectedOption,
    onSelectedItemChange: ({ selectedItem }) => {
      input.onChange(selectedItem?.value);
    },
  });

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={styles.selectWrapper}>
        <button
          {...getToggleButtonProps()}
          type="button"
          className={cx(styles.trigger, {
            [styles.invalid]: !!error,
            [styles.disabled]: disabled,
          })}
          data-qa={dataQa}
          disabled={disabled}
        >
          {!selectedOption && (
            <span className={styles.placeholder}>{placeholder || ''}</span>
          )}
          {selectedOption &&
            renderSelectedOption &&
            renderSelectedOption(selectedOption)}
          {selectedOption &&
            !renderSelectedOption &&
            renderOption(selectedOption, false)}
          <DownArrow className={styles.caret} />
        </button>

        <ul
          {...getMenuProps()}
          className={cx(styles.menu, {
            [styles.open]: isOpen,
          })}
        >
          {isOpen &&
            options.map((item, index) => (
              <li
                key={item.value}
                {...getItemProps({
                  item,
                  index,
                  className: cx(styles.option, optionClassName, {
                    [styles.highlighted]: highlightedIndex === index,
                    [highlightedClassName]:
                      highlightedClassName && highlightedIndex === index,
                    [styles.selected]: selectedOption?.value === item.value,
                    [selectedClassName]:
                      selectedClassName && selectedOption?.value === item.value,
                  }),
                })}
              >
                {renderOption(
                  item,
                  highlightedIndex === index,
                  selectedOption?.value === item.value,
                )}
              </li>
            ))}
        </ul>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.object,
  className: PropTypes.string,
  renderOption: PropTypes.func.isRequired,
  renderSelectedOption: PropTypes.func,
  meta: PropTypes.object,
  dataQa: PropTypes.string,
  placeholder: PropTypes.string,
  highlightedClassName: PropTypes.string,
  selectedClassName: PropTypes.string,
  optionClassName: PropTypes.string,
};

export default CustomSelect;
