import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const StarPartialFilled = ({ color, innerColor, ...props }) => (
  <svg {...props} viewBox="0 0 56 56" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 42.875L14.285 50.2657L16.9044 34.612L5.80872 23.526L21.1425 21.2422L28 7L34.8575 21.2422L50.1914 23.526L39.0957 34.612L41.715 50.2657L28 42.875Z"
      fill={innerColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1735 5.19593L20.4549 19.4994L5.44683 21.7917L5.32074 21.8198C4.67345 22.0116 4.43643 22.8555 4.93267 23.3638L15.8105 34.5067L13.2424 50.2506L13.23 50.376C13.2059 51.0748 13.9509 51.5675 14.5833 51.2181L27.9995 43.8048L41.4166 51.2181L41.5292 51.2712C42.1718 51.5237 42.8752 50.9715 42.7575 50.2506L40.1874 34.5067L51.0672 23.3638L51.1518 23.2648C51.5576 22.7184 51.2504 21.8982 50.5531 21.7917L35.5442 19.4994L28.8264 5.19593C28.495 4.49023 27.505 4.49023 27.1735 5.19593ZM27.9995 7.75407L34.1007 20.7446L34.1644 20.8586C34.3063 21.0756 34.5331 21.2241 34.7909 21.2635L48.4961 23.3558L38.558 33.5375L38.4764 33.6333C38.3273 33.8347 38.2641 34.0908 38.3052 34.3428L40.6439 48.6756L28.4377 41.9327L28.3176 41.8773C28.0718 41.785 27.7961 41.8035 27.5622 41.9327L15.354 48.6756L17.6947 34.3428L17.7066 34.2168C17.7135 33.965 17.6191 33.719 17.4419 33.5375L7.50174 23.3558L21.209 21.2635C21.5097 21.2176 21.7684 21.0231 21.8992 20.7446L27.9995 7.75407Z"
      fill={color}
    />
  </svg>
);

StarPartialFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  innerColor: PropTypes.string,
};

StarPartialFilled.defaultProps = {
  width: 18,
  height: 20,
  color: colors.primary,
  innerColor: colors.babyBlue,
};

export default memo(StarPartialFilled);
