import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CristalIcon from '../../../../../../../icons/Cristal';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import SectionTitle from '../../../../../common/SectionTitle';

import styles from './MethamphetamineDisclosureMO.module.scss';

function MethamphetamineDisclosureMO({ fieldRequired }) {
  return (
    <section className={styles.container}>
      <SectionTitle title="Methamphetamines Disclosure" icon={CristalIcon} />
      <Field
        component={RadioGroup}
        id="custom_state_data.methamphetamine_manufactured"
        name="custom_state_data.methamphetamine_manufactured"
        radiosContainerClassName={styles.radioButtonsContainer}
        label=" Do you know the premises were ever used as a site for Methamphetamine production?"
        labelProps={{
          className: styles.label,
        }}
        options={[
          {
            'id': 'methamphetamine_manufactured_yes',
            'value': true,
            'label': 'Yes',
            'data-qa':
              'lease-agreements-methamphetamine-manufactured-radio-yes',
          },
          {
            id: 'methamphetamine_manufactured_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition
        when="custom_state_data.methamphetamine_manufactured"
        is={true}
      >
        <HelpfulInfoCard
          noTitle
          Icon={TurboTenantSmallLogo}
          className={styles.helpCard}
          iconProps={{ className: styles.cardLogo }}
        >
          We will provide your tenant with the proper notice in the lease
          agreement
        </HelpfulInfoCard>
      </Condition>
    </section>
  );
}

MethamphetamineDisclosureMO.propTypes = {
  fieldRequired: PropTypes.func,
};

export default MethamphetamineDisclosureMO;
