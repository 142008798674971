import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../../../../../components/Avatar';
import { H2, H3, Paragraph } from '../../../../../../../components/Typography';
import DollarIcon from '../../../../../../../icons/DollarCircled';
import RecurringIcon from '../../../../../../../icons/Recurring';
import MonthlyChargesListForm from '../../../forms/MonthlyChargesListForm';
import OneTimeChargesEditList from '../../../forms/OneTimeChargesListForm';

import styles from './ChargesForm.module.scss';

const ChargesForm = ({
  monthlyCharges,
  oneTimeCharges,
  onAddMonthlyCharge,
  onEditMonthlyCharge,
  onRemoveMonthlyCharge,
  onAddOneTimeCharge,
  onRemoveOneTimeCharge,
  onEditOneTimeCharge,
  bankAccountOptions,
  isPremiumUser,
}) => {
  return (
    <div className={styles.container}>
      <H2 className="mb-0">Add your charges</H2>

      <div className={styles.header}>
        <Avatar size="small" icon={<RecurringIcon />} />
        <H3>Monthly charges</H3>
      </div>
      <div className="mb-16">
        <MonthlyChargesListForm
          charges={monthlyCharges}
          onAdd={onAddMonthlyCharge}
          onEdit={onEditMonthlyCharge}
          onDelete={onRemoveMonthlyCharge}
          hideDescription
          bankAccountOptions={bankAccountOptions}
          isPremiumUser={isPremiumUser}
        />
      </div>

      <div className={styles.header}>
        <Avatar size="small" icon={<DollarIcon />} />
        <H3>One-Time charges</H3>
      </div>
      <Paragraph className="mb-16">
        Good for deposits, pro-rated rent, move-in fees, etc.
      </Paragraph>
      <div className="mb-0">
        <OneTimeChargesEditList
          charges={oneTimeCharges}
          onAdd={onAddOneTimeCharge}
          onEdit={onEditOneTimeCharge}
          onDelete={onRemoveOneTimeCharge}
          hideDescription
          bankAccountOptions={bankAccountOptions}
          isPremiumUser={isPremiumUser}
        />
      </div>
    </div>
  );
};

ChargesForm.propTypes = {
  onAddMonthlyCharge: PropTypes.func.isRequired,
  onRemoveMonthlyCharge: PropTypes.func.isRequired,
  onEditMonthlyCharge: PropTypes.func.isRequired,
  onAddOneTimeCharge: PropTypes.func.isRequired,
  onRemoveOneTimeCharge: PropTypes.func.isRequired,
  onEditOneTimeCharge: PropTypes.func.isRequired,
  monthlyCharges: PropTypes.array,
  oneTimeCharges: PropTypes.array,
  bankAccountOptions: PropTypes.array,
  isPremiumUser: PropTypes.bool,
};

export default ChargesForm;
