import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextUnderline = ({ color, ...props }) => (
  <svg viewBox="0 0 21 20" {...props} fill={color}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.788 18.064c.338 0 .612.344.612.768 0 .424-.274.768-.612.768H1.012c-.338 0-.612-.344-.612-.768 0-.424.274-.768.612-.768h18.776ZM16.582.4c.341 0 .618.264.618.59v9.047c0 3.585-3.045 6.491-6.8 6.491-3.756 0-6.8-2.906-6.8-6.49V.99c0-.326.277-.59.618-.59.342 0 .618.264.618.59v9.047c0 2.934 2.49 5.31 5.564 5.31 3.073 0 5.564-2.377 5.564-5.31V.99c0-.326.276-.59.618-.59Z"
    />
  </svg>
);

TextUnderline.defaultProps = {
  width: 21,
  height: 20,
  color: 'currentColor',
};

TextUnderline.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextUnderline);
