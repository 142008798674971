import React from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MarketingImg from '../../../assets/marketing/product-overview.png';
import SidebarMarketing from '../../../components/SidebarMarketing';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import PersonalizeForm from '../../onboard/steps/Personalize/PersonalizeForm/PersonalizeForm';

import styles from './PersonalizeExperiencePage.module.scss';

const PersonalizeExperiencePage = ({ plan }) => {
  const history = useHistory();
  const handleSubmit = (data) => {
    const validPlans = ['pro', 'premium'];
    const targetPlan = validPlans.includes(plan) ? plan : 'pro';

    history.push({
      pathname: `/auth/signup/final/${targetPlan}`,
      state: { personalizeData: data },
    });
  };

  return (
    <MarketingLayout
      action={<div className={styles.action} />}
      side={
        <SidebarMarketing
          title="Advertising, Screening, And More"
          subtitle="As an independent landlord, manage your rental yourself."
          graphic={MarketingImg}
        />
      }
    >
      <div className={cx(styles.container)}>
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <PersonalizeForm onSubmit={handleSubmit} />
            </form>
          )}
        </Form>
      </div>
    </MarketingLayout>
  );
};

PersonalizeExperiencePage.propTypes = {
  plan: PropTypes.string.isRequired,
};

export default authPage(PersonalizeExperiencePage);
