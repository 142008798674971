import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import CloseIcon from '../../icons/streamline/Close';
import fieldRequired from '../../validators/fieldRequired';
import Button from '../Button';
import Input from '../Input';
import Modal from '../Modal';
import RadioGroup from '../RadioGroup';
import SelectField from '../SelectField';

import styles from './SaveFormDocumentModal.module.scss';

const SaveFormDocumentModal = ({
  open,
  onClose,
  onSave,
  leases = [],
  documentName,
  className,
}) => {
  const defaultTitle = `${documentName} - ${moment().format('MMMM YYYY')}`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={cx(styles.modal, className)}
      showCloseIcon
      closeIcon={CloseIcon}
      closeIconClassName={styles.closeIcon}
    >
      <h2 className={styles.title}>Save a copy of your document</h2>
      <p className={styles.description}>
        Once saved, this version cannot be edited. Access the original from the
        Forms pack. Your document is private unless shared.
      </p>

      <Form
        onSubmit={onSave}
        initialValues={{
          title: defaultTitle,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Field
              className={styles.leaseSelect}
              component={SelectField}
              id="leaseId"
              name="leaseId"
              label="Lease"
              options={leases.map((lease) => ({
                value: lease.id,
                label: lease.title,
              }))}
              empty={true}
              validate={fieldRequired}
            />

            <Field
              className={styles.titleInput}
              component={Input}
              id="title"
              name="title"
              label="Title"
              validate={fieldRequired}
            />

            <Field
              className={styles.shareDocument}
              component={RadioGroup}
              id="shareDocument"
              name="shareDocument"
              label="Do you want to share your document with the tenants on this lease?"
              options={[
                { value: true, label: 'Yes, share it' },
                { value: false, label: 'No, keep it private' },
              ]}
              validate={fieldRequired}
            />

            <Button
              type="submit"
              loading={submitting}
              className={styles.saveButton}
            >
              Save Document
            </Button>
          </form>
        )}
      </Form>
    </Modal>
  );
};

SaveFormDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  leases: PropTypes.array,
  documentName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SaveFormDocumentModal;
