import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import { Eyebrow, H2, Paragraph } from '../../../../../components/Typography';

import ChargesForm from './forms/ChargesForm';
import ListingForm from './forms/ListingForm';

import styles from './ChargesFirstForm.module.scss';

const ChargesFirstForm = ({
  state,
  onSave,
  onUpdateListing,
  onAddMonthlyCharge,
  onEditMonthlyCharge,
  onRemoveMonthlyCharge,
  onAddOneTimeCharge,
  onRemoveOneTimeCharge,
  onEditOneTimeCharge,
  bankAccountOptions,
  isPremiumUser,
  isEdit,
}) => {
  return (
    <Card padded className={styles.container}>
      <Eyebrow>add your first property</Eyebrow>
      <H2 className="mb-4">What property are you collecting rent for?</H2>
      <Paragraph className="mb-24">
        Collecting rent for multiple properties? You can add more later!
      </Paragraph>

      <ListingForm listing={state?.listing} onSubmit={onUpdateListing} />

      <ChargesForm
        monthlyCharges={state?.monthlyCharges}
        onEditMonthlyCharge={onEditMonthlyCharge}
        onRemoveMonthlyCharge={onRemoveMonthlyCharge}
        oneTimeCharges={state?.oneTimeCharges}
        onAddMonthlyCharge={onAddMonthlyCharge}
        onAddOneTimeCharge={onAddOneTimeCharge}
        onRemoveOneTimeCharge={onRemoveOneTimeCharge}
        onEditOneTimeCharge={onEditOneTimeCharge}
        bankAccountOptions={bankAccountOptions}
        isPremiumUser={isPremiumUser}
      />

      <div className={styles.formActions}>
        <Button
          variant="primary"
          onClick={onSave}
          form="listing_form"
          type="submit"
        >
          {isEdit ? 'Save' : 'Next'}
        </Button>
      </div>
    </Card>
  );
};

ChargesFirstForm.propTypes = {
  state: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdateListing: PropTypes.func.isRequired,
  onAddMonthlyCharge: PropTypes.func.isRequired,
  onAddOneTimeCharge: PropTypes.func.isRequired,
  onEditMonthlyCharge: PropTypes.func.isRequired,
  onRemoveMonthlyCharge: PropTypes.func.isRequired,
  onRemoveOneTimeCharge: PropTypes.func.isRequired,
  onEditOneTimeCharge: PropTypes.func.isRequired,
  bankAccountOptions: PropTypes.array.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default memo(ChargesFirstForm);
