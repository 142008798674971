import React, { useEffect } from 'react';
import seon from '@seontechnologies/seon-javascript-sdk';

const Seon = () => {
  useEffect(() => {
    seon.init();
  }, []);

  return <div />;
};

export default Seon;
