const MAINTENANCE_CATEGORIES = [
  { label: 'Appliances', value: 'APPLIANCES' },
  { label: 'Cabinets/Countertops', value: 'CABINETS_COUNTERTOPS' },
  { label: 'Doors/Windows', value: 'DOORS_WINDOWS' },
  { label: 'Electrical', value: 'ELECTRICAL' },
  { label: 'Fencing', value: 'FENCING' },
  { label: 'Fireplace', value: 'FIREPLACE' },
  { label: 'Flooring', value: 'FLOORING' },
  { label: 'Garage', value: 'GARAGE' },
  { label: 'Heating and Cooling', value: 'HEATING_AND_COOLING' },
  { label: 'Inspections', value: 'INSPECTIONS' },
  { label: 'Keys/Locks', value: 'KEYS_LOCKS' },
  { label: 'Landscaping', value: 'LANDSCAPING' },
  { label: 'Pest Control', value: 'PEST_CONTROL' },
  { label: 'Plumbing', value: 'PLUMBING' },
  { label: 'Pool', value: 'POOL' },
  { label: 'Roof', value: 'ROOF' },
  { label: 'General', value: 'GENERAL' },
  { label: 'Other', value: 'OTHER' },
];

export const maintenanceCategoriesObject = MAINTENANCE_CATEGORIES.reduce(
  (acc, { label, value }) => {
    acc[value] = label;
    return acc;
  },
  {},
);

export default MAINTENANCE_CATEGORIES;
