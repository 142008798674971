import React from 'react';
import PropTypes from 'prop-types';

import { warningToastOrange } from '../../constants/colors';
import BusinessBankColorIcon from '../../icons/BusinessBankColor';
import PaymentsIcon from '../../icons/Payments';
import PremiumBadge from '../../icons/PremiumBadge';
import ReceiveMoney from '../../icons/ReceiveMoney';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Card from '../Card';
import FlatButton from '../FlatButton/FlatButton';
import { H2, H5, Link, Paragraph } from '../Typography/Typography';

import styles from './PaymentsPremiumPromotion.module.scss';
const items = [
  {
    title: 'Expedited rent payouts',
    description: 'Receive deposits in just 2-4 business days',
    icon: ReceiveMoney,
  },
  {
    title: 'Free ACH payments',
    description: 'Normally $2 fee for renters',
    icon: PaymentsIcon,
  },
  {
    title: 'Unlimited bank accounts',
    description: `Deposits into unlimited number of bank accounts`,
    icon: BusinessBankColorIcon,
  },
];

const PaymentsPremiumPromotion = ({
  onPremium,
  onUpgradeToPremium,
  onCancel,
}) => {
  return (
    <Card className={styles.container} padded>
      <PremiumBadge className={styles.badge} />
      <H2>Unlock the full power of Rent Payments with Premium</H2>
      <Paragraph>
        55% of Rent Payment users have <Link onClick={onPremium}>Premium</Link>.
      </Paragraph>
      <div className={styles.items}>
        {items.map(({ title, description, icon: Icon }) => (
          <div key={title} className={styles.item}>
            <Avatar
              icon={<Icon color={warningToastOrange} width={40} height={40} />}
            />
            <div>
              <H5>{title}</H5>
              <Paragraph>{description}</Paragraph>
            </div>
          </div>
        ))}
      </div>
      <Card.Footer>
        <Button variant="primary" onClick={onUpgradeToPremium}>
          Upgrade to premium
        </Button>
        <FlatButton onClick={onCancel}>maybe later</FlatButton>
      </Card.Footer>
    </Card>
  );
};
PaymentsPremiumPromotion.propTypes = {
  onPremium: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpgradeToPremium: PropTypes.func.isRequired,
};
export default PaymentsPremiumPromotion;
