import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';
import { useParams, useHistory } from 'react-router-dom';

import { CometChatNavBar } from './CometChatNavBar';
import { CometChatMessages } from '../Messages';
import { CometChatIncomingCall, CometChatIncomingDirectCall } from '../Calls';

import { CometChatContextProvider } from '../../util/CometChatContext';
import * as enums from '../../util/enums.js';
import { theme } from '../../resources/theme';
import Translator from '../../resources/localization/translator';
import toGlobalId from '../../../../../helpers/to-global-id';
import fromGlobalId from '../../../../../helpers/from-global-id';
import { getConfig } from '../../../../../services/configService';

import { unifiedStyle, unifiedSidebarStyle, unifiedMainStyle } from './style';

class CometChatUI extends React.Component {
  loggedInUser = null;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      sidebarview: false,
      reloadKey: 0,
      newMessagesUniversalMessaging: false,
    };

    this.navBarRef = React.createRef();
    this.contextProviderRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.chatWithUser.length === 0 &&
      this.props.chatWithGroup.length === 0
    ) {
      this.toggleSideBar();
    }
    if (this.props.receiverUID) this.openInitialConversation();
  }

  openInitialConversation = async () => {
    const receiverUID = fromGlobalId(this.props.receiverUID);
    if (receiverUID) {
      // After we add groups, this "user" type needs to be dynamic
      const type = 'user';
      const conversation = await CometChat.getConversation(receiverUID, type);
      const conversationItem = conversation.conversationWith;
      this?.contextProviderRef?.setTypeAndItem(type, conversationItem);
      this?.contextProviderRef?.setTagsAndConversationId(
        conversation.tags,
        conversation.conversationId,
      );
    }
  };

  navBarAction = (action, type, item, tags = [], conversationId = '') => {
    switch (action) {
      case enums.ACTIONS['ITEM_CLICKED']:
        this.itemClicked(item, type, tags, conversationId);
        break;
      case enums.ACTIONS['TOGGLE_SIDEBAR']:
        this.toggleSideBar();
        break;
      default:
        break;
    }
  };

  refreshComponent = () => {
    this.setState({ reloadKey: this.state.reloadKey + 1 });
  };

  itemClicked = (item, type, tags = [], conversationId = '') => {
    const receiverGlobalId = toGlobalId(item.uid, type);
    const { IS_OWNER } = getConfig();
    window.history.replaceState(
      '',
      '',
      `/${
        IS_OWNER ? 'owners' : 'renters'
      }/messages/${receiverGlobalId}?tab=individual`,
    );
    this.contextProviderRef.setTypeAndItem(type, item);
    this.contextProviderRef.setTagsAndConversationId(tags, conversationId);
    this.toggleSideBar();
  };

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS['TOGGLE_SIDEBAR']:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };

  toggleSideBar = () => {
    const sidebarview = this.state.sidebarview;
    this.setState({ sidebarview: !sidebarview });
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  /**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
  groupUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (
          this.contextProviderRef.state.type ===
            CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          this.contextProviderRef.setItem({});
          this.contextProviderRef.setType('');
        }
        break;
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (
          this.contextProviderRef.state.type ===
            CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.state.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          const newObject = Object.assign(
            {},
            this.contextProviderRef.state.item,
            { scope: options['scope'] },
          );
          this.contextProviderRef.setItem(newObject);
          this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    const messageScreen = (
      <CometChatMessages
        key={`${this.state.reloadKey}_${this?.contextProviderRef?.state?.item?.uid}`}
        theme={this.props.theme}
        lang={this.props.lang}
        _parent="unified"
        actionGenerated={this.actionHandler}
        activeTab={this.state.activeTab}
        tags={this.contextProviderRef?.state?.tags}
        onArchiveSuccess={this.refreshComponent}
        isFromUniversalMessaging={this.props.isFromUniversalMessaging}
      />
    );

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={this.props.lang}
      >
        <div
          key={this.state.reloadKey}
          css={unifiedStyle({
            ...this.props,
            isFromUniversalMessaging: this.props.isFromUniversalMessaging,
          })}
          className="cometchat cometchat--unified"
          style={{
            height: this.props.isFromUniversalMessaging ? '100%' : undefined,
          }}
          dir={Translator.getDirection(this.props.lang)}
        >
          <div
            css={unifiedSidebarStyle(this.state, {
              ...this.props,
              isFromUniversalMessaging: this.props.isFromUniversalMessaging,
            })}
            className="unified__sidebar"
          >
            <CometChatNavBar
              ref={(el) => (this.navBarRef = el)}
              theme={this.props.theme}
              actionGenerated={this.navBarAction}
              onTabChange={this.handleTabChange}
              isFromUniversalMessaging={this.props.isFromUniversalMessaging}
              initialChatId={this.props.initialChatId}
            />
          </div>
          <div
            css={unifiedMainStyle(this.state, {
              ...this.props,
              isFromUniversalMessaging: this.props.isFromUniversalMessaging,
            })}
            className="unified__main"
          >
            {messageScreen}
          </div>
          <CometChatIncomingCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
          <CometChatIncomingDirectCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
        </div>
      </CometChatContextProvider>
    );
  }
}

// Specifies the default values for props:
CometChatUI.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  chatWithUser: '',
  chatWithGroup: '',
};

CometChatUI.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

const CometchatUIWrapper = (props) => {
  const { receiverUID } = useParams();
  const history = useHistory();
  return <CometChatUI {...props} history={history} receiverUID={receiverUID} />;
};

export { CometchatUIWrapper as CometChatUI };
