import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Fireworks from '../../../../components/Animations/FireworksAnimation';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { H2, Paragraph } from '../../../../components/Typography';
import { linkTo, routes } from '../../../../helpers/routes';
import { useRentersProfile } from '../../../../providers/RentersProfileProvider/context';
import { segmentTracking } from '../../../../services/utilities';

import styles from './Success.module.scss';

const Success = () => {
  const history = useHistory();
  const { currentLease } = useRentersProfile();

  const onComplete = useCallback(
    async (navigateTo) => {
      history.push(navigateTo);
    },
    [currentLease.id],
  );

  const goToMessages = useCallback(async () => {
    segmentTracking('onboarding_complete submit', {
      location: 'Tenant Onboarding All Set Page - Message LL',
    });
    await onComplete(linkTo(routes.tenantMessages));
  }, [onComplete]);

  const goToDashboard = useCallback(async () => {
    segmentTracking('onboarding_complete submit', {
      location: 'Tenant Onboarding All Set Page - Dashboard',
    });
    await onComplete(linkTo(routes.tenantDashboard));
  }, [onComplete]);

  return (
    <Card padded className={styles.container}>
      <Card.Content className={styles.content}>
        <div className={styles.animationWrapper}>
          <Fireworks loop />
        </div>
        <H2 align="center" className="mb-4">
          You’re all set!
        </H2>
        <Paragraph align="center" size="large" className="mb-24">
          You are now ready to use your tenant portal for rent payments,
          maintenance, messages, and more!
        </Paragraph>
      </Card.Content>
      <Card.Footer>
        <>
          <Button
            variant="primary"
            className={styles.button}
            onClick={goToMessages}
          >
            let your landlord know
          </Button>
          <Button
            variant="secondary"
            className={styles.button}
            onClick={goToDashboard}
          >
            go to dashboard
          </Button>
        </>
      </Card.Footer>
    </Card>
  );
};

export default Success;
