import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import CheckMarkFilledIcon from '../../../icons/CheckMarkFilled';
import CloseIcon from '../../../icons/Close';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import FlatButton from '../../FlatButton';
import IconButton from '../../IconButton';
import Modal from '../../Modal';

import styles from './LulaOnboardingSuccessModal.module.scss';

const LulaOnboardingSuccessModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onSoundGoodClick,
  nteAmount,
  cardLastFour,
}) => {
  useEffect(() => {
    if (open) {
      segmentTracking('lula_success_next_steps_modal loaded', {
        location: 'Lula Onboarding - Summary',
      });
    }
  }, []);

  const list = [
    {
      title: (
        <>
          <b>You will decide which maintenance requests are sent to Lula.</b>{' '}
          You can choose to automatically send requests to Lula in Settings.
        </>
      ),
    },
    {
      title:
        'Lula will reach out to your tenant via text message to troubleshoot the issue. If Lula is unable to resolve the request remotely, a Pro is dispatched. We recommend notifying your tenants promptly to expect texts from Lula when a maintenance request is sent.',
      prefix: 'When you send a maintenance request to Lula:',
    },
    {
      title: `For work orders exceeding $${nteAmount}, your contacts will be called and emailed for approval.`,
    },
    {
      title:
        'You’ll receive real-time updates in TurboTenant throughout the process.',
    },
    {
      title: `If a Pro has completed work, you'll receive an invoice and your card on file ending in ${cardLastFour} will be charged from TurboTenant.`,
    },
  ];

  const { PRIVATE_BASE_PATH } = useConfig();

  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={() => {
        segmentTracking('close clicked', {
          location: 'Lula Success Next Steps Modal',
        });
        onClose();
      }}
    >
      <div className={styles.header}>
        <IconButton
          icon={CloseIcon}
          onClick={() => {
            segmentTracking('close clicked', {
              location: 'Lula Success Next Steps Modal',
            });
            onClose();
          }}
          className={styles.close}
          iconProps={{ width: 18, height: 18, color: colors.white }}
        />
        <CheckMarkFilledIcon
          className={styles.checkMark}
          width={80}
          height={80}
          color={colors.lightGreenAlt}
        />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>
          Wahoo! Life's about to get a whole lot easier. Here's what's next:
        </h2>

        <ol className={cx(styles.numberedList)}>
          {list.map((item, index) => {
            return (
              item && (
                <>
                  {item.prefix && (
                    <span className={styles.prefix}>{item.prefix}</span>
                  )}
                  <li key={index} className={styles.listItem}>
                    <div>
                      <span>{item.title}</span>
                    </div>
                  </li>
                </>
              )
            );
          })}
        </ol>
        <div className={styles.btnWrapper}>
          <Button
            className={styles.btn}
            onClick={() => {
              segmentTracking('sounds_good clicked', {
                location: 'Lula Success Next Steps Modal',
              });
              onSoundGoodClick();
            }}
          >
            Sounds good
          </Button>
          <FlatButton
            className={styles.link}
            href={`${PRIVATE_BASE_PATH}maintenance/lula`}
            target="_blank"
            onClick={() => {
              segmentTracking('learn_more clicked', {
                location: 'Lula Success Next Steps Modal',
              });
            }}
          >
            learn more about the lula process
          </FlatButton>
        </div>
      </div>
    </Modal>
  );
};

LulaOnboardingSuccessModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onSoundGoodClick: PropTypes.func,
  onClose: PropTypes.func,
  nteAmount: PropTypes.number.isRequired,
  cardLastFour: PropTypes.string.isRequired,
};

export default LulaOnboardingSuccessModal;
