import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import Card from '../../../../components/Card';
import SecurityLockColoredIcon from '../../../../icons/SecurityLockColored';

import styles from './FormsEditPageLocked.module.scss';

const FormsEditPageLocked = ({ onDownloadOriginal, documentName }) => {
  return (
    <div className={styles.container}>
      <BackButton className={styles.backButton} />
      <div className={styles.lockedContainer}>
        <h2 className={styles.title}>Copy of {documentName}</h2>
        <p className={styles.text}>
          You can now make edits to this form before saving directly to your
          account! Or,{' '}
          <button className={styles.downloadLink} onClick={onDownloadOriginal}>
            download an original copy
          </button>
          .
        </p>
        <Card className={styles.card}>
          <div className={styles.iconWrapper}>
            <SecurityLockColoredIcon height={53} width={39} />
          </div>
          <h3 className={styles.cardTitle}>Editor locked</h3>
          <p className={styles.cardText}>
            Switch to desktop to edit this document.
          </p>
        </Card>
      </div>
    </div>
  );
};

FormsEditPageLocked.propTypes = {
  onDownloadOriginal: PropTypes.func.isRequired,
  documentName: PropTypes.string.isRequired,
};

export default FormsEditPageLocked;
