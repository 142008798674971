import React from 'react';
import PropTypes from 'prop-types';

import FlatButton from '../../../components/FlatButton';
import { Paragraph } from '../../../components/Typography';
import StarPartialFilled from '../../../icons/StarPartialFilled';

import styles from './ProcessForm.module.scss';

const OnboardingExperimentButton = ({ onClick, loading, subtitle }) => {
  return (
    <div className={styles.buttonContainer}>
      <FlatButton
        className={styles.experimentButton}
        onClick={onClick}
        loading={loading}
        data-qa="onboarding-choice-all"
      >
        <div className={styles.iconButtonContainer}>
          <StarPartialFilled width={56} height={56} />
          <div>
            <Paragraph className={styles.titleText}>All of the above</Paragraph>
            <Paragraph className={styles.subText}>
              {subtitle || 'Recommended for new landlords!'}
            </Paragraph>
          </div>
        </div>
      </FlatButton>
    </div>
  );
};

OnboardingExperimentButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default OnboardingExperimentButton;
