import { useCallback, useRef } from 'react';

import { useActiveExperiments } from '../../providers/ActiveExperimentsProvider/context';

import tryAssignActiveCookieExperiment from './tryAssignActiveCookieExperiment';

/**
 * Hook that provides a simplified interface for assigning cookie-based experiments
 * @returns {Function} assignExperiment - Function to assign an experiment variant
 * @example
 * const assignExperiment = useCookieExperimentAssignment();
 * const variant = assignExperiment('my-experiment', { expirationDays: 30 });
 */
export const useCookieExperimentAssignment = () => {
  const { cookieExperiments } = useActiveExperiments();
  const cookieExperimentsRef = useRef(cookieExperiments);

  cookieExperimentsRef.current = cookieExperiments;

  return useCallback(async (experimentName, options = {}, callback = null) => {
    if (!cookieExperimentsRef.current) {
      await new Promise((resolve) => {
        const checkExperiments = () => {
          if (cookieExperimentsRef.current) {
            resolve();
          } else {
            setTimeout(checkExperiments, 50);
          }
        };
        checkExperiments();
      });
    }

    const variant = tryAssignActiveCookieExperiment(
      experimentName,
      cookieExperimentsRef.current,
      options,
    );

    if (typeof callback === 'function') {
      callback(variant);
    }

    return variant;
  }, []);
};
