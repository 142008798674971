import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../../../components/RadioGroup';

import styles from './DenverTenantRightsCO.module.scss';

function DenverTenantRightsCO({ fieldRequired }) {
  return (
    <section className={styles.container}>
      <div>
        <Field
          component={RadioGroup}
          id="custom_state_data.rental_located_denver"
          name="custom_state_data.rental_located_denver"
          className={styles.radioWrapper}
          radiosContainerClassName={styles.radioButtonsContainer}
          label="Is the rental located within the City and County of Denver?"
          labelProps={{
            className: styles.headerLabel,
          }}
          options={[
            {
              'id': 'rental_located_denver_choice_yes',
              'value': true,
              'label': 'Yes',
              'data-qa': 'lease-agreements-rental-located-denver-radio-yes',
            },
            {
              id: 'rental_located_denver_choice_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <HelpfulInfoCard
        noIcon
        title="Denver Tenant Rights and Resources Handbook"
        className={styles.helpfulInfoCard}
      >
        <p className={styles.description}>
          Denver law requires that the{' '}
          <a
            className={styles.link}
            href="https://www.denvergov.org/Government/Agencies-Departments-Offices/Agencies-Departments-Offices-Directory/Department-of-Housing-Stability/Resident-Resources/Eviction-Help/Tenant-Rights-and-Resources"
            target="_blank"
            rel="noopener noreferrer"
          >
            Denver Tenant Rights and Resources Handbook
          </a>{' '}
          be attached to the lease upon signing and when any demand for rent is
          made. If it is located in Denver, we will attach it for you.
        </p>
      </HelpfulInfoCard>
    </section>
  );
}

DenverTenantRightsCO.propTypes = {
  fieldRequired: PropTypes.func,
};

export default DenverTenantRightsCO;
