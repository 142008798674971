export const calculateTotalCost = (numberOfUnits) => {
  if (numberOfUnits <= 0) return 0;

  const basePrices = [
    180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020,
  ];

  if (numberOfUnits <= basePrices.length) {
    return basePrices[numberOfUnits - 1];
  }

  return 1020;
};
