import { useEffect, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';

import eventEmitter from '../../eventEmitter';

const useMessagingNotifications = (ownerId) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const handleClearUnreadCountConversation = (clearCount) => {
    setUnreadMessagesCount((prev) => {
      const updatedCount = prev - clearCount;
      return updatedCount < 0 ? 0 : updatedCount;
    });
  };

  const onTextMessageReceived = (textMessage) => {
    const ownerIdDecoded = atob(ownerId).split(':').pop();
    const receiverId = textMessage?.receiverId;
    const formatedOwnerId = `owner_${ownerIdDecoded}`;

    // If the message is not for the logged in user, return
    if (receiverId !== formatedOwnerId) return;

    if (window.location.pathname !== '/owners/messages') {
      setUnreadMessagesCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const listenerId = `message-listener-${new Date().getTime()}`;

    if (ownerId) {
      const setUnreadMessagesCountOnLoad = async () => {
        const loggedInUser = await CometChat.getLoggedinUser();
        if (loggedInUser) {
          // TODO: Remove this timeout once the issue is fixed in CometChat
          setTimeout(async () => {
            const unreadData = await CometChat.getUnreadMessageCount();
            const totalUnreadSum = Object.values(unreadData.users).reduce(
              (acc, curr) => acc + curr,
              0,
            );
            setUnreadMessagesCount(totalUnreadSum);
          }, 1000);
        }
        return 0;
      };

      setUnreadMessagesCountOnLoad();

      CometChat.addMessageListener(
        listenerId,
        new CometChat.MessageListener({
          onTextMessageReceived: (textMessage) =>
            onTextMessageReceived(textMessage),
        }),
      );

      eventEmitter.on('increment_messages_count', () => {
        setUnreadMessagesCount((prev) => prev + 1);
      });

      eventEmitter.on(
        'clear_unread_count_conversation',
        handleClearUnreadCountConversation,
      );
    }
    return () => {
      CometChat.removeMessageListener(listenerId);
    };
  }, [ownerId]);

  return { unreadMessagesCount };
};

export default useMessagingNotifications;
