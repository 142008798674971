import React, { useEffect, useRef } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';

import FlatButton from '../../../components/FlatButton';
import IncomeInsightsCardContainer from '../../../components/IncomeInsightsCard/IncomeInsightsCardContainer';
import SectionTitleWithIcon from '../../../components/SectionTitleWithIcon';
import SectionTitleWithIconAndAction from '../../../components/SectionTitleWithIconAndAction';
import { H2, H4, Paragraph } from '../../../components/Typography';
import getFormattedDateLocalTZ from '../../../helpers/format-date-local';
import formatPhone from '../../../helpers/format-phone';
import getAppliedTo from '../../../helpers/getAppliedTo';
import AccountFind from '../../../icons/AccountFind';
import Print from '../../../icons/Print';
import ProfileThickIcon from '../../../icons/ProfileThick';
import QuestionMarkCircle from '../../../icons/QuestionMarkCircle';
import TransUnionLogo from '../../../icons/TransUnionLogo';
import { segmentTracking } from '../../../services/utilities';

import AddressSection from './sections/AddressSection/AddressSection';
import CollectionSection from './sections/CollectionSection/CollectionSection';
import CriminalSection from './sections/CriminalSection';
import EvictionRecordsSection from './sections/EvictionRecordsSection';
import InquiriesSection from './sections/InquiriesSection';
import MainSection from './sections/MainSection';
import ProfileSummarySection from './sections/ProfileSummarySection/ProfileSummarySection';
import PublicRecordsSection from './sections/PublicRecordsSection';
import TradelinesSection from './sections/TradelinesSection';

import styles from './ReportResult.module.scss';

const ReportResult = ({
  application,
  screening_report_type,
  shortVersion,
  showPrintButton = true,
  showHelpButton = true,
  showIncomeInsightPromo = true,
  isForPartner = false,
}) => {
  const componentRef = useRef(null);

  useEffect(() => {
    if (!isForPartner) {
      segmentTracking('screening_report loaded', {
        location: 'Applicant Screening Report Tab',
      });
    } else {
      segmentTracking('screening_report loaded', {
        location: 'Redfin Applicant Screening Report Tab',
      });
    }
  }, []);

  const renderShortVersion = () => {
    const report = JSON.parse(application.credit_check.report);
    return (
      <div className={styles.column}>
        <SectionTitleWithIconAndAction
          className={styles.headerTitle}
          title="Screening Report"
          icon={AccountFind}
          text={`RECEIVED ON ${moment(
            application.credit_check.report_received,
          ).format('MM/DD/YYYY')}`}
        />
        <MainSection
          report={report}
          applicantId={get(application, 'renter.id')}
          firstName={application.first_name}
          shortVersion
          screening_report_type={screening_report_type}
        />
      </div>
    );
  };

  if (!application.credit_check.report) {
    return <div />;
  }

  const report = JSON.parse(application.credit_check.report);
  const isRefer =
    get(report, 'recommendation.title', '').toLowerCase() === 'refer';
  const renter = get(application, 'renter', {}) || {};

  return (
    <div className={styles.container} ref={componentRef}>
      {shortVersion ? (
        renderShortVersion()
      ) : (
        <>
          <div className={styles.header}>
            <H2 className={cx(styles.title, 'mb-0')}>Screening Report</H2>
            <div className={cx(styles.hideSmall, styles.headerActions)}>
              {showPrintButton && (
                <ReactToPrint content={() => componentRef.current}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FlatButton
                        icon={Print}
                        onClick={handlePrint}
                        className={styles.hideOnPrint}
                      >
                        PRINT
                      </FlatButton>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              )}
              {showHelpButton && (
                <FlatButton
                  onClick={() =>
                    window.open(
                      'https://www.turbotenant.com/blog/how-to-read-a-tenant-screening-report/',
                      'blank',
                    )
                  }
                  className={cx(styles.getHelpReading, styles.hideOnPrint)}
                  icon={QuestionMarkCircle}
                >
                  GET HELP READING A REPORT
                </FlatButton>
              )}
            </div>
            <TransUnionLogo className={styles.transUnionLogo} />
          </div>
          <Paragraph className={styles.label} size="small" weight="bold">
            RECEIVED ON{' '}
            {moment(application.credit_check.report_received).format(
              'MM/DD/YYYY',
            )}
          </Paragraph>
          <div className={styles.summaryContainer}>
            <SectionTitleWithIcon icon={ProfileThickIcon} title="Summary" />
            <H4 className="mb-16" weight="semi">
              {renter.first_name} {renter.last_name}
            </H4>
            {application.listing && (
              <Paragraph className={styles.infoRow} size="small">
                <b>Applied to:</b> {getAppliedTo(application.listing)}
              </Paragraph>
            )}
            {application.submitted_date && (
              <Paragraph className={styles.infoRow} size="small">
                <b>Date Submitted:</b>{' '}
                {getFormattedDateLocalTZ(application.submitted_date)}
              </Paragraph>
            )}
            {renter.email && (
              <Paragraph className={styles.infoRow} size="small">
                <b>Email:</b> {renter.email}
              </Paragraph>
            )}
            {renter.telephone && (
              <Paragraph className={styles.infoRow} size="small">
                <b>Phone:</b> {formatPhone(renter.telephone)}
              </Paragraph>
            )}
          </div>
          <MainSection
            publicListing={application.listing}
            applicantId={get(application, 'renter.id')}
            report={report}
            screening_report_type={screening_report_type}
            firstName={application.first_name}
          />
          {showIncomeInsightPromo && (
            <IncomeInsightsCardContainer
              application={application}
              className={styles.incomeCard}
            />
          )}
          <hr />
          <div className={styles.sectionsContainer}>
            <AddressSection report={report} />
            <hr />
            <CriminalSection report={report} />
            <hr />
            <ProfileSummarySection report={report} />
            <hr />
            {!isRefer && <TradelinesSection report={report} />}
            {!isRefer && <hr />}
            <CollectionSection report={report} />
            <hr />
            {report.public_records && report.public_records.length > 0 && (
              <div>
                <PublicRecordsSection report={report} />
                <hr />
              </div>
            )}
            <InquiriesSection report={report} />
            <hr />
            <EvictionRecordsSection evictions={report.evictions} />
          </div>
          <TransUnionLogo className={styles.transUnionLogoMobile} />
        </>
      )}
    </div>
  );
};

ReportResult.propTypes = {
  application: PropTypes.object,
  screening_report_type: PropTypes.string,
  shortVersion: PropTypes.bool,
  showPrintButton: PropTypes.bool,
  showHelpButton: PropTypes.bool,
  showIncomeInsightPromo: PropTypes.bool,
  isForPartner: PropTypes.bool,
};

export default ReportResult;
