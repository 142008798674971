import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import GoogleIcon from './Icon';
import LoginTextIcon from './LoginTextIcon';
import SignupTextIcon from './SignupTextIcon';

import styles from './GoogleButtonNew.module.scss';

const icons = {
  signup: SignupTextIcon,
  login: LoginTextIcon,
};

const GoogleButtonNew = ({
  className,
  disabled,
  loading,
  type,
  ...otherProps
}) => {
  // eslint-disable-next-line no-useless-assignment
  const GoogleText = icons[type];

  return (
    <button
      type="button"
      className={cx(
        styles.buttonNew,
        { [styles.disabled]: disabled || loading },
        className,
      )}
      disabled={disabled || loading}
      {...otherProps}
    >
      <div className={cx(styles.icon)}>
        <GoogleIcon />
      </div>
      {loading ? <Spinner /> : <GoogleText />}
    </button>
  );
};

GoogleButtonNew.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['signup', 'login']),
};

export default GoogleButtonNew;
