import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Excel = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 37" {...props}>
    <defs />
    <defs>
      <path id="a" d="M.03.006h31.187V36.27H.03z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(9.602)">
        <mask id="b" fill="#fff">
          <use href="#a" />
        </mask>
        <path
          fill="#1B5234"
          d="M29.573 36.273H1.675A1.644 1.644 0 01.03 34.629v-7.423h31.186v7.423c0 .908-.736 1.644-1.644 1.644"
          mask="url(#b)"
        />
        <path
          fill="#1C985F"
          d="M15.806.006H1.675C.767.006.03.742.03 1.65v7.423h15.775V.006"
          mask="url(#b)"
        />
        <path
          fill="#41AF78"
          d="M15.806.006h13.767c.908 0 1.644.736 1.644 1.644v7.423H15.806V.006"
          mask="url(#b)"
        />
      </g>
      <path fill="#1B5234" d="M9.632 27.206h15.775V18.14H9.632z" />
      <path fill="#19733D" d="M25.407 27.206h15.411V18.14h-15.41z" />
      <path fill="#19733C" d="M9.632 18.14h15.775V9.072H9.632z" />
      <path fill="#1C985F" d="M25.407 18.14h15.411V9.072h-15.41z" />
      <path
        fill="#19733D"
        d="M19.353 28.527H1.605A1.605 1.605 0 010 26.922V9.339c0-.886.719-1.605 1.605-1.605h17.748c.887 0 1.606.719 1.606 1.605v17.583c0 .886-.72 1.605-1.606 1.605"
      />
      <path
        fill="#FFFFFE"
        d="M11.654 18.116l3.407 5.45h-2.267l-2.27-3.637-2.276 3.638H5.98l3.407-5.45-3.407-5.451h2.267l2.275 3.638 2.271-3.638h2.267l-3.407 5.45"
      />
    </g>
  </svg>
);

Excel.defaultProps = {
  width: 42,
  height: 37,
};

Excel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Excel);
