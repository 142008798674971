import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';
import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../graphql/queries/ownerSettingsQuery.graphql';
import Applicant from '../../icons/ApplicantThick';
import PremiumBadge from '../../icons/PremiumBadge';
import RadioCheck from '../../icons/RadioCheck';
import { useConfig } from '../../providers/ConfigProvider';
import {
  getItem,
  removeItem,
  setItem,
} from '../../services/localStorageService';
import { segmentTracking } from '../../services/utilities';
import PremiumSubscriptionModal from '../PremiumSubscription';
import getSegmentLocation from '../PremiumSubscription/get-premium-modal-segment-location';
import SectionTitleWithIcon from '../SectionTitleWithIcon';

import AreYouSureModal from './AreYouSureModal';
import PremiumRadioButtonOffer from './PremiumRadioButtonOffer';

import styles from './PremiumRadioButtonOfferWrapped.module.scss';

const ARE_YOU_SURE_KEY = 'are_you_sure_modal_pop_count';
const ARE_YOU_SURE_DATE_KEY = 'are_you_sure_modal_date_set';

const PremiumRadioButtonOfferWrapped = ({
  input,
  label,
  className,
  openLocation,
  purchase_location,
  hideLabel,
  ...props
}) => {
  const { user } = useUserProfile({}, false);

  const { DEFAULT_APPLICATION_FEE } = useConfig();
  const premiumSubscribed = user?.premium_subscription_subscribed;
  const proSubscriptionActive = user?.pro_subscription_active;

  const areYouSureModalCount = Number(getItem(ARE_YOU_SURE_KEY)) || 0;
  const areYouSureModalDate = getItem(ARE_YOU_SURE_DATE_KEY) || null;

  const [showAreYouSure, setShowAreYouSure] = useState();
  const [showPremium, setShowPremium] = useState(false);

  const applicationFee =
    get(user, 'application_fee', '') || DEFAULT_APPLICATION_FEE;
  const appFeePriceExpPremiumPrice = get(
    user,
    'app_fee_price_exp_premium_price',
  );

  useEffect(() => {
    if (areYouSureModalDate && Date.now() > areYouSureModalDate) {
      setItem(ARE_YOU_SURE_KEY, 0);
      setShowAreYouSure(true);
      removeItem(ARE_YOU_SURE_DATE_KEY);
    }

    if (showAreYouSure === undefined && user.id) {
      setShowAreYouSure(areYouSureModalCount < 3);
    }
  }, [user?.id]);

  useEffect(() => {
    if (premiumSubscribed) {
      input.onChange('premium');
    }
  }, [premiumSubscribed]);

  if (proSubscriptionActive) return null;

  return (
    <div className={className}>
      {!hideLabel && (
        <SectionTitleWithIcon
          centered={false}
          title={label}
          icon={Applicant}
          iconClassNames={styles.icon}
        />
      )}

      {premiumSubscribed ? (
        <div className={styles.hasPremium}>
          <PremiumBadge className={styles.premiumBadge} />
          <div className={styles.iconHolder}>
            <RadioCheck className={styles.radioCheck} />
          </div>
          <div className={styles.descData}>
            <span className={styles.offerButtonTitle}>PREMIUM UNLOCKED</span>
            <span className={styles.offerButtonDesc}>
              {props?.premium?.desc}
            </span>
            <span className={styles.offerButtonDesc2}>
              {props?.premium?.desc2}
            </span>
          </div>
        </div>
      ) : (
        <>
          <PremiumRadioButtonOffer {...props} input={input} />

          <AreYouSureModal
            onClose={() => {
              segmentTracking('not_right_now clicked', {
                location: getSegmentLocation(openLocation),
              });
              setItem(ARE_YOU_SURE_KEY, areYouSureModalCount + 1);
              if (areYouSureModalCount > 2 && !areYouSureModalDate) {
                // we are setting the date to 6 months from now
                setItem(
                  ARE_YOU_SURE_DATE_KEY,
                  Date.now() + 6 * 30 * 24 * 60 * 60 * 1000,
                );
              }
              setShowAreYouSure(false);
            }}
            showPremium={() => {
              segmentTracking('learn_more_about_fee clicked', {
                location: getSegmentLocation(openLocation),
              });
              setShowAreYouSure(false);
              setShowPremium(true);
            }}
            onLoad={() =>
              segmentTracking('are_you_sure_modal loaded', {
                location: getSegmentLocation(openLocation),
              })
            }
            yourPrice={applicationFee}
            discountPrice={appFeePriceExpPremiumPrice}
            open={input.value === 'free' && showAreYouSure}
          />

          {input.value === 'premium' && (
            <PremiumSubscriptionModal
              openLocation={openLocation}
              copy="inviteToApply"
              onClose={() => input.onChange(null)}
              refetchQueries={[basicUserProfile, ownerSettingsQuery]}
              onSuccess={() => input.onChange('premium')}
              purchase_location={purchase_location}
            />
          )}

          {showPremium && (
            <PremiumSubscriptionModal
              openLocation={openLocation}
              copy="inviteToApply"
              onClose={() => setShowPremium(false)}
              refetchQueries={[basicUserProfile, ownerSettingsQuery]}
              onSuccess={() => {
                setShowPremium(false);
                input.onChange('premium');
              }}
              purchase_location={purchase_location}
            />
          )}
        </>
      )}
    </div>
  );
};

PremiumRadioButtonOfferWrapped.defaultProps = {
  hideLabel: false,
};

PremiumRadioButtonOfferWrapped.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  openLocation: PropTypes.string,
  purchase_location: PropTypes.string,
  hideLabel: PropTypes.bool,
  premium: PropTypes.object,
};

export default PremiumRadioButtonOfferWrapped;
