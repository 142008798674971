import React, { memo } from 'react';

const TurboTaxLogo = (props) => {
  return (
    <svg
      viewBox="0 0 2174 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M300 600C465.685 600 600 465.685 600 300C600 134.315 465.685 0 300 0C134.315 0 0 134.315 0 300C0 465.685 134.315 600 300 600Z"
        fill="#D52B1E"
      />
      <path
        d="M233.067 410C198.601 385.133 165.467 358.6 133.734 330.467C122.067 356.067 111.601 382.267 102.334 409.067C152.934 452 220.601 501.2 267.001 528.867C327.201 373 421.134 271.333 511.001 200.4C499.467 176 483.801 153.867 464.934 135C369.134 208.467 289.667 302.333 233.067 410Z"
        fill="white"
      />
      <path
        d="M684.933 431.534V394.4H717.4V340.667H763V394.4H809.4V431.534H763V517.734C763 534.334 766.866 546.334 787.333 546.334C793.533 546.334 801.667 545.2 807.067 542.467L808.6 578.8C800.466 581.867 788.133 583.8 777.667 583.8C728.533 583.8 717.333 553.667 717.333 518.067V431.534H684.933Z"
        fill="black"
      />
      <path
        d="M1011.27 580H967.2V550.2H966.4C958.267 568.734 936.267 585.4 908.8 585.4C860.467 585.4 840.334 548.267 840.334 510.4V394.4H886.734V497.267C886.734 519.334 891.734 546.734 921.534 546.734C950.934 546.734 964.867 521.2 964.867 496.467V394.4H1011.27V580Z"
        fill="black"
      />
      <path
        d="M1059.27 390.933H1103.73V421.867H1104.53C1114.6 400.6 1134.33 385.533 1158.67 385.533C1162.13 385.533 1166.8 385.933 1169.87 386.667V429.2C1163.67 427.667 1159.8 427.266 1155.53 427.266C1113.8 427.266 1105.67 463.6 1105.67 477.133V576.533H1059.27V390.933V390.933Z"
        fill="black"
      />
      <path
        d="M1246.4 287.667V418H1247.53C1257.2 406 1275.73 389 1309.8 389C1362.8 389 1399.87 431.534 1399.87 486.467C1399.87 540.6 1365.07 585.467 1308.6 585.467C1282.67 585.467 1257.53 573.467 1244.8 552.2H1244V580.067H1199.93V287.734H1246.4V287.667ZM1299.33 426.867C1265.33 426.867 1244.8 458.2 1244.8 487.2C1244.8 516.2 1265.27 546.734 1299.33 546.734C1335.27 546.734 1352.67 515.4 1352.67 486.4C1352.73 457.4 1335.33 426.867 1299.33 426.867Z"
        fill="black"
      />
      <path
        d="M1520.93 389C1577 389 1621.87 428.067 1621.87 486.467C1621.87 544.867 1577 585.467 1520.93 585.467C1464.87 585.467 1420 544.867 1420 486.467C1420 428.067 1464.87 389 1520.93 389ZM1520.93 546.733C1557.67 546.733 1574.67 515.4 1574.67 486.4C1574.67 457.4 1557.67 426.867 1520.93 426.867C1484.2 426.867 1467.2 457.4 1467.2 486.4C1467.2 515.4 1484.2 546.733 1520.93 546.733Z"
        fill="black"
      />
      <path
        d="M1631.47 431.534V394.4H1663.93V340.667H1709.53V394.4H1755.93V431.534H1709.53V517.734C1709.53 534.334 1713.4 546.334 1733.87 546.334C1740.07 546.334 1748.2 545.2 1753.6 542.467L1755.13 578.8C1747 581.867 1734.67 583.8 1724.2 583.8C1675.07 583.8 1663.87 553.667 1663.87 518.067V431.534H1631.47Z"
        fill="black"
      />
      <path
        d="M1908.73 556.4H1907.6C1898.33 572.667 1876.27 584.6 1850 584.6C1820.2 584.6 1780.4 570.267 1780.4 527C1780.4 471.733 1850 464 1908 464V459.333C1908 436.533 1888.67 425.333 1865.47 425.333C1845.73 425.333 1826.8 434.6 1814.8 446.6L1790.8 418.4C1811.67 399.067 1841.07 389 1869.27 389C1935.8 389 1952 430 1952 467.133V580.067H1908.67V556.4H1908.73ZM1907.93 495.333H1897.47C1868.07 495.333 1826.73 498.8 1826.73 526.267C1826.73 544.067 1843.33 549.467 1859.2 549.467C1890.93 549.467 1907.93 531.267 1907.93 501.933V495.333Z"
        fill="black"
      />
      <path
        d="M2040.6 481.4L1973.73 394.4H2029.4L2069.6 452L2111 394.4H2164.33L2097.8 481.4L2173.6 580H2117.13L2068.8 512.334L2020.07 580H1965.13L2040.6 481.4Z"
        fill="black"
      />
      <path
        d="M1193.33 139.267C1193.33 186.334 1231 220.734 1278.87 220.734C1326.73 220.734 1364.47 186.401 1364.47 139.267V31.2007H1318.07V133.734C1318.07 159.134 1301.07 176.134 1278.73 176.134C1256.33 176.134 1239.4 159.134 1239.4 133.734V31.2007H1193.07L1193.33 139.267Z"
        fill="black"
      />
      <path
        d="M1488.33 74.6006H1543.67V217.001H1590V74.6006H1645.27V31.2007H1488.33V74.6006Z"
        fill="black"
      />
      <path d="M1458.4 31.2666H1412.07V217.067H1458.4V31.2666Z" fill="black" />
      <path
        d="M1006.13 74.6006H1061.47V217.001H1107.8V74.6006H1163.07V31.2007H1006.13V74.6006Z"
        fill="black"
      />
      <path d="M763 31.2666H716.667V217.067H763V124.2V31.2666Z" fill="black" />
      <path
        d="M981.734 108.933C981.734 61.8668 944 27.4668 896.134 27.4668C848.267 27.4668 810.534 61.8001 810.534 108.933V217H856.867V114.467C856.867 89.0668 873.867 72.0668 896.201 72.0668C918.534 72.0668 935.534 89.0668 935.534 114.467V217H981.867L981.734 108.933Z"
        fill="black"
      />
    </svg>
  );
};

export default memo(TurboTaxLogo);
