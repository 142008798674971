import React from 'react';

import MarketingImg from '../../../../assets/marketing/product-overview.png';
import SidebarMarketing from '../../../../components/SidebarMarketing';
import SideBarReviews from '../../../../components/SideBarReviews';
import { Experiments } from '../../../../constants/experiments';
import JourneyMapSidebar from '../ExperimentSidebars/JourneyMapSidebar';
import LogoGardenSidebar from '../ExperimentSidebars/LogoGardenSidebar';
import LogoGardenSidebarAnimated from '../ExperimentSidebars/LogoGardenSidebarAnimated';
import Gt3pSignupOnboardingExperimentSidebar from '../Gt3pSignupOnboardingExperimentComponents/Gt3pSignupOnboardingExperimentSidebar';
import Gt3pSignupOnboardingExperimentSidebarAnimated from '../Gt3pSignupOnboardingExperimentComponents/Gt3pSignupOnboardingExperimentSidebarAnimated';

const getExperimentSidebar = (currentPath, experimentVariant) => {
  if (
    currentPath === '/auth/signup/final' &&
    experimentVariant === 'gt3Variant1'
  ) {
    return <Gt3pSignupOnboardingExperimentSidebar />;
  }

  if (
    currentPath === '/auth/signup/final' &&
    experimentVariant === 'gt3Variant2'
  ) {
    return <Gt3pSignupOnboardingExperimentSidebarAnimated />;
  }

  if (
    currentPath === '/auth/signup/final' &&
    experimentVariant !==
      Experiments.GtOnePropertyNllSignupOnboardingAnswers.variants.variant_1
  ) {
    return <SideBarReviews />;
  }

  if (
    currentPath === '/auth/signup/final' &&
    experimentVariant ===
      Experiments.GtOnePropertyNllSignupOnboardingAnswers.variants.variant_1
  ) {
    return <JourneyMapSidebar />;
  }

  if (experimentVariant === 'logogarden') {
    return <LogoGardenSidebar />;
  }

  if (experimentVariant === 'logogarden_animated') {
    return <LogoGardenSidebarAnimated />;
  }

  return (
    <SidebarMarketing
      title="Advertising, Screening, And More"
      subtitle="As an independent landlord, manage your rental yourself."
      graphic={MarketingImg}
    />
  );
};

export default getExperimentSidebar;
