import { useActiveExperiments } from '../../providers/ActiveExperimentsProvider/context';
import getCookieExperimentVariant from '../experiments/getCookieExperimentVariant';

export const useCookieExperimentVariant = (experimentName, fallback = '') => {
  const { cookieExperiments } = useActiveExperiments();

  if (!cookieExperiments) {
    return null;
  }

  return getCookieExperimentVariant(experimentName, fallback);
};
