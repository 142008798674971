import React from 'react';
import PropTypes from 'prop-types';

import {
  profileColorTextPair,
  profilePictureColors,
} from '../../../../constants/colors';
import AcceptIcon from '../../../../icons/Accept';

import styles from './ProCustomLabel.module.scss';

const calculateColor = (email) => {
  let sum = 0;
  for (const index in email) {
    sum += email.charCodeAt(index);
  }
  return sum % profilePictureColors.length;
};

const ProCustomLabel = ({ name, company, email, isSelected }) => {
  const backgroundColor = profilePictureColors[calculateColor(name)];
  const textColor = profileColorTextPair[backgroundColor];

  /*
   * Check if name has more than 1 word
   * If there is two words, then we need to get the first letter of each word
   * If there is more then two words, then we need to get the first letter of the first word
   * and the first letter of the last word
   */
  const nameLetters = name
    .trim()
    .split(' ')
    .map((word) => word[0]);
  let initials;
  if (nameLetters.length > 1) {
    initials = `${nameLetters[0]}${nameLetters[nameLetters.length - 1]}`;
  } else {
    initials = nameLetters[0];
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.avatar} style={{ backgroundColor }}>
          <span className={styles.avatarText} style={{ color: textColor }}>
            {initials}
          </span>
        </div>
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.company}>{company ? company : email}</span>
        </div>
      </div>
      {isSelected ? <AcceptIcon /> : null}
    </div>
  );
};

ProCustomLabel.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default ProCustomLabel;
