import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Undo = ({ color, ...props }) => (
  <svg viewBox="0 0 18 20" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M.808.4c.335 0 .607.273.607.61v3.673c3.272-3.22 8.509-3.71 12.36-.931 4.317 3.113 5.318 9.174 2.238 13.536a9.65 9.65 0 0 1-2.145 2.197.584.584 0 0 1-.822-.128.598.598 0 0 1 .128-.83 8.467 8.467 0 0 0 1.882-1.93C17.76 12.77 16.88 7.452 13.093 4.72 9.498 2.125 4.526 2.774 1.718 6.092h4.761c.308 0 .562.23.602.527l.006.083c0 .337-.272.61-.608.61H.808a.609.609 0 0 1-.608-.61V1.01C.2.673.472.4.808.4Z"
    />
  </svg>
);

Undo.defaultProps = {
  width: 18,
  height: 20,
  color: 'currentColor',
};

Undo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Undo);
