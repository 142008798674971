import { v4 as uuidv4 } from 'uuid';

export const INIT_ACTION = 'INIT';
export const LISTING_UPDATED = 'LISTING_UPDATED';
export const ADD_MONTHLY_CHARGE = 'ADD_MONTHLY_CHARGE';
export const EDIT_MONTHLY_CHARGE = 'EDIT_MONTHLY_CHARGE';
export const ADD_ONE_TIME_CHARGE = 'ADD_ONE_TIME_CHARGE';
export const REMOVE_MONTHLY_CHARGE = 'REMOVE_MONTHLY_CHARGE';
export const MANAGE_LATE_FEE_POLICIES = 'MANAGE_LATE_FEE_POLICIES';
export const EDIT_ONE_TIME_CHARGE = 'EDIT_ONE_TIME_CHARGE';
export const REMOVE_ONE_TIME_CHARGE = 'REMOVE_ONE_TIME_CHARGE';
export const SAVE = 'SAVE';

export const initData = {
  dirty: false,
  listing: {},
  monthlyCharges: [],
  oneTimeCharges: [],
};

export const init = (data = {}) => ({
  type: INIT_ACTION,
  data: data || {},
});

export const updateListing = (listing) => ({
  type: LISTING_UPDATED,
  data: listing,
});

export const addMonthlyCharge = (charge) => ({
  type: ADD_MONTHLY_CHARGE,
  data: { id: uuidv4(), ...charge },
});

export const addOneTimeCharge = (charge) => ({
  type: ADD_ONE_TIME_CHARGE,
  data: { id: uuidv4(), ...charge },
});

export const removeMonthlyCharge = (id) => ({
  type: REMOVE_MONTHLY_CHARGE,
  data: id,
});

export const removeOneTimeCharge = (id) => ({
  type: REMOVE_ONE_TIME_CHARGE,
  data: id,
});

export const editMonthlyCharge = (charge) => ({
  type: EDIT_MONTHLY_CHARGE,
  data: charge,
});

export const editOneTimeCharge = (charge) => ({
  type: EDIT_ONE_TIME_CHARGE,
  data: charge,
});

export const manageLateFeePolicies = (id, policies) => ({
  type: MANAGE_LATE_FEE_POLICIES,
  data: { id, policies },
});

export const saveData = () => ({
  type: SAVE,
});

const chargesFirstReducer = (state, action) => {
  switch (action.type) {
    case INIT_ACTION:
      return {
        dirty: false,
        listing: {},
        monthlyCharges: [],
        oneTimeCharges: [],
        ...action.data,
      };

    case LISTING_UPDATED:
      return {
        ...state,
        dirty: true,
        listing: { ...state.listing, ...action.data },
      };
    case ADD_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        monthlyCharges: [...state.monthlyCharges, action.data],
      };
    case ADD_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        oneTimeCharges: [...state.oneTimeCharges, action.data],
      };
    case REMOVE_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        monthlyCharges: state.monthlyCharges.filter(
          (charge) => charge.id !== action.data,
        ),
      };
    case REMOVE_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        oneTimeCharges: state.oneTimeCharges.filter(
          (charge) => charge.id !== action.data,
        ),
      };
    case EDIT_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        monthlyCharges: state.monthlyCharges.map((charge) =>
          charge.id === action.data.id ? action.data : charge,
        ),
      };
    case EDIT_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        oneTimeCharges: state.oneTimeCharges.map((charge) =>
          charge.id === action.data.id ? action.data : charge,
        ),
      };
    case MANAGE_LATE_FEE_POLICIES: {
      return {
        ...state,
        dirty: true,
        monthlyCharges: state.monthlyCharges.map((charge) => {
          if (charge.id === action.data.id) {
            return { ...charge, late_fee_policy: action.data.policies };
          }
          return charge;
        }),
      };
    }
    case SAVE:
      return {
        ...state,
        dirty: false,
      };
    default:
      return state;
  }
};

export default chargesFirstReducer;
