import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox';
import { H2, Link } from '../Typography';

import styles from './GBBPremiumModal.module.scss';

const SubscriptionAddOn = ({
  isChecked,
  onCheckboxChange,
  totalCost,
  numberOfUnits,
  onEditClick,
}) => (
  <>
    <H2 className={styles.subscriptionTitle}>Subscription Add-On</H2>
    <div
      className={cx(styles.rentalAccountingContainer, {
        [styles.activeBackground]: isChecked,
      })}
    >
      <div className={styles.checkboxPriceContainer}>
        <Checkbox
          label="Rental Accounting"
          type="checkbox"
          id="rental_accounting"
          name="rental_accounting"
          className={styles.checkbox}
          onChange={onCheckboxChange}
          checked={isChecked}
        />
        <H2 className={styles.price}>+${totalCost}/year</H2>
      </div>
      <div className={styles.rentalAccountingContent}>
        Automate your bookkeeping with the accounting tool built specifically
        for real estate investors.
        <span className={styles.boldContent}>
          {' '}
          Pricing based on {numberOfUnits} units{' '}
        </span>
        (
        <Link className={styles.link} onClick={onEditClick}>
          edit
        </Link>
        ).
      </div>
    </div>
  </>
);

SubscriptionAddOn.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  totalCost: PropTypes.number.isRequired,
  numberOfUnits: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default SubscriptionAddOn;
