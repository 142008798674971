import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useRenderOutside } from '../../helpers/render-outside';
import LeaseAgreementEditorToolbar from '../../pages/digital-leases/LeaseAgreementEditor/LeaseAgreementEditorToolbar';
import LoadingScreen from '../LoadingScreen';

import FormsEditorLeaveModal from './FormsEditorLeaveModal';

import styles from './FormsEditor.module.scss';

const FormsEditor = ({
  className,
  editorClassName,
  initialHtml = '',
  loading = false,
  disabled = false,
  documentName,
  history,
  onRevert,
  onDownloadOriginal,
  editorRef,
  skipLeaveCheck,
}) => {
  const renderOutside = useRenderOutside();
  const [editorHtml, setEditorHtml] = useState(initialHtml);
  const [initialEditorHtml, setInitialEditorHtml] = useState(initialHtml);
  const internalEditorRef = useRef(null);
  const unblock = useRef(() => {});
  const setToLeave = useRef(false);

  useEffect(() => {
    if (editorRef) {
      editorRef.current = {
        getCurrentHtml: () =>
          internalEditorRef.current?.innerHTML || editorHtml,
      };
    }
  }, [editorHtml]);

  useEffect(() => {
    if (internalEditorRef.current) {
      setInitialEditorHtml(internalEditorRef.current.innerHTML);
    }
  }, []);

  useEffect(() => {
    if (history) {
      unblock.current = history.block((location) => {
        if (setToLeave.current) {
          return true;
        }

        const editorCurrentHtml = internalEditorRef.current?.innerHTML || '';
        const dirty = editorCurrentHtml !== initialEditorHtml;

        if (!dirty || skipLeaveCheck) {
          return true;
        }

        renderOutside((done) => (
          <FormsEditorLeaveModal
            open
            loading={false}
            onRevert={async () => {
              if (onRevert) {
                await onRevert();
              }
              setToLeave.current = true;
              history.replace(location.pathname);
              done();
            }}
            onGoBack={done}
          />
        ));

        return false;
      });
    }

    return () => {
      unblock.current();
    };
  }, [initialEditorHtml, history, onRevert]);

  const onCut = (e) => {
    e.preventDefault();
  };

  const onCopy = (e) => {
    e.preventDefault();
  };

  return (
    <LoadingScreen
      className={cx(styles.container, className)}
      loading={loading}
    >
      <div className={styles.editorHeader}>
        <h2 className={styles.title}>Copy of {documentName}</h2>
        <p className={styles.text}>
          You can now make edits to this form before saving directly to your
          account! Or,{' '}
          <button className={styles.downloadLink} onClick={onDownloadOriginal}>
            download an original copy
          </button>
          .
        </p>
      </div>
      <div className={styles.editorContainer}>
        <LeaseAgreementEditorToolbar className={styles.toolbar} />
        <div className={styles.editorInnerContainer}>
          <ContentEditable
            className={cx(
              styles.editor,
              styles.fullPageHeight,
              editorClassName,
            )}
            innerRef={internalEditorRef}
            html={editorHtml}
            disabled={disabled}
            onCopy={onCut}
            onCut={onCopy}
            onChange={(e) => {
              setEditorHtml(e.target.value);
            }}
          />
        </div>
      </div>
    </LoadingScreen>
  );
};

FormsEditor.propTypes = {
  className: PropTypes.string,
  editorClassName: PropTypes.string,
  initialHtml: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  documentName: PropTypes.string,
  history: PropTypes.object,
  onRevert: PropTypes.func,
  onDownloadOriginal: PropTypes.func,
  editorRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  skipLeaveCheck: PropTypes.bool,
};

export default FormsEditor;
