import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { H2, Paragraph } from '../Typography';

import SmallStepper from './SmallStepper';

import styles from './GBBPremiumModal.module.scss';

const EditUnitsModal = ({
  isOpen,
  onClose,
  initialValue,
  onSave,
  min,
  max,
}) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    id="EditUnitsModal"
    size="extra-small"
    showCloseIcon
  >
    <Modal.Content noHeader noFooter>
      <H2 className={styles.editUnitsModalTitle}>Update Your Units</H2>
      <Paragraph className={styles.subtitle}>
        How many units do you own or manage?
      </Paragraph>

      <SmallStepper
        initialValue={initialValue}
        min={min}
        max={max}
        onSave={onSave}
        onCancel={onClose}
      />
    </Modal.Content>
  </Modal>
);

EditUnitsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValue: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

EditUnitsModal.defaultProps = {
  min: 1,
  max: 999,
};

export default EditUnitsModal;
