import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Redo = ({ color, ...props }) => (
  <svg viewBox="0 0 18 20" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.235.2c.312 0 .565.273.565.61v5.692c0 .337-.253.61-.565.61h-5.27c-.312 0-.565-.273-.565-.61 0-.337.253-.61.565-.61h4.317C13.474 2.574 8.502 1.925 4.908 4.52 1.12 7.25.24 12.569 2.944 16.398a8.467 8.467 0 0 0 1.883 1.928.598.598 0 0 1 .127.83.584.584 0 0 1-.822.13 9.65 9.65 0 0 1-2.145-2.198C-1.093 12.726-.092 6.665 4.225 3.552 8.11.748 13.406 1.272 16.671 4.569V.809c0-.308.212-.563.488-.603L17.235.2Z"
    />
  </svg>
);

Redo.defaultProps = {
  width: 18,
  height: 20,
  color: 'currentColor',
};

Redo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Redo);
