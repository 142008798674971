import React, { memo } from 'react';
import PropTypes from 'prop-types';

const SecurityLockColored = ({ color, lockFill, ...props }) => (
  <svg {...props} viewBox="0 0 39 53">
    <g fill="none" fillRule="evenodd">
      <path
        fill={lockFill}
        d="M8 8c5.333-4.444 9.333-6.667 12-6.667S26.222 3.556 30.667 8v13.333H8V8Z"
      />
      <path
        fill={color}
        d="M19.5 0c1.403.028 2.792.27 4.117.753a12.87 12.87 0 0 1 4.72 3.039 13.433 13.433 0 0 1 3.103 4.956 13.98 13.98 0 0 1 .781 4.413c.014 2.206.005 4.412.002 6.618 1.832.047 3.661.121 5.49.227.121.022.234.048.346.104.255.127.454.358.547.634.041.12.054.24.06.367v31.111c-.006.128-.019.246-.06.367a1.1 1.1 0 0 1-.659.682 1.214 1.214 0 0 1-.354.062H1.074a1.213 1.213 0 0 1-.355-.062 1.1 1.1 0 0 1-.659-.682 1.334 1.334 0 0 1-.06-.367V21.111c.007-.128.02-.246.06-.367a1.1 1.1 0 0 1 .66-.682c.116-.042.231-.055.354-.062 1.777 0 3.555-.015 5.332-.038-.058-2.324 0-4.65.043-6.973.046-1.503.316-2.987.833-4.394a13.433 13.433 0 0 1 3.047-4.803A12.872 12.872 0 0 1 14.901.809 12.728 12.728 0 0 1 19.5 0Zm17.019 22.222-5.371-.001-1.724.07c-4.256.145-8.516.097-12.777.032l-3.652-.055a385.772 385.772 0 0 0-5.476-.046h-5.37v28.89h34.37v-28.89Zm-17.142 8.89.066.001c.321.026.63.106.914.265.242.136.458.32.633.54a2.242 2.242 0 0 1 .49 1.302c.04.825-.381 1.613-1.058 2.028l-.011-.573.025 1.409c.02 1.694.01 3.388-.03 5.08a1.196 1.196 0 0 1-.122.463 1.106 1.106 0 0 1-.57.523 1.067 1.067 0 0 1-1.307-.476 1.187 1.187 0 0 1-.146-.51 144.757 144.757 0 0 1-.018-5.916 2.269 2.269 0 0 1-.567-3.33c.176-.22.392-.404.634-.54.285-.16.592-.24.914-.265l.065-.001h.088Zm.012-28.89h-.195c-1.213.024-2.413.241-3.554.673a10.759 10.759 0 0 0-4.082 2.771A11.206 11.206 0 0 0 9.2 9.64c-.405 1.189-.6 2.437-.607 3.696v6.593c7.162-.116 14.327-.325 21.483-.2l-.002.27c0-2.317.04-4.636-.003-6.954a11.65 11.65 0 0 0-.648-3.533 11.2 11.2 0 0 0-2.586-4.13 10.732 10.732 0 0 0-3.81-2.487 10.605 10.605 0 0 0-3.554-.673h-.084Z"
      />
    </g>
  </svg>
);

SecurityLockColored.defaultProps = {
  width: 39,
  height: 53,
  color: 'currentColor',
  lockFill: '#7FE3FF',
};

SecurityLockColored.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(SecurityLockColored);
