import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import {
  getCurrentToken,
  isUserProfileToken,
} from '../../core/auth/authService';
import { useUserProfile } from '../../core/TTgraphql';
import { useStatusEndpoint } from '../../helpers/status-endpoint';

import { ActiveExperimentsContext } from './context';

const ActiveExperimentsProvider = ({ children }) => {
  const shouldFetchProfile = isUserProfileToken(getCurrentToken());
  const { user, loadingUser } = useUserProfile({
    skip: !shouldFetchProfile,
  });
  const { cookieExperiments } = useStatusEndpoint();
  const experiments = useMemo(() => {
    const userExperiments =
      user?.active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || null;

    const ownerExperiments =
      user?.owner_active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || null;

    return {
      userExperiments,
      ownerExperiments,
      cookieExperiments,
    };
  }, [user, cookieExperiments]);

  const isLoggedIn = user !== null;

  if (
    loadingUser ||
    (!experiments.userExperiments && isLoggedIn) || // We only wait for userExperiments if user is logged in
    !experiments.cookieExperiments // If user is not logged in, we still need to wait for cookieExperiments
  ) {
    return <LoadingScreen loading />;
  }

  return (
    <ActiveExperimentsContext.Provider value={experiments}>
      {children}
    </ActiveExperimentsContext.Provider>
  );
};

ActiveExperimentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActiveExperimentsProvider;
