import React from 'react';

import SidebarImage from '../../../../../src/assets/Journey Map.svg';

import styles from './JourneyMapSidebar.module.scss';

const JourneyMapSidebar = () => {
  return (
    <div className={styles.container}>
      <h3>Solve for Every Step, Start to Finish</h3>
      <img src={SidebarImage} />
    </div>
  );
};

export default JourneyMapSidebar;
