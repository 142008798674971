import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import basicProfileQuery from '../../graphql/basicUserProfile.graphql';
import coverAchFeeMutation from '../../graphql/mutations/setCoverAchFee.graphql';
import { useRenderOutside } from '../../helpers/render-outside';
import { segmentTracking } from '../../services/utilities';
import PremiumSubscriptionModal from '../PremiumSubscription';
import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';

import ACHFeeCard from './ACHFeeCard';

const ACHFeeCardWrapped = ({ onNext }) => {
  const [setCoverAchFeeMutation, { loading: loadingMutation }] = useMutation(
    coverAchFeeMutation,
    {
      refetchQueries: [{ query: basicProfileQuery }],
    },
  );

  const renderOutside = useRenderOutside();

  const onShowPremiumModal = () => {
    renderOutside((done) => (
      <PremiumSubscriptionModal
        onClose={() => {
          done();
        }}
        onSuccess={() => {
          done();
          onNext();
        }}
        refetchQueries={[basicProfileQuery]}
        openLocation={MODAL_LOCATIONS.PREMIUM_UPSELL_PAGE}
        purchase_location="RP Premium Upsell - ACH"
      />
    ));
  };

  const onSubmit = async (data) => {
    const coverAchFee = data.coverAchFee;

    segmentTracking('next clicked', {
      location: 'RP Premium Upsell',
      cover_ach_fee: coverAchFee,
    });

    const isPremiumSelected = coverAchFee === 'Neither';
    if (isPremiumSelected) {
      onShowPremiumModal();
      return;
    }

    await setCoverAchFeeMutation({
      variables: {
        coverAchFee: coverAchFee === 'Landlord',
      },
    });

    onNext();
  };

  return <ACHFeeCard onSubmit={onSubmit} loading={loadingMutation} />;
};

ACHFeeCardWrapped.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default ACHFeeCardWrapped;
