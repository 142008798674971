import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextBold = ({ color, ...props }) => (
  <svg viewBox="0 0 15 20" {...props} fill={color}>
    <path
      fill="#042238"
      fillRule="evenodd"
      d="M1.658 19.6a.324.324 0 0 1-.097-.016C1.015 19.498.6 19.06.6 18.534V1.466c0-.527.415-.965.96-1.051A.33.33 0 0 1 1.658.4h5.339c3.087 0 5.591 2.305 5.591 5.148 0 1.417-.622 2.7-1.628 3.63 1.924.919 3.24 2.77 3.24 4.903 0 3.047-2.685 5.519-5.994 5.519H1.658Zm1.259-8.905v6.771h5.289c2.03 0 3.677-1.516 3.677-3.385 0-1.869-1.647-3.385-3.677-3.385l-5.289-.001Zm4.08-8.162h-4.08v6.029h4.08c1.808 0 3.274-1.35 3.274-3.014 0-1.665-1.466-3.015-3.274-3.015Z"
    />
  </svg>
);

TextBold.defaultProps = {
  width: 15,
  height: 20,
  color: 'currentColor',
};

TextBold.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextBold);
