import React from 'react';
import cx from 'classnames';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import CaretDown from '../../icons/CaretDown';

import styles from './Dropdown.module.scss';

const Dropdown = ({
  className,
  buttonClassName,
  buttonLabel,
  listClassName,
  listItemClassName,
  items,
  onSelect,
  loading,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem?.value);
    },
  });

  return (
    <div className={cx(styles.dropdownContainer, className)}>
      <button
        className={cx(styles.dropdownButton, buttonClassName)}
        {...getToggleButtonProps()}
        aria-label="toggle menu"
      >
        {loading ? 'Loading...' : selectedItem?.label || buttonLabel}
        <CaretDown className={styles.btnIcon} />
      </button>
      <ul
        className={cx(styles.dropdownList, listClassName, {
          [styles.opened]: isOpen && !loading,
        })}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              key={item.value}
              className={cx(styles.dropdownListItem, listItemClassName, {
                [styles.highlighred]: highlightedIndex === index,
                [styles.selected]: selectedItem?.value === item.value,
              })}
              {...getItemProps({
                index,
                item,
                onMouseDown: (e) => {
                  e.preventDefault();
                },
              })}
            >
              {item.label}
            </li>
          ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
  loading: PropTypes.bool,
};

Dropdown.defaultProps = {
  items: [],
  buttonLabel: 'Select',
};

export default Dropdown;
