export const Experiments = {
  GoodBetterBest: {
    name: 'experiment_gbb',
    variants: {
      A: 'A', // Tabs
      B: 'B', // Accordion
      C: 'C', // Toggle
      D: 'D', // Tabs Premium Plus 299
      E: 'E', // Tabs Premium Plus 249
      G: 'G',
      control: 'Control',
    },
  },
  LandlordPaysSpecificStates: {
    name: 'LL_Pays_NY_WI_MA_VT',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  LandlordPaysOtherStates: {
    name: 'LL_Pays_Other_States',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  MaintenanceRequestLulaProCardAug2024: {
    name: 'maintenance_request_lula_pro_card_aug_2024',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  RentPaymentsFlowOptimization: {
    name: 'rp_flow_optimization',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  ReiHubAddonOffer: {
    name: 'rei_addon_offer',
    variants: {
      quarterly: 'quarterly',
      annually: 'annually',
    },
  },
  ReiHubMonthlyTrialOffer: {
    name: 'rei_monthly_trial_offer',
    variants: {
      test: 'free_trial',
      control: '50_percent_off',
    },
  },
  LeaseAgreementCosigner: {
    name: 'product_lease_agreement_co-signer',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  PremiumModalGooglePay: {
    name: 'premium_checkout_modal_google_apple_pay_test',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  GeneralApplicationFlow: {
    name: 'general_application_flow',
    variants: {
      variantA: 'variant_A',
      control: 'control',
    },
  },
  RenterCreditReportingPricing: {
    name: 'renter_credit_reporting_pricing',
    variants: {
      control: 'control',
      test_1: 'test_1',
      test_2: 'test_2',
    },
  },
  RenterCreditReportingOptInPromo: {
    name: 'renter_credit_reporting_opt_in_promo',
    variants: {
      control: 'control',
      test_1: 'test_1',
      test_2: 'test_2',
    },
  },
  GeneralApplicationTest2: {
    name: 'general_application_test_2',
    variants: {
      control: 'Control',
      variant_A: 'variant_A',
      variant_B: 'variant_B',
      variant_C: 'variant_C',
    },
  },
  RenterUpfrontPricingTest: {
    name: 'application_upfront_pricing_test',
    variants: {
      control: 'control',
      test_1: 'variant_A',
    },
  },
  CxScheduleProcess: {
    name: 'gt_cx_schedule_process',
    variants: {
      control: 'control',
      test: 'variant_1',
    },
  },
  LLPropertyAdd: {
    name: 'gt_ll_property_add',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  PaymentOnboardingUpsellPremium: {
    name: 'payment_onboarding_upsell_premium_exp',
    variants: {
      ach_form: 'ach_form',
      premium_card: 'premium_card',
      control: 'control',
    },
  },
  GtSignupBluebox: {
    name: 'gt_signup_bluebox',
    variants: {
      control: 'control',
      logogarden: 'logogarden',
      logogarden_animated: 'logogarden_animated',
    },
  },
  GtOnePropertyNllSignupOnboardingAnswers: {
    name: 'gt_1p_nll_signup_onboarding_answers',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  RRAnnualFlow: {
    name: 'renter_credit_annual_flow_experiment',
    variants: {
      control: 'control',
      variant_B: 'variant_B',
      variant_C: 'variant_C',
    },
  },
  GtCxSchedulePersonalize: {
    name: 'gt_cx_schedule_personalize',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GT_QUESTION_TWO_COPY: {
    name: 'gt_question_two_copy',
    variants: {
      control: 'control',
      units: 'units',
    },
  },
  ChargesFirst: {
    name: 'charges_first',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  GtOnboardingPhoneNumber: {
    name: 'gt_onboarding_phone_number',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  AccountingOnboardingOption: {
    name: 'accounting_onboarding_option',
    variants: {
      control: 'control',
      test: 'show_accounting_option',
    },
  },
  AvailableDateCopyTest: {
    name: 'available_date_copy_test',
    variants: {
      control: 'control',
      variant: 'variant',
    },
  },
  FirstApplicationPrice: {
    name: 'first_application_price',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
      variant_3: 'variant_3',
    },
  },
  GtAnimatedLogogardenMobile: {
    name: 'animated_logogarden_mobile',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  AccountingPage: {
    name: 'accounting_page',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
    },
  },
  GtThreePropertiesNllSignupOnboardingAnswers: {
    name: 'gt_3p_signup_onboarding_answers',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
    },
  },
};
