const getCheckboxError = (
  { error, submitError, value, submitFailed },
  skipTouchedCheck,
) => {
  if (skipTouchedCheck && error) {
    return error;
  }

  if (submitFailed && error) {
    return error;
  }

  if (!value && submitError) {
    return submitError;
  }

  return null;
};

export default getCheckboxError;
