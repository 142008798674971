import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextJustifyLeft = ({ color, ...props }) => (
  <svg viewBox="0 0 20 18" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.012 16.627a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024ZM16.662.2a.587.587 0 1 1 0 1.173H.987A.587.587 0 1 1 .988.2H16.66Zm-3.135 2.347a.587.587 0 1 1 0 1.173H.987a.587.587 0 1 1 0-1.173h12.54Zm1.567 2.346a.587.587 0 1 1 0 1.174H.988a.587.587 0 1 1 0-1.174h14.106Zm3.918 2.347a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h18.024Zm-3.134 2.347a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h14.89Zm-2.351 2.346a.587.587 0 1 1 0 1.174H.987a.587.587 0 1 1 0-1.174h12.54Zm2.35 2.347a.587.587 0 1 1 0 1.173H.988a.587.587 0 1 1 0-1.173h14.89Z"
    />
  </svg>
);

TextJustifyLeft.defaultProps = {
  width: 20,
  height: 18,
  color: 'currentColor',
};

TextJustifyLeft.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextJustifyLeft);
