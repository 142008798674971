const getCookieExperimentVariant = (experimentName, fallback = '') => {
  const cookies = document.cookie.split(';');
  const cookieName = `experiment_${experimentName}`;

  const experimentCookie = cookies
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${cookieName}=`));

  if (!experimentCookie) {
    return fallback;
  }

  return experimentCookie.split('=')[1];
};

export default getCookieExperimentVariant;
