import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import DownloadMobileAppGraphic from '../../../../assets/download-mobile-app.png';
import DownloadMobileAppMobileGraphic from '../../../../assets/download-mobile-app-mobile.png';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FlatButton from '../../../../components/FlatButton';
import { H2, Paragraph } from '../../../../components/Typography';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import completeOnboardingGQL from '../../../../graphql/mutations/completeRenterOnboarding.graphql';
import { linkTo, routes } from '../../../../helpers/routes';
import { useRentersProfile } from '../../../../providers/RentersProfileProvider/context';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './DownloadApp.module.scss';

const downloadAppLink = 'https://turbo-tenant-renter.app.link/onboarding';

const DownloadApp = () => {
  const { currentLease } = useRentersProfile();
  const [completeOnboardingMutation, { loading: completingOnboarding }] =
    useMutation(completeOnboardingGQL);

  const completeOnboarding = useCallback(async () => {
    await completeOnboardingMutation({
      variables: { leaseId: currentLease.id },
      refetchQueries: [{ query: basicUserProfile }],
      awaitRefetchQueries: true,
    });
  }, [currentLease.id]);

  const onGetTheApp = useCallback(() => {
    segmentTracking('mobile_app submit', {
      location: 'Tenant Onboarding Mobile App Page - Get App',
    });
    completeOnboarding();
    window.open(downloadAppLink, '_blank');
  }, [completeOnboarding]);

  const onSkipForNow = useCallback(() => {
    segmentTracking('mobile_app submit', {
      location: 'Tenant Onboarding Mobile App Page - Skip',
    });
    completeOnboarding();
  }, [completeOnboarding]);

  return (
    <>
      <OnboardingProgress step="Mobile App" progress={90} />

      <Card padded className={styles.container}>
        <Card.Content className={styles.content}>
          <img
            src={DownloadMobileAppGraphic}
            alt="Renters Insurance Promo Image"
            className={styles.placeholderImage}
          />
          <img
            src={DownloadMobileAppMobileGraphic}
            alt="Renters Insurance Promo Image"
            className={styles.placeholderMobileImage}
          />
          <H2 align="center" className="mb-4">
            Tap into your tenant portal from anywhere
          </H2>
          <Paragraph align="center" size="large" className="mb-24">
            Easily handle your renting needs on the go. Download the TurboTenant
            app on your phone.
          </Paragraph>
        </Card.Content>
        <Card.Footer>
          <Button
            variant="primary"
            className={styles.button}
            onClick={onGetTheApp}
            loading={completingOnboarding}
            to={linkTo(routes.tenantOnboardingStepSuccess)}
          >
            Get The App
          </Button>
          <FlatButton
            to={linkTo(routes.tenantOnboardingStepSuccess)}
            loading={completingOnboarding}
            onClick={onSkipForNow}
          >
            Skip for now
          </FlatButton>
        </Card.Footer>
      </Card>
    </>
  );
};
export default DownloadApp;
