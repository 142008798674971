import React from 'react';

import styles from './SignupForm.module.scss';

export const TermsPrivacy = () => {
  return (
    <div className={styles.termsPolicy}>
      By clicking the button above you are agreeing to our{' '}
      <div>
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/terms-of-use/"
        >
          Terms of Use
        </a>
        {' & '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/privacy-policy/"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
