import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextItalic = ({ color, ...props }) => (
  <svg viewBox="0 0 18 20" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="m15.096.45.039.019h2.003c.366 0 .662.318.662.709 0 .391-.296.709-.662.709l-2.192-.001-9.759 16.298h2.732c.366 0 .662.318.662.709 0 .391-.296.709-.662.709H.862c-.366 0-.662-.318-.662-.709 0-.391.296-.709.662-.709h2.903l9.759-16.298h-3.443c-.366 0-.662-.317-.662-.708 0-.391.296-.709.662-.709l4.431-.001a.756.756 0 0 1 .584-.018Z"
    />
  </svg>
);

TextItalic.defaultProps = {
  width: 18,
  height: 20,
  color: 'currentColor',
};

TextItalic.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TextItalic);
