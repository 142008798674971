import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './LogoGardenSidebarAnimatedMobile.module.scss';

const logogarden = [
  'logo-cnn.svg',
  'logo-fortune.svg',
  'logo-bhg.svg',
  'logo-realtor.svg',
  'logo-inc-5000.svg',
  'logo-cbs.svg',
  'logo-yahoo-finance.svg',
  'logo-nasdaq.svg',
  'logo-usnews.svg',
  'logo-benzinga.svg',
];

const logoGardenDir =
  'https://turbotenant-production.s3.us-east-1.amazonaws.com/uploads/assets/logogarden/';

const LogoGardenSidebarAnimatedMobile = ({ className, ...otherProps }) => {
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll(`.${styles.track} img`);
    let loadedCount = 0;

    images.forEach((img) => {
      img.onload = () => {
        loadedCount += 1;
        if (loadedCount === images.length) {
          setAllImagesLoaded(true);
        }
      };
      if (img.complete) {
        loadedCount += 1;
        if (loadedCount === images.length) {
          setAllImagesLoaded(true);
        }
      }
    });
  }, []);

  const repeatedLogos = Array(3).fill(logogarden).flat();

  return (
    <div className={cx(styles.container, className)} {...otherProps}>
      <h3>
        Trusted by 700,000+ landlords and 11 million renters across the U.S.
      </h3>
      <div className={styles.logoCarousel}>
        <div
          className={styles.track}
          style={{ visibility: allImagesLoaded ? 'visible' : 'hidden' }}
        >
          {repeatedLogos.map((logo, index) => (
            <img
              src={`${logoGardenDir}${logo}`}
              alt={`Logo ${index}`}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

LogoGardenSidebarAnimatedMobile.propTypes = {
  className: PropTypes.string,
};

export default LogoGardenSidebarAnimatedMobile;
