import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './SecurityDepositLawGA.module.scss';

function SecurityDepositLawGA() {
  return (
    <section>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Georgia Security Deposit Laws"
        color="iceCold"
        collapsable
      >
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The maximum security deposit you can collect is two month’s rent.
          </HelpfulInfoListItem>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </section>
  );
}

export default SecurityDepositLawGA;
