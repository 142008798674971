import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import Input from '../../../../../../../components/Input';
import InputWithIcon from '../../../../../../../components/InputWithIcon';
import { Paragraph } from '../../../../../../../components/Typography';
import DollarCircledIcon from '../../../../../../../icons/DollarCircled';
import PercentageIcon from '../../../../../../../icons/Percentage';
import amountGreaterThenZero from '../../../../../../../validators/amountGreaterThenZero';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import isLowerThan from '../../../../../../../validators/isLowerThan';
import isNumber from '../../../../../../../validators/isNumber';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './SecurityDepositInfo.module.scss';

function SecurityDepositInfo() {
  const { standAloneWizard } = useStandaloneWizardState();

  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <section className={styles.container}>
      <div className={styles.infoBlock}>
        <SectionTitle
          title="Bank Location, Information and Interest Rate"
          icon={DollarCircledIcon}
        />
        <label className={styles.label}>
          Where is your security deposit escrow account located (name of bank
          and address), account number, and what is the yearly interest rate?
        </label>
        <Paragraph className={styles.description}>
          NH law requires that all security deposits be placed in an
          interest-earning escrow account in a FDIC insured bank and the bank
          name, location, account number, amount of the security deposit and
          annual interest rate be disclosed.
        </Paragraph>
        <Field
          label="Bank Name"
          className={styles.inputContainer}
          inputProps={{
            className: styles.input,
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Account #"
          className={styles.inputContainer}
          inputProps={{
            className: styles.input,
          }}
          id="custom_state_data.bank_account_number"
          name="custom_state_data.bank_account_number"
          component={Input}
          validate={fieldRequiredShort}
        />

        <Field
          label="Interest Rate"
          className={styles.inputContainer}
          inputProps={{
            className: styles.smallInput,
          }}
          id="custom_state_data.interest_rate_account"
          name="custom_state_data.interest_rate_account"
          component={InputWithIcon}
          icon={PercentageIcon}
          iconRight
          validate={composeValidators(
            fieldRequired,
            isNumber,
            amountGreaterThenZero,
            isLowerThan(1000),
          )}
          type="number"
          step="1"
          pattern="[0-9]*"
          parse={(value) => (value ? parseFloat(value) : value)}
          format={(value) => (value ? parseFloat(value) : value)}
        />
      </div>
    </section>
  );
}
export default SecurityDepositInfo;
