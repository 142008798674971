import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Notice.module.scss';

const Notice = ({
  type,
  title,
  icon: Icon,
  iconProps,
  className,
  contentClassName,
  children,
  iconDirection = 'right',
}) => (
  <div className={cx(styles.notice, styles[`notice-${type}`], className)}>
    <div
      className={cx(
        {
          [styles.contentRight]: iconDirection === 'left',
        },
        styles.content,
        contentClassName,
      )}
    >
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.text}>{children}</div>
    </div>
    {Icon && (
      <div
        className={cx(styles.iconWrapper, {
          [styles.iconLeft]: iconDirection === 'left',
        })}
      >
        <Icon className={styles.icon} {...iconProps} />
      </div>
    )}
  </div>
);

Notice.propTypes = {
  type: PropTypes.oneOf(['info', 'warn', 'error']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  icon: PropTypes.object,
  iconProps: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
  }),
  iconDirection: PropTypes.string,
};

export default Notice;
