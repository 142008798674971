import { compile } from 'path-to-regexp';
import qs from 'query-string';

import { getConfig } from '../../services/configService';

export { routes } from './routes';

export const linkTo = (
  pathname,
  params = {},
  queryParams = null,
  encode = true,
) => {
  const { PRIVATE_BASE_PATH } = getConfig();
  if (!pathname) {
    throw new Error('Pathname is required');
  }

  const stringifiedQP = queryParams
    ? `?${qs.stringify(queryParams, { encode })}`
    : '';
  return `${PRIVATE_BASE_PATH.slice(0, -1)}${compile(pathname)(
    params,
  )}${stringifiedQP}`;
};
