import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Accept = ({ color, ...rest }) => (
  <svg {...rest} viewBox="0 0 17 12" fill={color}>
    <path
      fillRule="evenodd"
      d="m4.657 9.132 9.438-8.81a1.2 1.2 0 1 1 1.638 1.755l-10.286 9.6a1.2 1.2 0 0 1-1.667-.028L.351 8.22A1.2 1.2 0 0 1 2.05 6.523l2.608 2.609Z"
      clipRule="evenodd"
    />
  </svg>
);

Accept.defaultProps = {
  width: 17,
  height: 12,
  color: colors.primary,
};

Accept.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Accept);
