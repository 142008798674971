import React, { memo } from 'react';
import PropTypes from 'prop-types';

const RevertColored = ({ color, arrowFill, ...props }) => (
  <svg viewBox="0 0 64 64" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h64v64H0z" />
      <g fillRule="nonzero">
        <path
          fill={arrowFill}
          d="M4.333 17.658c0-2.359 2.83-3.553 4.493-1.879l5.986 6.026c16.792-.314 28.3 4.417 34.521 14.195-3.235-2.225-9.262-1.404-18.08 2.464l-.034.015 5.913 5.993c1.6 1.622.547 4.36-1.663 4.52l-.204.008H6.966c-1.457 0-2.633-1.192-2.633-2.655V17.658Z"
        />
        <path
          fill={color}
          d="M4.333 17.658c0-2.359 2.83-3.553 4.493-1.879l5.986 6.026.224-.065c26.076-7.586 44.26 1.525 44.625 23.915l.006.69v1H54.4v-1c0-10.482-9.92-13.682-23.148-7.881l-.034.015 5.913 5.993c1.6 1.622.547 4.36-1.663 4.52l-.204.008H6.966c-1.457 0-2.633-1.192-2.633-2.655V17.658Zm3.074-.47c-.398-.4-1.074-.114-1.074.47v28.687c0 .365.287.655.633.655h28.3c.561 0 .85-.709.442-1.123l-6.916-7.01-.987-1.001 1.27-.605c14.558-6.93 26.585-3.79 27.293 7.968l.005.115h1.279l-.006-.278C56.981 24.637 40.274 16.47 15.58 23.664l-.766.227-.575.174-.424-.426-6.408-6.45Z"
        />
      </g>
    </g>
  </svg>
);

RevertColored.defaultProps = {
  width: 64,
  height: 64,
  color: 'currentColor',
  arrowFill: '#7FE3FF',
};

RevertColored.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(RevertColored);
