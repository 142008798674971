import React, { useEffect, useState } from 'react';

import { H2, Paragraph } from '../../../../components/Typography';
import { getItem } from '../../../../services/localStorageService';

import styles from './Gt3pSignupOnboardingExperiment.module.scss';

const Gt3pSignupOnboardingExperimentSidebarAnimated = () => {
  const savedData = JSON.parse(getItem('ADDITIONAL_SIGNUP_DATA') || {});

  const amountPerUnit = 2400;
  const units = Number(savedData.properties) || 0;
  let totalAmount = units * amountPerUnit;

  totalAmount = totalAmount >= 24000 ? 24000 : totalAmount;

  const [animatedAmount, setAnimatedAmount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000;
    const increment = totalAmount / (duration / 16);

    const animate = () => {
      start += increment;
      if (start >= totalAmount) {
        setAnimatedAmount(totalAmount);
        return;
      }
      setAnimatedAmount(Math.floor(start));
      requestAnimationFrame(animate);
    };

    animate();
  }, [totalAmount]);

  return (
    <div className={styles.gt3pSignupOnboardingExperimentSidebarContainer}>
      <H2 className={styles.gt3pSignupOnboardingExperimentSidebarTitle}>
        By self-managing {units} units, save at least
      </H2>
      <H2
        className={styles.gt3pSignupOnboardingExperimentSidebarAmountAnimated}
      >
        ${animatedAmount.toLocaleString()}
        <span className={styles.gt3pSignupOnboardingExperimentSidebarYear}>
          /YEAR
        </span>
      </H2>
      <H2 className={styles.gt3pSignupOnboardingExperimentSidebarTitle}>
        TurboTenant gives you powerful tools <br />
        to get the job done – all in one place <br />
        and at a fraction of the cost.
      </H2>
      <Paragraph
        className={styles.gt3pSignupOnboardingExperimentSidebarSmallText}
      >
        Based on average US house rental with 10% property manager fees.
      </Paragraph>
    </div>
  );
};

export default Gt3pSignupOnboardingExperimentSidebarAnimated;
