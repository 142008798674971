const editorFontSizes = [
  {
    value: '8',
    label: '8',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '12',
    label: '12',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '16',
    label: '16',
  },
  {
    value: '18',
    label: '18',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '22',
    label: '22',
  },
  {
    value: '24',
    label: '24',
  },
  {
    value: '26',
    label: '26',
  },
  {
    value: '28',
    label: '28',
  },
  {
    value: '30',
    label: '30',
  },
];

const editorBlockTypes = [
  {
    value: 'p',
    label: 'Normal',
  },
  {
    value: 'h1',
    label: 'Heading 1',
  },
  {
    value: 'h2',
    label: 'Heading 2',
  },
  {
    value: 'h3',
    label: 'Heading 3',
  },
  {
    value: 'h4',
    label: 'Heading 4',
  },
  {
    value: 'h5',
    label: 'Heading 5',
  },
  {
    value: 'h6',
    label: 'Heading 6',
  },
];

export { editorFontSizes, editorBlockTypes };
