export const LateFeeUnitEnum = {
  FLAT: 'FLAT',
  PERCENTAGE: 'PERCENTAGE',
  PERCENTAGE_OF_UNPAID: 'PERCENTAGE_OF_UNPAID',
};

export const LateFeeLimitTypeEnum = {
  MAX_DAYS: 'MAX_DAYS',
  MAX_TOTAL_FEE_AMOUNT: 'MAX_TOTAL_FEE_AMOUNT',
  NO_LIMIT: 'NO_LIMIT', // this one is never sent to the BE
};

export const lateFeeOptions = [
  { label: 'Flat', value: LateFeeUnitEnum.FLAT },
  { label: '% Rent', value: LateFeeUnitEnum.PERCENTAGE },
  { label: '% Unpaid', value: LateFeeUnitEnum.PERCENTAGE_OF_UNPAID },
];

export const lateFeesLimitOptions = [
  { label: 'Flat', value: LateFeeUnitEnum.FLAT },
  { label: '% Rent', value: LateFeeUnitEnum.PERCENTAGE },
];
