import React from 'react';

import { H2, Paragraph } from '../../../../components/Typography';

import styles from './Gt3pSignupOnboardingExperiment.module.scss';

const Gt3pSignupOnboardingExperimentTitle = () => {
  return (
    <div className={styles.gt3pSignupOnboardingExperimentTitleContainer}>
      <H2 className={styles.gt3pSignupOnboardingExperimentTitle}>
        Better than a property manager
      </H2>
      <Paragraph className={styles.gt3pSignupOnboardingExperimentSubtitle}>
        Skip the middle man and manage your rentals smarter <br /> with
        TurboTenant.
      </Paragraph>
      <Paragraph className={styles.gt3pSignupOnboardingExperimentSubtitleBold}>
        Start saving and sign up now.
      </Paragraph>
    </div>
  );
};

export default Gt3pSignupOnboardingExperimentTitle;
