import React, { useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import validateEmailSendgrid from '../../helpers/validateEmailSendgrid';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import validEmail from '../../validators/validEmail';
import validName from '../../validators/validName';
import validPasswordStrength from '../../validators/validPasswordStrength';
import validPhone from '../../validators/validPhone';
import Button from '../Button';
import { CalloutWarning } from '../Callout/Callout.stories';
import FinalFormError from '../FinalFormError';
import FlatButton from '../FlatButton';
import IconTooltip from '../IconTooltip/IconTooltip';
import Input from '../Input';
import trimEmail from '../Input/trimEmail';
import PasswordInput from '../PasswordInput';
import PhoneField from '../PhoneField';
import RadioGroup from '../RadioGroup';
import Stepper from '../Stepper';

import styles from './SignupForm.module.scss';

const SignupForm = ({
  onSubmit,
  className,
  onboarding_personalize_flow,
  mainFields,
  onboardingExperimentVariant,
}) => {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const emailChangeApplied = useRef(false);
  const emailRef = useRef();
  const emailsChecked = useRef({});

  const handleEmailValidation = async (email, submitValues) => {
    let result;
    if (emailsChecked.current[email]) {
      result = emailsChecked.current[email];
    } else {
      result = await validateEmailSendgrid(email);
      emailsChecked.current[email] = result;
    }
    if (result.suggestion) {
      setEmailSuggestion(result.suggestion);
    }

    setShowSuggestion(!!result.suggestion);

    if (submitValues && !result.suggestion) {
      return await onSubmit(submitValues);
    }
  };

  const applySuggestion = (form, currentEmail) => {
    const userPart = currentEmail.split('@')[0];

    const newEmail = `${userPart}@${emailSuggestion}`;
    form.change('email', newEmail);
    setShowSuggestion(false);
    emailChangeApplied.current = newEmail;
  };
  return (
    <Form
      onSubmit={async (values) => {
        if (showSuggestion || emailChangeApplied?.current) {
          return await onSubmit(values);
        }
        return await handleEmailValidation(values.email, values);
      }}
      subscription={{
        submitting: true,
        values: true,
        active: true,
      }}
    >
      {({ handleSubmit, submitting, values, form }) => (
        <form
          onSubmit={handleSubmit}
          className={cx(styles.signUpContainer, className)}
        >
          <FinalFormError />
          <div className={styles.row}>
            <Field
              label="First Name"
              component={Input}
              name="first_name"
              id="firstname"
              className={styles.name}
              validate={composeValidators(fieldRequired, validName)}
            />
            <Field
              label="Last Name"
              component={Input}
              name="last_name"
              id="lastname"
              className={styles.name}
              validate={composeValidators(fieldRequired, validName)}
            />
          </div>
          <div className={styles.row}>
            <Field
              label="Email"
              component={Input}
              type="email"
              name="email"
              id="email"
              className={styles.email}
              parse={trimEmail}
              validate={composeValidators(validEmail, fieldRequired)}
              inputProps={{
                autoComplete: 'username',
              }}
              inputRef={emailRef}
            />
          </div>
          <OnChange name="email">
            {(value) => {
              if (value) {
                setShowSuggestion(false);
                if (emailChangeApplied.current !== value) {
                  emailChangeApplied.current = null;
                }
              }
            }}
          </OnChange>
          <OnBlur name="email">
            {() => {
              const value = emailRef?.current?.value;
              const emailFieldState = form.getFieldState('email');
              if (value && emailFieldState?.valid) {
                handleEmailValidation(value);
              }
            }}
          </OnBlur>

          <div>
            {showSuggestion && (
              <CalloutWarning type="warning" className={styles.suggestedEmail}>
                <span>
                  Did you mean{' '}
                  {`${values?.email?.split('@')[0]}@${emailSuggestion}`}?{' '}
                  <FlatButton
                    onClick={() => applySuggestion(form, values.email)}
                  >
                    Use Suggested Email
                  </FlatButton>
                </span>
              </CalloutWarning>
            )}
          </div>
          {onboardingExperimentVariant ===
            Experiments.GtOnboardingPhoneNumber.variants.variant_1 && (
            <div className={styles.column}>
              <Field
                label="Phone (Optional)"
                component={PhoneField}
                name="telephone"
                id="telephone"
                validate={composeValidators(validPhone)}
                className={styles.name}
              />
            </div>
          )}
          <div className={styles.column}>
            <Field
              label="Password"
              component={PasswordInput}
              name="password"
              id="password"
              validate={composeValidators(fieldRequired, validPasswordStrength)}
              className={styles.password}
              inputProps={{
                autoComplete: 'new-password',
              }}
              showPasswordStrengthChecker
            />
          </div>
          {!(onboarding_personalize_flow || mainFields) && (
            <>
              <div className={styles.row}>
                <Field
                  component={RadioGroup}
                  id="landlord_signup_description"
                  name="signupDescription"
                  label="Which best describes you?"
                  options={[
                    {
                      label: 'I manage my own rental(s)',
                      value: 'MY_RENTALS',
                    },
                    {
                      label: 'I manage rentals for others',
                      value: 'OTHERS_RENTALS',
                    },
                    {
                      label: 'I manage a mix of both',
                      value: 'BOTH',
                    },
                    {
                      label: "I don't manage any rentals yet",
                      value: 'DONT_MANAGE_RENTALS',
                    },
                    {
                      value: 'BROKER',
                      label: (
                        <span className={styles.dwellingOption}>
                          I’m an apartment or rental broker
                          <IconTooltip
                            tip={
                              <span>
                                They serve as the go-between for property
                                managers and potential tenants. They do not
                                actively manage rentals.
                              </span>
                            }
                          />
                        </span>
                      ),
                    },
                  ]}
                  className={styles.landlordDescriptionContainer}
                  radioRowClassName={styles.radioRowClassName}
                  validate={fieldRequired}
                />
              </div>
              <div className={styles.row}>
                <Field
                  label="How many properties do you own/manage?"
                  component={Stepper}
                  small
                  name="properties"
                  id="propertiesCount"
                  className={styles.stepper}
                  validate={fieldRequired}
                />
              </div>
            </>
          )}

          <Button
            className={styles.submit}
            type="submit"
            loading={submitting}
            id="signup_submit"
            data-qa="signup-form-submit"
          >
            Sign up
          </Button>
        </form>
      )}
    </Form>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  onboarding_personalize_flow: PropTypes.bool,
  mainFields: PropTypes.bool,
  onboardingExperimentVariant: PropTypes.string,
};

export default SignupForm;
