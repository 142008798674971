import React from 'react';

import InformationCircleFilled from '../../icons/InformationCircleFilled';
import NewWindow from '../../icons/NewWindow';
import { segmentTracking } from '../../services/utilities';
import { H2, Link, Paragraph } from '../Typography';

import styles from './Scheduler.module.scss';

const Scheduler = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <InformationCircleFilled />
        <H2 className={styles.title}> Get your account set up faster </H2>
      </div>
      <Paragraph className={styles.description}>
        Since you manage multiple properties, you qualify for a free onboarding
        call with our Colorado-based success team. We'll help you organize your
        workflow!
      </Paragraph>
      <Link
        onClick={() => {
          segmentTracking('schedule_a_call clicked', {
            location: 'Onboarding',
          });
        }}
        href="https://calendly.com/d/cnfg-v2x-jqv/turbotenant-onboarding?month=2024-11"
        target="_blank"
        className={styles.link}
      >
        <div className={styles.linkRow}>
          SCHEDULE A CALL WITH US
          <NewWindow className={styles.icon} width={24} height={24} />
        </div>
      </Link>
    </div>
  );
};

export default Scheduler;
