import React from 'react';

import Dropdown from '../../../../components/Dropdown';
import OrderedList from '../../../../icons/OrderedList';
import Redo from '../../../../icons/Redo';
import TextBold from '../../../../icons/TextBold';
import TextItalic from '../../../../icons/TextItalic';
import TextJustifyCenter from '../../../../icons/TextJustifyCenter';
import TextJustifyLeft from '../../../../icons/TextJustifyLeft';
import TextJustifyRight from '../../../../icons/TextJustifyRight';
import TextStrikethrough from '../../../../icons/TextStrikethrough';
import TextUnderline from '../../../../icons/TextUnderline';
import Undo from '../../../../icons/Undo';
import UnorderedList from '../../../../icons/UnorderedList';
import LeaseAgreementEditorButton from '../LeaseAgreementEditorButton';
import {
  editorBlockTypes,
  editorFontSizes,
} from '../leaseAgreementEditorContants';

import styles from './LeaseAgreementEditorToolbar.module.scss';

const LeaseAgreementEditorToolbar = () => {
  const updateBlockType = (val) => {
    // Clear any inline formatting before applying the new block type
    document.execCommand('removeFormat', false, null);

    // Apply the new block type
    document.execCommand('formatBlock', false, `<${val}>`);
  };

  const updateFontSize = (val) => {
    document.execCommand('fontSize', false, '7');

    const fontElements = document.getElementsByTagName('font');

    for (let i = 0, len = fontElements.length; i < len; ++i) {
      if (fontElements[i].size === '7') {
        fontElements[i].removeAttribute('size');
        fontElements[i].style.fontSize = `${val}px`;
      }
    }
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="bold"
          icon={TextBold}
          iconProps={{ width: 14, height: 19 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="italic"
          icon={TextItalic}
          iconProps={{ width: 18, height: 19 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="underline"
          icon={TextUnderline}
          iconProps={{ width: 20, height: 19 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="strikethrough"
          icon={TextStrikethrough}
          iconProps={{ width: 19, height: 20 }}
        />
        <div className={styles.verticalLine} />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="justifyLeft"
          icon={TextJustifyLeft}
          iconProps={{ width: 19, height: 18 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="justifyCenter"
          icon={TextJustifyCenter}
          iconProps={{ width: 19, height: 18 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="justifyRight"
          icon={TextJustifyRight}
          iconProps={{ width: 19, height: 18 }}
        />
        <div className={styles.verticalLine} />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="insertOrderedList"
          icon={OrderedList}
          iconProps={{ width: 19, height: 18 }}
        />
        <LeaseAgreementEditorButton
          className={styles.toolbarIcon}
          cmd="insertUnorderedList"
          icon={UnorderedList}
          iconProps={{ width: 19, height: 16 }}
        />
        <div className={styles.verticalLine} />
        <Dropdown
          items={editorBlockTypes}
          onSelect={updateBlockType}
          buttonClassName={styles.dropdownButton}
          buttonLabel="Select block"
        />
        <Dropdown
          items={editorFontSizes}
          onSelect={updateFontSize}
          buttonClassName={styles.dropdownButton}
          buttonLabel="Select font"
        />
      </div>
      <div className={styles.toolbarRight}>
        <LeaseAgreementEditorButton
          cmd="undo"
          icon={Undo}
          iconProps={{ width: 18, height: 19 }}
        />
        <LeaseAgreementEditorButton
          cmd="redo"
          icon={Redo}
          iconProps={{ width: 18, height: 19 }}
        />
      </div>
    </div>
  );
};

export default LeaseAgreementEditorToolbar;
