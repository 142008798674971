import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import UnsavedChangesPrompt from '../../../../digital-leases/standalone-wizard/UnsavedChangesPrompt';

import ChargesFirstForm from './ChargesFirstForm';
import useChargesFirst from './useChargesFirst';

const ChargesFirst = ({ isPremiumUser, bankAccountOptions, isEdit }) => {
  const { state, ...hooks } = useChargesFirst();
  return (
    <>
      <UnsavedChangesPrompt when={state.dirty} />
      <Prompt
        when={state.dirty}
        message="Changes that you made may not be saved."
      />

      <ChargesFirstForm
        isPremiumUser={isPremiumUser}
        bankAccountOptions={bankAccountOptions}
        state={state}
        isEdit={isEdit}
        {...hooks}
      />
    </>
  );
};

ChargesFirst.propTypes = {
  isPremiumUser: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  bankAccountOptions: PropTypes.array.isRequired,
};

export default ChargesFirst;
