import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CreditCardAmex from '../../../../icons/CreditCardAmex';
import CreditCardDiscover from '../../../../icons/CreditCardDiscover';
import CreditCardMasterCard from '../../../../icons/CreditCardMasterCard';
import CreditCardVisa from '../../../../icons/CreditCardVisa';

import styles from './PaymentCardsList.module.scss';

const PaymentCardsList = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.card}>
      <CreditCardVisa />
    </div>
    <div className={styles.card}>
      <CreditCardAmex />
    </div>
    <div className={styles.card}>
      <CreditCardDiscover />
    </div>
    <div className={styles.card}>
      <CreditCardMasterCard />
    </div>
  </div>
);

PaymentCardsList.propTypes = {
  className: PropTypes.string,
};

export default PaymentCardsList;
