import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import LoadingScreen from '../../../components/LoadingScreen';
import MaintenanceForm from '../../../components/Maintenance/MaintenanceForm';
import { useLeases, useUserProfile } from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import listingQuery from '../../../graphql/queries/listingQuery.graphql';
import { removeFromApolloCache } from '../../../services/utilities';
import editMaintenanceRequest from '../graphql/editMaintenanceRequest.graphql';

import styles from './EditMaintenancePage.module.scss';

const EditMaintenancePage = ({ className, history, match }) => {
  const requestId = get(match, 'params.maintenanceRequestId', '');
  const listingId = get(match, 'params.listingGlobalId', '');

  if (!requestId || !listingId) {
    history.goBack();
  }

  const [editMaintenance] = useMutation(editMaintenanceRequest, {
    refetchQueries: [
      { query: listingQuery, variables: { id: listingId } },
      { query: basicUserProfile },
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(
        ['maintenanceRequests', 'leases', 'tenants'],
        cache,
      );
    },
  });

  const { user, loading: loadingUser } = useUserProfile({
    polling: false,
  });

  const mainApproverPhone = get(
    user,
    'maintenance_request_management_settings.approvers[0].phone',
    user?.telephone,
  );
  const { maintenance_plus_subscription_subscribed: maintenancePlusActive } =
    user || {};

  const { data: listingData, loading: loadingListing } = useQuery(
    listingQuery,
    {
      variables: {
        id: listingId,
      },
    },
  );
  const { leases = [], loading: loadingLeases } = useLeases();

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  if (loadingListing || loadingLeases || loadingUser) {
    return <LoadingScreen loading={loadingListing} />;
  }

  const maintenanceRequests = get(listingData, 'node.maintenance_requests', []);

  const currentRequest = maintenanceRequests.find(
    (request) => request.id === requestId,
  );

  const getInitialValues = () => {
    const { title, category, description, preferred_time } = currentRequest;
    const lease_id = get(currentRequest, 'lease.id', undefined);
    const renter_id = get(currentRequest, 'renter.id', undefined);
    const attachments = get(
      currentRequest,
      'maintenance_request_attachments',
      [],
    );

    return {
      lease_id,
      renter_id,
      title,
      category,
      description,
      preferred_time,
      attachments,
      contact_phone_number: get(currentRequest, 'renter.telephone', ''),
    };
  };

  const onSubmit = async (data) => {
    // Check if renterId is "NONE" and delete it from data if true
    // We should remove this once we releae the feature
    // And clean this code
    if (data.renterId === 'NONE') {
      delete data.renterId;
      delete data.contact_phone_number;
    }

    let submitData = {
      id: requestId,
      ...data,
      attachments: (data.attachments || []).map((a) => ({
        id: a.id,
      })),
    };
    if (!('lease_id' in data)) {
      submitData = {
        ...submitData,
        lease_id: null,
      };
    }
    try {
      const { data: response } = await editMaintenance({
        variables: { ...submitData },
      });
      const {
        editMaintenanceRequest: { maintenance_request },
      } = response;
      // We have ID for redirect here maintenance_request.id
      console.log(maintenance_request, 'maintenance_request');
      history.goBack();
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.back)}>
        <BackButton />
      </div>
      <MaintenanceForm
        initialValues={getInitialValues()}
        isEdit={true}
        leases={leases}
        listing={listingData.node}
        onSubmit={onSubmit}
        maintenancePlusActive={maintenancePlusActive || isEnrolledFreeLula}
        mainApproverPhone={mainApproverPhone || user?.telephone}
      />
    </div>
  );
};

EditMaintenancePage.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(EditMaintenancePage);
