import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MinusCircle from '../../icons/MinusCircle';
import PlusCircle from '../../icons/PlusCircle';
import { segmentTracking } from '../../services/utilities';
import Button from '../Button';
import FlatButton from '../FlatButton';
import PlainInput from '../PlainInput';

import styles from './SmallStepper.module.scss';

const clamp = (value, min, max) => {
  if (value === '' || value === null) {
    return value;
  }
  return Math.round(Math.min(Math.max(value, min), max));
};

const SmallStepper = ({
  initialValue,
  min,
  max,
  onSave,
  className,
  disabled,
}) => {
  const [localValue, setLocalValue] = useState(initialValue);

  const handleIncrement = () => {
    setLocalValue((prevValue) => clamp(prevValue + 1, min, max));
    segmentTracking('plus clicked', {
      location: 'Update Unit Count Modal',
    });
  };

  const handleDecrement = () => {
    setLocalValue((prevValue) => clamp(prevValue - 1, min, max));
    segmentTracking('minus clicked', {
      location: 'Update Unit Count Modal',
    });
  };

  const handleInputChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue)) {
      setLocalValue(clamp(inputValue, min, max));
    }
  };

  return (
    <div className={cx(styles.input, styles.small, className)}>
      <div className={styles.content}>
        <FlatButton
          onClick={handleDecrement}
          icon={MinusCircle}
          disabled={disabled || localValue === min || localValue === ''}
          iconProps={{ className: styles.icon }}
        />
        <PlainInput
          className={cx(styles.field)}
          type="number"
          value={localValue}
          onChange={handleInputChange}
        />
        <FlatButton
          onClick={handleIncrement}
          disabled={localValue >= max}
          icon={PlusCircle}
          iconProps={{ className: styles.icon }}
        />
      </div>
      <div className={styles.btnWrp}>
        <Button
          primary
          className={styles.saveButton}
          onClick={() => onSave(localValue)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

SmallStepper.propTypes = {
  initialValue: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SmallStepper.defaultProps = {
  min: 0,
  max: 999,
  className: '',
};

export default SmallStepper;
