import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import useWindowSize from '@rooks/use-window-size';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import DownloadFormModal from '../../../components/DownloadFormModal';
import FormsEditor from '../../../components/FormsEditor';
import LoadingScreen from '../../../components/LoadingScreen';
import SaveFormDocumentModal from '../../../components/SaveFormDocumentModal';
import { midScreenWidth } from '../../../constants/media-breakpoints';
import saveEditedFormMutation from '../../../graphql/mutations/forms/saveEditedForm.graphql';
import landlordFormsQuery from '../../../graphql/queries/landlordForms.graphql';
import leasesQuery from '../../../graphql/queries/leasesQuery.graphql';
import { getFormsDownloadableFormats } from '../../../helpers/getFormsDownloadableFormats';
import { useRenderOutside } from '../../../helpers/render-outside';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import FormsEditPageLocked from './FormsEditPageLocked';

import styles from './FormsEditPage.module.scss';

const FormsEditPage = ({ match, history }) => {
  const originalFormId = get(match, 'params.originalFormId', '');
  const { data: formsData, loading: loadingForms } =
    useQuery(landlordFormsQuery);
  const { data: leasesData, loading: loadingLeases } = useQuery(leasesQuery);
  const [saveEditedForm] = useMutation(saveEditedFormMutation);
  const [skipLeaveCheck, setSkipLeaveCheck] = useState(false);

  const { PRIVATE_BASE_PATH } = useConfig();
  const formsIndexPage = `${PRIVATE_BASE_PATH}forms`;

  const editorRef = useRef(null);
  const { innerWidth } = useWindowSize();
  const isMobileOrTablet = innerWidth < midScreenWidth;
  const renderOutside = useRenderOutside();

  const forms = formsData?.viewer?.forms || [];
  const currentForm = forms.find((form) => form.id === originalFormId);

  const leases = (get(leasesData, 'userData.leases.data') || []).map(
    (lease) => ({
      id: lease.id,
      title: lease.title,
      hasRenters: !!get(lease, 'renters.length', 0),
    }),
  );

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  if (forms?.length && !currentForm) {
    history.replace(formsIndexPage);
    return null;
  }

  const handleSubmit = async (formData) => {
    const currentHtml = editorRef.current?.getCurrentHtml();

    if (currentHtml) {
      try {
        setSkipLeaveCheck(true);

        await saveEditedForm({
          variables: {
            leaseId: formData.leaseId,
            html: currentHtml,
            title: formData.title,
            share: formData.shareDocument,
            originalFormId,
          },
        });

        history.replace(`${PRIVATE_BASE_PATH}leases/view/${formData.leaseId}`);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const handleRevert = async () => {
    // TODO: Implement revert logic
    console.log('Reverting changes');
  };

  const onBackClick = () => {
    history.replace(formsIndexPage);
  };

  const onDownloadOriginal = () => {
    setDownloadModalOpen(true);
  };

  const handleDownloadClicked = (formatClicked) => {
    const eventName = `download_${currentForm.title.toLowerCase().replace(/ /g, '_')} clicked`;

    segmentTracking(eventName, {
      location: 'Forms Editor',
      properties: formatClicked,
    });
  };

  if (loadingForms || loadingLeases) {
    return <LoadingScreen loading />;
  }

  if (isMobileOrTablet) {
    return (
      <FormsEditPageLocked
        documentName={currentForm.title}
        onDownloadOriginal={onDownloadOriginal}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={onBackClick} />
        <Button
          className={styles.btn}
          onClick={() => {
            renderOutside((done) => (
              <SaveFormDocumentModal
                open
                onClose={() => {
                  done();
                }}
                onSave={async (formData) => {
                  await handleSubmit(formData);
                  done();
                }}
                documentName={currentForm.title}
                leases={leases}
              />
            ));
          }}
        >
          Next
        </Button>
      </div>
      <div className={styles.formsEditorWrapper}>
        <FormsEditor
          className={styles.editor}
          initialHtml={currentForm.html_content}
          history={history}
          documentName={currentForm.title}
          onRevert={handleRevert}
          onDownloadOriginal={onDownloadOriginal}
          editorRef={editorRef}
          skipLeaveCheck={skipLeaveCheck}
        />
      </div>
      <DownloadFormModal
        open={downloadModalOpen}
        formats={getFormsDownloadableFormats(currentForm?.formats)}
        onClose={() => setDownloadModalOpen(false)}
        onDownloadClicked={handleDownloadClicked}
      />
    </div>
  );
};

FormsEditPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default FormsEditPage;
