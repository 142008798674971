import React from 'react';
import { Field, useForm } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getCheckboxError from '../../validators/get-checkbox-error';
import Checkbox from '../Checkbox';
import Input from '../Input';
import Label from '../Label';
import SectionTitleWithIcon from '../SectionTitleWithIcon';

import styles from './CheckboxGroupFields.module.scss';

const CheckboxGroupFields = ({
  className,
  options,
  checkboxProps,
  style,
  label,
  labelProps,
  checkboxesClassName,
  id,
  name,
  validate,
  icon: Icon,
  iconTitleClassNames,
  controlled,
}) => {
  const form = useForm();

  return (
    <div className={cx(styles.wrap, className)} style={style}>
      {Icon ? (
        <SectionTitleWithIcon
          className={iconTitleClassNames}
          centered={false}
          title={label}
          icon={Icon}
          {...(labelProps || {})}
        />
      ) : (
        label && (
          <Label className={styles.label} {...(labelProps || {})} htmlFor={id}>
            {label}
          </Label>
        )
      )}
      <div className={cx(styles.container, checkboxesClassName)}>
        {options.map((option, index) => {
          const shouldShowAdditionalInput =
            option.additionalInput &&
            form.getState().values?.[id]?.includes?.(option.value);

          return (
            <React.Fragment key={option.value}>
              <Field
                component={Checkbox}
                type="checkbox"
                name={name}
                id={`${id}-${option.id}`}
                key={option.value}
                value={option.value}
                label={option.label}
                disabled={option.disabled}
                {...checkboxProps}
                {...(option.checked !== undefined
                  ? { checked: option.checked }
                  : {})}
                controlled={controlled}
                className={cx(styles.checkbox, checkboxProps.className)}
                data-qa={`input-${id}-${option.id}`}
                ignoreError
              />

              {shouldShowAdditionalInput ? (
                <>
                  {option.additionalInput.label && (
                    <Label
                      className={styles.label}
                      hint={option.additionalInput.isOptional && '(Optional)'}
                      htmlFor={id}
                    >
                      {option.additionalInput.label}
                    </Label>
                  )}
                  <Field
                    component={Input}
                    className={cx({
                      [styles.lastAdditionalInput]:
                        index === options.length - 1,
                    })}
                    as={option.additionalInput.as || 'textarea'}
                    id={option.additionalInput.id}
                    value={option.additionalInput.value}
                    name={option.additionalInput.name}
                    validate={option.additionalInput.validate}
                    inputProps={{
                      autoFocus: true,
                      rows: 3,
                      placeholder: option.additionalInput.placeholder || '',
                      ...option.additionalInput.inputProps,
                    }}
                    data-qa={`input-${id}-${option.additionalInput.id}`}
                  />
                </>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
      <Field
        render={({ meta }) => {
          const error = getCheckboxError(meta);
          if (!error) {
            return null;
          }
          return <span className={styles.error}>{error}</span>;
        }}
        name={name}
        validate={validate}
      />
    </div>
  );
};

CheckboxGroupFields.propTypes = {
  style: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.object,
  checkboxesClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      additionalInput: PropTypes.shape({
        value: PropTypes.any,
        id: PropTypes.string,
        name: PropTypes.string,
        as: PropTypes.oneOf(['input', 'textarea']),
        placeholder: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        isOptional: PropTypes.bool,
        validate: PropTypes.func,
      }),
    }),
  ).isRequired,
  checkboxProps: PropTypes.object,
  validate: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  iconTitleClassNames: PropTypes.string,
  controlled: PropTypes.bool,
};

CheckboxGroupFields.defaultProps = {
  checkboxProps: {},
  controlled: false,
};

export default CheckboxGroupFields;
