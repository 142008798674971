import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import rentalRequestQuery from '../pages/applications/graphql/rentalRequest.graphql';

const useApplicationFetcher = () => {
  return useCallback(({ applicationId, skip }) => {
    const { data, loading } = useQuery(rentalRequestQuery, {
      variables: {
        id: applicationId,
      },
      skip,
    });

    return { data: data?.node, loading };
  });
};

export default useApplicationFetcher;
