import { useCallback, useReducer } from 'react';
import { v4 as uuid } from 'uuid';

import { segmentTracking } from '../../../../../services/utilities';
import isUuid from '../../../../../services/utilities/isUuid';

import chargesFirstReducer, {
  addMonthlyCharge,
  addOneTimeCharge,
  editMonthlyCharge,
  editOneTimeCharge,
  initData,
  removeMonthlyCharge,
  removeOneTimeCharge,
  saveData,
  updateListing,
} from './reducer';

const useChargesFirst = () => {
  const [state, dispatch] = useReducer(chargesFirstReducer, initData);

  const onUpdateListing = useCallback((listing) => {
    dispatch(updateListing(listing));
  }, []);

  const onAddMonthlyCharge = useCallback(({ amount, ...charge }) => {
    dispatch(
      addMonthlyCharge({ id: uuid(), amount: Number(amount), ...charge }),
    );
  }, []);

  const onAddOneTimeCharge = useCallback(({ amount, ...charge }) => {
    dispatch(
      addOneTimeCharge({ id: uuid(), amount: Number(amount), ...charge }),
    );
  }, []);

  const onEditMonthlyCharge = useCallback((charge) => {
    dispatch(editMonthlyCharge(charge));
  }, []);

  const onRemoveMonthlyCharge = useCallback(({ id }) => {
    dispatch(removeMonthlyCharge(id));
  }, []);

  const onEditOneTimeCharge = useCallback((charge) => {
    dispatch(editOneTimeCharge(charge));
  }, []);

  const onRemoveOneTimeCharge = useCallback(({ id }) => {
    dispatch(removeOneTimeCharge(id));
  }, []);

  const onSave = useCallback(() => {
    segmentTracking('property_and_charges_step submit', {
      location: 'rp onboarding property and charges step',
    });
    const data = {
      ...state,
      monthlyCharges: state.monthlyCharges.map((charge) => {
        const { id, ...rest } = charge;
        if (isUuid(id)) {
          return rest;
        }
        return charge;
      }),
      oneTimeCharges: state.oneTimeCharges.map((charge) => {
        const { id, ...rest } = charge;
        if (isUuid(id)) {
          return rest;
        }
        return charge;
      }),
    };
    console.info('Form data', data);
    alert('Data saved! Check console tab');
    dispatch(saveData());
  }, [state]);

  return {
    state,
    onUpdateListing,
    onAddMonthlyCharge,
    onEditMonthlyCharge,
    onRemoveMonthlyCharge,
    onAddOneTimeCharge,
    onEditOneTimeCharge,
    onRemoveOneTimeCharge,
    onSave,
  };
};

export default useChargesFirst;
