import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import LoadingScreen from '../../../components/LoadingScreen';
import Modal from '../../../components/Modal';
import RevertColoredIcon from '../../../icons/RevertColored';

import styles from './FormsEditorLeaveModal.module.scss';

const FormsEditorLeaveModal = ({
  className,
  containerClassName,
  open,
  loading,
  onGoBack,
  onRevert,
}) => {
  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onGoBack}
      showCloseIcon
    >
      <LoadingScreen loading={loading}>
        <div className={styles.iconWrapper}>
          <RevertColoredIcon />
        </div>
        <h2 className={styles.title}>Are you sure you want to leave?</h2>
        <p className={styles.text}>All changes made will be lost.</p>
        <div className={styles.btnWrapper}>
          <Button onClick={onRevert}>Yes, Leave</Button>
          <Button secondary onClick={onGoBack}>
            Cancel
          </Button>
        </div>
      </LoadingScreen>
    </Modal>
  );
};

FormsEditorLeaveModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onRevert: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default FormsEditorLeaveModal;
