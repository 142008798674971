import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';

import styles from './LeaseAgreementEditorButton.module.scss';

const LeaseAgreementEditorButton = ({
  className,
  name,
  cmd,
  arg,
  icon,
  iconProps,
}) => {
  if (icon) {
    return (
      <IconButton
        className={cx(styles.iconBtn, className)}
        icon={icon}
        iconProps={iconProps}
        key={cmd}
        onMouseDown={(evt) => {
          evt.preventDefault(); // Avoids loosing focus from the editable area
          document.execCommand(cmd, false, arg); // Send the command to the browser
        }}
      >
        {name || cmd}
      </IconButton>
    );
  }

  return (
    <Button
      className={styles.btn}
      key={cmd}
      onMouseDown={(evt) => {
        evt.preventDefault(); // Avoids loosing focus from the editable area
        document.execCommand(cmd, false, arg); // Send the command to the browser
      }}
    >
      {name || cmd}
    </Button>
  );
};

LeaseAgreementEditorButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  cmd: PropTypes.string.isRequired,
  arg: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object.isRequired,
};

LeaseAgreementEditorButton.defaultProps = {
  iconProps: {},
};

export default LeaseAgreementEditorButton;
