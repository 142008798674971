import Excel from '../icons/Excel';
import GoogleDocs from '../icons/GoogleDocs';
import GoogleSheets from '../icons/GoogleSheets';
import Pdf from '../icons/Pdf';
import Word from '../icons/Word';

export const getFormsDownloadableFormats = (formats) => {
  if (!formats?.length) {
    return [];
  }

  return formats
    .map(({ url, format }) => {
      switch (format) {
        case 'word':
          return { url, icon: Word, title: 'Microsoft Word', format };
        case 'excel':
          return { url, icon: Excel, title: 'Microsoft Excel', format };
        case 'copy_word':
          return { url, icon: GoogleDocs, title: 'Google Doc', format };
        case 'copy_excel':
          return { url, icon: GoogleSheets, title: 'Google Sheets', format };
        case 'pdf':
          return { url, icon: Pdf, title: 'PDF', format };
        default:
          return null;
      }
    })
    .filter(Boolean);
};
