import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import Hr from '../../../components/Hr';
import SignupForm from '../../../components/SignupForm';
import { TermsPrivacy } from '../../../components/SignupForm/TermsPrivacy';
import SocialSignup from '../../../components/SocialSignup';
import { Experiments } from '../../../constants/experiments';
import clearMask from '../../../helpers/clear-mask';
import { useCookieExperimentAssignment } from '../../../helpers/experiments/useCookieExperimentAssignment';
import { useCookieExperimentVariant } from '../../../helpers/experiments/useCookieExperimentVariant';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import LoadSSOServiceModal, {
  useOpenSSOService,
} from '../../owners/LoadSSOServiceModal';

import {
  BackButtonWrapper,
  backStepPath,
  centerContent,
  getCurentTest,
  isWiderContent,
  onSignupSucess,
  personalizeAfterSignup,
  TestDependableShow,
  TestHideForm,
  TestHR,
  TestTitle,
} from './ABtestsHelper/FlowPicker';
import { TestProvider } from './ABtestsHelper/TestProvider';
import useAdditionaSignupData from './ABtestsHelper/useAdditionaSignupData';
import Gt3pSignupOnboardingExperimentTitle from './Gt3pSignupOnboardingExperimentComponents/Gt3pSignupOnboardingExperimentTitle';
import getExperimentSidebar from './utils/getExperimentSidebar';
import { getSidebarToShow } from './utils/getSidebarToShow';
import RenterSignupOptions from './RenterSignupOptions';
import SignupOnboardingExperimentTitle from './SignupOnboardingExperimentTitle';
import useSignupMutation from './useSignupMutation';
import WhichDescribeYouSection from './WhichDescribeYouSection';

import styles from './SignupPage.module.scss';

const SignupPage = ({ location, history, match }) => {
  const onboarding_personalize_flow = personalizeAfterSignup();
  const { type: preselectOption, redirectUrl } = queryString.parse(
    location.search,
  );

  const signup = useSignupMutation(location);
  const currentTestSignUpOnboarding = getCurentTest();
  const currentPath = location.pathname;
  const tryAssignActiveCookieExperiment = useCookieExperimentAssignment();
  tryAssignActiveCookieExperiment(Experiments.GtSignupBluebox.name, {
    excludeDeviceTypes: ['mobile'],
  });

  tryAssignActiveCookieExperiment(Experiments.GtAnimatedLogogardenMobile.name, {
    excludeDeviceTypes: ['desktop', 'tablet'],
  });
  const blueBoxExperimentVariant = useCookieExperimentVariant(
    Experiments.GtSignupBluebox.name,
  );

  const onboardingAnswersExperiment = useCookieExperimentVariant(
    Experiments.GtOnePropertyNllSignupOnboardingAnswers.name,
  );

  const gt3ExperimentVariant = useCookieExperimentVariant(
    Experiments.GtThreePropertiesNllSignupOnboardingAnswers.name,
  );

  const isGt3ExperimentActive =
    gt3ExperimentVariant ===
      Experiments.GtThreePropertiesNllSignupOnboardingAnswers.variants
        .variant_1 ||
    gt3ExperimentVariant ===
      Experiments.GtThreePropertiesNllSignupOnboardingAnswers.variants
        .variant_2;

  const isOnboardingVariant1 =
    onboardingAnswersExperiment ===
    Experiments.GtOnePropertyNllSignupOnboardingAnswers.variants.variant_1;

  const sidebarToShow = getSidebarToShow(
    gt3ExperimentVariant,
    onboardingAnswersExperiment,
    blueBoxExperimentVariant,
  );

  const gtOnboardingPhoneVariant = useCookieExperimentVariant(
    Experiments.GtOnboardingPhoneNumber.name,
  );

  const [openAcademyModal, openAcademy] = useOpenSSOService('academy');

  const [selected, setSelected] = useState(
    preselectOption || !match.isExact ? 'landlord' : undefined,
  );

  const [additionalSignupData, setAdditionalSignupData] =
    useAdditionaSignupData({});

  const academySignup = !!(redirectUrl || '').match(/(learnworlds|academy)/g);

  const testPath =
    !match.isExact &&
    location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1);

  const backPath = backStepPath(testPath);

  const widerContent = isWiderContent(testPath);

  useEffect(() => {
    segmentTracking('onboarding started', {
      location: 'Sign Up Flow',
      experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
    });
  }, []);

  const onSubmit = async (data) => {
    try {
      const hasPhone = data.telephone ? 'YES' : 'NO';
      const isPhoneExperimentVariant_1 =
        gtOnboardingPhoneVariant ===
        Experiments.GtOnboardingPhoneNumber.variants.variant_1;
      segmentTracking('sign_up clicked', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
        ...(isPhoneExperimentVariant_1 ? { phone: hasPhone } : {}),
      });

      await signup({
        ...data,
        telephone: data.telephone ? clearMask(data.telephone) : null,
        ...additionalSignupData,
        onboarding_personalize_flow,
        signup_test: currentTestSignUpOnboarding,
      });

      segmentTracking('account_created', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
      });

      if (academySignup) {
        return openAcademy();
      }

      await onSignupSucess(history, additionalSignupData?.process);
    } catch (error) {
      console.log('error signing up', error);
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  const center = centerContent();

  const onSelectUserType = (userType) => {
    if (userType === 'landlord') {
      segmentTracking('landlord clicked', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
      });
    }

    setSelected(userType);
  };

  const titleToRender = () => {
    if (isGt3ExperimentActive) {
      return <Gt3pSignupOnboardingExperimentTitle />;
    } else if (!isGt3ExperimentActive && isOnboardingVariant1) {
      return <SignupOnboardingExperimentTitle />;
    } else if (!isOnboardingVariant1) {
      return <TestTitle>Sign up for TurboTenant</TestTitle>;
    }
  };

  return (
    <TestProvider
      additionalSignupData={additionalSignupData}
      setAdditionalSignupData={setAdditionalSignupData}
      selected={selected}
    >
      <MarketingLayout
        action={
          <Button
            style={{ width: 176 }}
            to={`/auth/login${history?.location?.search || ''}`}
            small={true}
            secondary={true}
          >
            Landlord Login
          </Button>
        }
        side={getExperimentSidebar(currentPath, sidebarToShow)}
      >
        <div
          className={cx(styles.container, {
            [styles.centerContent]: center,
          })}
        >
          {!match.isExact && (
            <BackButtonWrapper path={testPath}>
              {({ setLayout }) => (
                <BackButton
                  onClick={() => {
                    setLayout();
                    if (backPath) {
                      return history.push(`/auth/signup/${backPath}`);
                    }
                    history.push('/auth/signup');
                    setSelected(undefined);
                  }}
                  className={cx(styles.backButton)}
                />
              )}
            </BackButtonWrapper>
          )}
          <div
            className={cx(styles.content, {
              [styles.widerContent]: widerContent,
            })}
          >
            <h2
              className={cx(styles.title, { [styles.centerTitle]: !selected })}
            >
              {titleToRender()}
            </h2>
            <TestDependableShow isSelected={selected}>
              <WhichDescribeYouSection
                value={selected}
                onChange={onSelectUserType}
              />
            </TestDependableShow>

            {selected ? (
              <TestHR>
                <Hr />
              </TestHR>
            ) : null}
            {selected === 'landlord' ? (
              <TestHideForm>
                <SocialSignup
                  className={styles.social}
                  onboarding_personalize_flow={onboarding_personalize_flow}
                  additionalSignupData={additionalSignupData}
                  showFacebookSignup={false}
                />
                <SignupForm
                  onboarding_personalize_flow={onboarding_personalize_flow}
                  onSubmit={onSubmit}
                  onboardingExperimentVariant={gtOnboardingPhoneVariant}
                />
                <TermsPrivacy />
                <LoadSSOServiceModal
                  open={openAcademyModal}
                  ssoService="academy"
                />
              </TestHideForm>
            ) : null}
            {selected === 'renter' ? <RenterSignupOptions /> : null}
          </div>
        </div>
      </MarketingLayout>
    </TestProvider>
  );
};

SignupPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default authPage(SignupPage);
