import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page from '../../../components/page';
import PageNotFound from '../../../components/PageNotFound';
import PageWrapper from '../../../components/PageWrapper';
import componentLoader from '../../../helpers/component-loader';
import OfflineToOnlineConversionPage from '../pages/OfflineToOnlineConversionPage/OfflineToOnlineConversionPage';

import BankAccountRoutes from './account-setup/routes.bankAccount';
import NewOnboardingPage from './new-setup-2021/OwnerPaymentsOnboardingWrapper';

const PaymentSetupPage = componentLoader(
  () => import('./new-setup-2021/PaymentSetupPage'),
);

const ChargesFirstPage = componentLoader(
  () => import('./new-setup-2021/ChargesFirst'),
);

const SetupSummaryPage = componentLoader(
  () => import('./new-setup-2021/SetupSummaryPage'),
);

const MakeOfflinePayment = componentLoader(
  () => import('./MakeOfflinePayment'),
);

const InProductInternalReviewWrapper = componentLoader(
  () => import('./internal-review/InProductInternalReviewWrapper'),
);

const InProductStripeVerificationWrapper = componentLoader(
  () =>
    import(
      './stripe-verification-documents/InProductStripeVerificationWrapper'
    ),
);

const PromoteRentPayments = componentLoader(
  () => import('./payment-setup/PromoteRentPayments'),
);

const CreateEditCharge = componentLoader(
  () => import('./charges/CreateEditCharge'),
);

const PaymentsDashboard = componentLoader(() => import('../PaymentsDashboard'));

const ExpensesPage = componentLoader(() => import('../pages/ExpensesPage'));

const AccountingPage = componentLoader(() => import('../pages/AccountingPage'));

const ReportsPage = componentLoader(() => import('../pages/ReportsPage'));

const ReportsPromoPage = componentLoader(
  () => import('../pages/ReportsPage/ReportsPromoPage'),
);

const PaymentRoutes = ({ match }) => {
  const me = match.url;

  return (
    <Switch>
      <Route
        path={`${me}/bank-account`}
        render={(props) => <BankAccountRoutes {...props} />}
      />
      <Route
        exact
        path={`${me}/setup-rent-payments/:leaseId`}
        render={(props) => (
          <PageWrapper title="My Payments - TurboTenant">
            <PromoteRentPayments {...props} />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={`${me}/setup/summary`}
        render={(props) => {
          return (
            <PageWrapper title="Setup - Payments - TurboTenant">
              <SetupSummaryPage {...props} />
            </PageWrapper>
          );
        }}
      />
      <Route
        exact
        path={`${me}/setup/:step?/:leaseId?`}
        render={(props) => (
          <PageWrapper>
            <PaymentSetupPage {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/makeofflinepayment/:leaseId?`}
        exact
        render={(props) => (
          <PageWrapper>
            <MakeOfflinePayment {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/verify`}
        render={(props) => (
          <PageWrapper>
            <InProductStripeVerificationWrapper {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/internalreview`}
        render={(props) => (
          <PageWrapper>
            <InProductInternalReviewWrapper {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/convert-to-online`}
        render={(props) => (
          <PageWrapper>
            <OfflineToOnlineConversionPage {...props} />
          </PageWrapper>
        )}
      />
      <Redirect
        exact
        from={`${me}/turbopay/setup`}
        to={`${me}/charges/premium-subscription`}
      />
      <Route
        exact
        path={`${me}/charges/create/:leaseId?`}
        render={(props) => (
          <PageWrapper>
            <CreateEditCharge {...props} />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={`${me}/charges/edit/:paymentId/:isRule?/:leaseId?`}
        render={(props) => (
          <PageWrapper>
            <CreateEditCharge {...props} />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={`${me}/onboarding/:step?/:isEdit?`}
        render={(props) => (
          <PageWrapper>
            <NewOnboardingPage {...props} />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={`${me}/charges-first`}
        render={(props) => (
          <PageWrapper>
            <ChargesFirstPage {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/expenses`}
        render={(props) => (
          <Page title="Expenses - My Payments - TurboTenant">
            <ExpensesPage {...props} />
          </Page>
        )}
      />
      <Route
        path={`${me}/accounting`}
        render={(props) => (
          <Page title="Accounting - My Payments - TurboTenant">
            <AccountingPage {...props} />
          </Page>
        )}
      />
      <Route
        path={`${me}/reports`}
        exact
        render={(props) => (
          <Page title="Reports - My Payments - TurboTenant">
            <ReportsPage {...props} />
          </Page>
        )}
      />
      <Route
        path={`${me}/reports/promo`}
        exact
        render={(props) => (
          <Page title="Reports Promo - My Payments - TurboTenant">
            <ReportsPromoPage {...props} />
          </Page>
        )}
      />
      <Route
        path={`${me}`}
        render={(props) => (
          <Page title="My Payments - TurboTenant">
            <PaymentsDashboard {...props} />
          </Page>
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

PaymentRoutes.propTypes = {
  match: PropTypes.object,
};

export default PaymentRoutes;
