import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './LogoGardenSidebar.module.scss';

const logogarden = [
  'logo-cnn.svg',
  'logo-fortune.svg',
  'logo-bhg.svg',
  'logo-realtor.svg',
  'logo-inc-5000.svg',
  'logo-cbs.svg',
  'logo-yahoo-finance.svg',
  'logo-nasdaq.svg',
  'logo-usnews.svg',
  'logo-benzinga.svg',
];

const logoGardenDir =
  'https://turbotenant-production.s3.us-east-1.amazonaws.com/uploads/assets/logogarden/';

const LogoGardenSidebar = ({
  title,
  subtitle,
  className,
  titleClassName,
  graphic,
  description,
  showLogo,
  ...otherProps
}) => (
  <div className={cx(styles.container, className)} {...otherProps}>
    <h3>
      Trusted by 700,000+ landlords and 11 million renters across the U.S. and
      counting.
    </h3>
    <div className={styles.logogarden}>
      <div className={styles.column}>
        {logogarden.slice(0, Math.ceil(logogarden.length / 2)).map((logo) => (
          <img src={`${logoGardenDir}${logo}`} alt={logo} key={logo} />
        ))}
      </div>
      <div className={styles.column}>
        {logogarden.slice(Math.ceil(logogarden.length / 2)).map((logo) => (
          <img src={`${logoGardenDir}${logo}`} alt={logo} key={logo} />
        ))}
      </div>
    </div>
  </div>
);

LogoGardenSidebar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  graphic: PropTypes.string,
  description: PropTypes.node,
  titleClassName: PropTypes.string,
  showLogo: PropTypes.bool,
};

export default LogoGardenSidebar;
