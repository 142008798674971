// Add this to a utils file or create new one
export const SEND_WORK_ORDER_TOOLTIP_KEY = 'tt_send_work_order_tooltip_shown';

export const shouldShowSendWorkOrderTooltip = () => {
  const shown = localStorage.getItem(SEND_WORK_ORDER_TOOLTIP_KEY);
  if (!shown) {
    localStorage.setItem(SEND_WORK_ORDER_TOOLTIP_KEY, 'true');
    return true;
  }
  return false;
};
